import { Component } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CompanyWebService, OrganisationWebService } from '@app/providers/web.service';
import {SelectItem} from 'primeng/api';
import { BaseUser } from '@app/models/base-user';
import { AppService } from '@app/modules/app/app.service';

@Component({
    selector: 'transfer-employees-modal',
    templateUrl: './transfer-employees-modal.component.html'
})
export class TransferEmployeesModalComponent {

    public organisationFlat: Array<any> = [];
    public employees: SelectItem[];
    public selectedEmployees: any[];
    public selectDisabled = true;
    public currentUser: BaseUser = null;

    public transferForm: FormGroup = new FormGroup({
        from_business: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(100)]),
        to_business: new FormControl('',[Validators.required, Validators.minLength(2), Validators.maxLength(100)]),
    });

    constructor(
        private modalService: BsModalService,
        public bsModalRef: BsModalRef,
        private organisationWebService: OrganisationWebService,
        private companyWebService: CompanyWebService,
        private appService: AppService,
    ) {
    }

    ngOnInit(){
        this.currentUser = this.appService.getUser();
    }

    async onChange(event){
        let division_id = parseInt(event.split(':')[1]);
        // TODO separate API
        let res = await this.companyWebService.getAllBusinessEmployees(division_id)
        const empl = res.filter(e => e.business_id === division_id)
        
        empl.forEach(element => {
            element.full_name = element.first_name + ' ' + element.last_name;
        });
        this.employees = empl;
        if (this.employees.length > 0) {
            this.selectDisabled = false;
        }
    }

    public async onTransfer(){
        
        let params = {
            from_business: this.transferForm.get('from_business').value,
            to_business: this.transferForm.get('to_business').value,
            users: this.selectedEmployees.map(e => e.UserID || e.id) 
        }

        if (params.users.includes(this.currentUser.id)){
            this.appService.showCustomError("Error", "You can't move yourself to another division!")
            return
        }
        await this.organisationWebService.transferEmployees(params)
        this.bsModalRef.hide();
    }    
}
