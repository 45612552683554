import { EmailVerificationComponent } from '@appmodule/email-activation/email-verification.component';
import { AppComponent } from '@appmodule/app/app.component';
import { NgModule, isDevMode } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminAuthGuard } from '@providers/admin-auth-guard.provider';
import { AuthGuard } from '@providers/auth-guard.provider';
import { EmailInviteComponent } from '@appmodule/email-invite/email-invite.component';
import { AuthGuardNoRedirect } from '@app/providers/auth-guard-no-redirect.provider';
import { ActivationComponent } from '@register/activation/activation.component'
import { DirectDebitCompleteComponent } from '@common/direct-debit-complete/direct-debit-complete.component'
// import { LinkStripeComponent } from './link-stripe/link-stripe.component';

const routes: Routes = [
  { path: '', redirectTo: 'explore/projects', pathMatch: 'full' }, // TODO: Should redirect to general after implemented
  { path: 'activate/:key', canActivate: [AuthGuardNoRedirect], component: EmailVerificationComponent },
  { path: 'invite/:key', canActivate: [AuthGuardNoRedirect], component: EmailInviteComponent },
  // { path: 'link-stripe', canActivate: [AuthGuard] , component: LinkStripeComponent },
  { path: 'admin', canActivate: [AdminAuthGuard] , loadChildren: '@modules/admin/admin.module#AdminModule' },
  { path: 'cnx-developers', loadChildren: '@cnx-developers/cnx-developers.module#CnxDevelopersModule' }, // TODO: Need to create Auth Guard
  { path: 'general', canActivate: [AuthGuardNoRedirect], loadChildren: '@modules/general-pages/general-pages.module#GeneralPagesModule' },
  { path: 'login', canActivate: [AuthGuardNoRedirect], loadChildren: '@modules/login/login.module#LoginModule' },
  { path: 'register', canActivate: [AuthGuardNoRedirect], loadChildren: '@modules/register/register.module#RegisterModule' },
  { path: 'individual', canActivate: [AuthGuard], loadChildren: '@modules/individual/individual.module#IndividualModule' },
  { path: 'explore', canActivate: [AuthGuardNoRedirect], loadChildren: '@modules/explore/explore.module#ExploreModule' },
  { path: 'charity/:id', loadChildren: '@modules/charity/charity.module#CharityModule' },
  { path: 'business/:id', canActivate: [AuthGuard], loadChildren: '@modules/business/business.module#BusinessModule' },
  { path: 'activation/:email', canActivate: [AuthGuardNoRedirect], component: ActivationComponent },
  { path: 'group/:id', canActivate: [AuthGuard], loadChildren: '@modules/group/group.module#GroupModule' },
  { path: 'direct-debit-completed', canActivate: [AuthGuard], component: DirectDebitCompleteComponent },

  // { path: 'group/:id/business/:companyId', canActivate: [AuthGuardNoRedirect], component: GroupProfileComponent },
  //{ path: '**', component: AppComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
