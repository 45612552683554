import { Component } from '@angular/core';
import { CompanyModel } from '@app/models/company-model';
import { BsModalRef } from 'ngx-bootstrap';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CompanyWebService } from '@app/providers/web.service';
import { BusinessGroup } from '@app/models/business-group';
import { AppService } from '@app/modules/app/app.service';
import { ValidatorHelpers } from '@app/utils/validators';
import { Utils } from '@app/utils/utils';

@Component(
    {
        selector: 'create-employees-group-modal',
        templateUrl: './create-employees-group-modal.component.html'
    }
)
export class CreateEmployeesGroupModalComponent {
    public business: CompanyModel;
    public editMode: boolean = false;
    public group: BusinessGroup;
    public addGroupForm: FormGroup = new FormGroup({
        group_name: new FormControl('', [Validators.required, ValidatorHelpers.validateNotWhitespace])
      });

    constructor(public modalRef: BsModalRef, public companyWebService: CompanyWebService, private appService: AppService) {
    }

    ngOnInit() {
        if(this.editMode) {
            this.addGroupForm.get("group_name").patchValue(this.group.name)
        }
    }

    public async addGroup() {
        this.appService.setAppLoading(true);
        let val = this.addGroupForm.get("group_name").value;
        if(!this.addGroupForm.valid){
            Utils.validateAllFormFields(this.addGroupForm);
            this.appService.setAppLoading(false);
            return;
        } 
        this.addGroupForm.reset();
        
        let params = {
            name: val
        }

        if(this.editMode) {
            await this.companyWebService.updateBusinessGroup(this.business.company_id, this.group.id, params)
        } else {
            await this.companyWebService.createBusinessGroup(this.business.company_id,params);
        }
        this.appService.setAppLoading(false);
        this.modalRef.hide();
    }
}
