import { Component, TemplateRef, ViewChild } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormGroup } from '@angular/forms';
import { AppService } from '@app/modules/app/app.service';
import { BaseUser } from '@app/models/base-user';
import { UserWebService } from '@providers/web.service';
import { TypesOfGivingModel } from '@app/models/types-of-giving';
import { Subscription } from 'rxjs';
import { Utils } from '@app/utils/utils';
import { StripeComponent } from '@common/stripe/stripe.component';
import { DirectDebitComponent } from '@app/modules/common/direct-debit/direct-debit.component';
import { WalletType } from '@app/utils/Enumerations';

@Component({
  selector: 'individual-charity-project-donate-funds-modal',
  templateUrl: './individual-donate-modal.component.html'
})
export class IndividualDonateFundsComponent {

  public user: BaseUser = new BaseUser();

  @ViewChild('stripe') public creditCardStripe: StripeComponent;
  @ViewChild('direct_debit') public directDebitStripe: DirectDebitComponent;

  public donateIndividualForm: FormGroup = null;
  public giving: TypesOfGivingModel;
  public exceeded: boolean = false;
  public isCharityDonation: boolean = false;
  public disableButton: boolean = true;

  private walletTypeSub: Subscription;
  private amountSub: Subscription;

  constructor(
    public donateModalRef: BsModalRef
  ) {
  }

  ngDoCheck() {
    if (this.donateIndividualForm) {
      if (this.donateIndividualForm.get('wallet_type').value == WalletType.CreditOrDebitCard) {
        const amount = this.creditCardStripe.paymentForm.get('amount').value;
        if(amount) this.donateIndividualForm.get('amount').patchValue(amount);
        if (amount > (this.giving.max_amount - this.giving.current_amount) && (!this.isCharityDonation && !this.giving.can_exceed_max)) {
          this.exceeded = true;
        } else {
          this.exceeded = false;
        }
      } else if (this.donateIndividualForm.get('wallet_type').value == WalletType.DirectDebit){
        const form_data = this.directDebitStripe.paymentForm.getRawValue();
        if(form_data){
          this.donateIndividualForm.get('amount').patchValue(form_data.amount);
          this.donateIndividualForm.get('recurring').patchValue(form_data.recurring);
        } 
        if (form_data.amount > (this.giving.max_amount - this.giving.current_amount) && (!this.isCharityDonation && !this.giving.can_exceed_max)) {
          this.exceeded = true;
        } else {
          this.exceeded = false;
        }
      } else {
        const amount = this.donateIndividualForm.get('amount').value;
        if (amount > (this.giving.max_amount - this.giving.current_amount) && (!this.isCharityDonation && !this.giving.can_exceed_max)) {
          this.exceeded = true; 
        } else {
          this.exceeded = false;
        }
      }
    }
  }

  public clearAmount() {
    this.donateIndividualForm.get('amount').setValue(0);
    this.creditCardStripe.paymentForm.get('amount').setValue(0);
  }

  public onIndividualFormDonate() {
    if (this.donateIndividualForm.invalid) {
      Utils.validateAllFormFields(this.donateIndividualForm)
      return;
    }
    if (this.exceeded) return;
    this.openConfirmModal(null)
  }

  public openConfirmModal(template: TemplateRef<any>) {
    if (!this.donateIndividualForm.valid) return;
    this.donateModalRef.hide();
  }

  ngOnDestroy(): void {
    if (this.walletTypeSub) {
      this.walletTypeSub.unsubscribe();
    }
    if (this.amountSub) {
      this.amountSub.unsubscribe();
    }
  }
}
