import { Component, Input } from '@angular/core';
import { CharityWebService } from '@app/providers/web.service';
import { ImpactStatement } from '@app/models/impact-statement';
import { CharityModel } from '@app/models/charity-model';

@Component(
    {
        selector: 'impact-statement-modal',
        templateUrl: './impact-statement-modal.component.html'
    }
)
export class ImpactStatementModalComponent {
    @Input('projectImpact') projectImpactStatement: ImpactStatement;
    @Input() charity: CharityModel;

    constructor(private charityWebService: CharityWebService) {
    }
}
