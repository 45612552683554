import { Router } from '@angular/router';
import { Injectable, isDevMode } from '@angular/core';
import { } from 'googlemaps';
import { BaseUser } from '@models/base-user';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UserWebService, GiftWebService } from '@providers/web.service';
import { Utils } from '@utils/utils'
import { BaseWebService, ErrorResponse, SuccessResponse } from '@providers/base-web.service';
import { MessageService } from 'primeng/api';
import { interval, Observable, range, Subject, timer } from 'rxjs'
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { GiftCategoryModel } from '@app/models/gift-category-model';
import { GiftModel } from '@app/models/gift-model';
import { CharityMembership } from '@app/models/charity-membership';
import { ThemeEnum } from '@app/utils/Constants';
import cssVars from 'css-vars-ponyfill';

declare var googleMapsLoaded;
const jwtHelper = new JwtHelperService();
@Injectable()
export class AppService {

    public token: string = null;
    private user: BaseUser = new BaseUser();
    private userCharities: Array<CharityMembership> = [];
    private userBusinesses: Array<any> = [];
    private userProjects: Array<any> = [];
    private userGroups: Array<any> = [];
    private userExtendedObj: any = {};
    public userSubject: Subject<BaseUser> = new Subject();
    public appLoadingSubject: Subject<boolean> = new Subject();
    public appLoadingTimeout: any = null;
    public appLoadingQueue: Array<boolean> = [];
    public giftCategories: Array<GiftCategoryModel> = [];
    public giftList: Array<GiftModel> = [];
    public giftListById: { [id: number]: GiftModel } = {};
    public giftsMap: { [id: number]: Array<GiftModel> } = [];
    public userUnreadMessages: number = 0;

    public modalSubject: Subject<string> = new Subject();
    public modalTemplate: any;
    public modalConfirmTemplate: any;
    public modalRef: BsModalRef = null;
    public modalPromiseResolve: any = null;
    public ModalTitle: string = '';
    public modalTitleSubject: Subject<string> = new Subject();
    public modalConfirmTitleSubject: Subject<string> = new Subject();
    public isMobile: boolean = false;
    public isDeveloper = isDevMode();
    public isIpad: boolean = false;
    public iosDevice: boolean = false;
    public minRemaining: Observable<boolean> = new Observable();

    public autocomplete: { service: google.maps.places.AutocompleteService } = {
        service: null
    };

    constructor(private userWebService: UserWebService, private messageService: MessageService, private modalService: BsModalService, private router: Router, private giftWebService: GiftWebService) {
        this.isMobile = Utils.checkForMobile();
        this.isIpad = Utils.checkForIpad();
        this.iosDevice = Utils.checkForIOSDevice();
        const token = localStorage.getItem('token');
        if (token) {
            this.token = token;
            let user = localStorage.getItem('user')
            if (!user || (token && jwtHelper.isTokenExpired(token))) {
                this.clearSession();
            } else {
                this.user.fromJSON(JSON.parse(user).user)
                this.userCharities = (JSON.parse(user).charity_membership)
                this.userBusinesses = (JSON.parse(user).business_membership)
                this.userProjects = (JSON.parse(user).project_membership)
                this.userGroups = (JSON.parse(user).group_membership)
                this.userUnreadMessages = (JSON.parse(user).unread_messages)
                this.userWebService.getUserDetail().then((res) => {
                    this.setUser(res)
                    this.reloadGiftCategories();
                })
            }
        }
        BaseWebService.errorSubject.subscribe((err: ErrorResponse) => {
            this.setAppLoading(false);
            this.showError(err)
        })

        BaseWebService.successSubject.subscribe((success: SuccessResponse) => {
            this.showSuccess(success.summary, success.detail)
        })

        BaseWebService.shouldLogoutSubject.subscribe(() => {
            this.setAppLoading(false);
            this.logoutUser();
        })

        this.changeThemeColor(ThemeEnum.Default);

        this.reloadGiftCategories().then(() => this.reloadGiftList());
    }

    public async showModal(title: string): Promise<boolean> {
        let res = new Promise<boolean>((resolve, reject) => {
            this.modalPromiseResolve = resolve
            this.modalTitleSubject.next(title)
            this.modalRef = this.modalService.show(this.modalTemplate, { class: 'modal-sm' });
            let modalHideSub = this.modalService.onHide.subscribe((reason) => {
                if (reason) {
                    resolve(false);
                }
                modalHideSub.unsubscribe();
            })
        })
        return res;
    }

    public async showConfirmModal(title: string): Promise<boolean> {
        const res = new Promise<boolean>((resolve, reject) => {
            this.modalPromiseResolve = resolve;
            this.modalConfirmTitleSubject.next(title);
            this.modalRef = this.modalService.show(this.modalConfirmTemplate, { class: 'modal-lm', ignoreBackdropClick: true });

            const modalHideSub = this.modalService.onHide.subscribe((reason) => {
                if (reason) {
                    resolve(false);
                }
                modalHideSub.unsubscribe();
            });
        });
        return res;
    }

    public confirm(): void {
        if (this.modalPromiseResolve) {
            this.modalPromiseResolve(true);
            this.modalRef.hide();
        }
    }


    public decline(): void {
        if (this.modalPromiseResolve) {
            this.modalPromiseResolve(false);
            this.modalRef.hide();
        }
    }

    public async reloadGiftCategories() {
        this.giftCategories = [];
        let res = await this.giftWebService.getGiftsCategories()
        for (let category of res) {
            this.giftCategories.push(new GiftCategoryModel().fromJSON(category));
        }
    }

    public async reloadGiftList() {
        this.giftList = [];
        let res = await this.giftWebService.getGiftsList()
        for (let gift of res) {
            const newGift = new GiftModel().fromJSON(gift);
            this.giftList.push(newGift);
            this.giftListById[newGift.id] = newGift;
        }
        this.buildGiftsMap();
    }

    public buildGiftsMap() {
        this.giftsMap = {};
        for (let category of this.giftCategories) {
            this.giftsMap[category.id] = this.giftList.filter((gift) => {
                return gift.cid == category.id;
            })
        }
    }

    public setUser(val: any) {
        this.userExtendedObj = val;
        if (val.user) this.user.fromJSON(val.user);
        else this.user = new BaseUser().fromJSON(val);
        if (val.charity_membership) this.userCharities = val.charity_membership;
        if (val.business_membership) this.userBusinesses = val.business_membership;
        if (val.group_membership) this.userGroups = val.group_membership;
        if (val.unread_messages) this.userUnreadMessages = val.unread_messages;
        this.user.unread_messages = this.userUnreadMessages
        localStorage.setItem('user', JSON.stringify(val));
        this.userSubject.next(this.user);
    }

    public updateUser(val: any, fromJSON: boolean = true) {
        if (fromJSON) {
            this.user.fromJSON(val);
        } else {
            this.user = val;
        }
        this.userExtendedObj.user = val;
        localStorage.setItem('user', JSON.stringify(this.userExtendedObj));
        this.userSubject.next(this.user);
    }

    public setAppLoading(status: boolean) {
        if (status) {
            this.appLoadingQueue.push(status);
            if (this.appLoadingTimeout) {
                clearTimeout(this.appLoadingTimeout);
                this.appLoadingTimeout = null;
            }
            this.appLoadingTimeout = setTimeout(() => {
                this.appLoadingSubject.next(status);
            }, 200);
        } else {
            this.appLoadingQueue.pop();
            if (this.appLoadingTimeout && this.appLoadingQueue.length === 0) {
                clearTimeout(this.appLoadingTimeout);
                this.appLoadingTimeout = null;
                this.appLoadingSubject.next(status);
            }
        }
    }

    public async reloadUser(){
        this.userWebService.getUserDetail().then((res) => {
            this.setUser(res)
        })
    }

    public getUser(): BaseUser {
        return this.user
    }

    public getUserCharities(): Array<any> {
        return this.userCharities;
    }

    public getUserBusinesses(): Array<any> {
        return this.userBusinesses;
    }

    public getUserProjects(): Array<any> {
        return this.userProjects;
    }

    public getUserGroups(): Array<any> {
        return this.userGroups;
    }
    
    public getUserUnreadMessages(): number {
        return this.userUnreadMessages;
    }

    public showError(err: ErrorResponse) {
        this.messageService.add({ key: "general", severity: "error", summary: "", detail: err.message });
    }

    public showCustomError(summary: string, detail: string) {
        this.messageService.add({ key: "general", severity: "error", summary: summary, detail: detail });
    }

    public showSuccess(summary: string, detail: string) {
        this.messageService.add({ key: "general", severity: "success", summary: summary, detail: detail });
    }

    public showWarning(summary: string, detail: string) {
        this.messageService.add({ key: "general", severity: "warn", summary: summary, detail: detail });
    }

    public isUserLoggedIn() {
        const token = localStorage.getItem('token');
        if (token && !jwtHelper.isTokenExpired(this.token)) {
            this.token = token;
            let user = localStorage.getItem('user')
            if (user) {
                this.user.fromJSON(JSON.parse(user).user);
                return true
            }
        }
        return false;
    }

    public getAutocomplete(): Promise<{ service: google.maps.places.AutocompleteService }> {
        let ret = new Promise<{ service: google.maps.places.AutocompleteService }>((resolve, reject) => {
            if (googleMapsLoaded) {
                this.autocomplete.service = new google.maps.places.AutocompleteService();
                resolve(this.autocomplete);
            } else {
                document.addEventListener("googleMapsLoaded", () => {
                    this.autocomplete.service = new google.maps.places.AutocompleteService();
                    googleMapsLoaded = true;
                    resolve(this.autocomplete);
                })
            }
        });

        return ret;
    }

    public getCity(input: string, country: string): Promise<Array<string>> {
        let options = {
            types: ['(cities)'],
            componentRestrictions: { country: country },
            input: input
        };
        return new Promise<Array<string>>((resolve, reject) => {
            if (input) {
                this.autocomplete.service.getPlacePredictions(options, (res) => {
                    let cities: Array<string> = [];
                    cities.push('UK')
                    if (res) {
                        for (let city of res) {
                            if (city && city.terms && city.terms[0] && (cities.indexOf(city.terms[0].value) < 0)) {
                                cities.push(city.terms[0].value);
                            }
                        }
                        resolve(cities);
                    } else {
                        resolve([]);
                    }
                })
            } else {
                resolve([]);
            }
        });
    }

    public getAddress(input: string, country: string): Promise<Array<string>> {
        let options = {
            types: ['address'],
            componentRestrictions: { country: country },
            input: input
        };
        return new Promise<Array<string>>((resolve, reject) => {
            if (input) {
                this.autocomplete.service.getPlacePredictions(options, (res) => {
                    let addresses: Array<string> = [];
                    if (res) {
                        for (let address of res) {
                            if (address && address.structured_formatting && address.structured_formatting.main_text) {
                                if (!(addresses.indexOf(address.structured_formatting.main_text) > -1)) {
                                    addresses.push(address.structured_formatting.main_text);
                                }
                            }
                        }
                        resolve(addresses);
                    } else {
                        resolve([]);
                    }
                })
            } else {
                resolve([]);
            }
        });
    }

    public getPostCode(input: string, country: string): Promise<Array<string>> {
        let options = {
            types: ['(regions)'],
            componentRestrictions: { country: country },
            input: input
        };
        return new Promise<Array<string>>((resolve, reject) => {
            if (input) {
                this.autocomplete.service.getPlacePredictions(options, (res) => {
                    let post_code: Array<string> = [];
                    if (res) {
                        for (let post of res) {
                            if (post && post.types.includes('postal_code') && post.terms[1] && (post_code.indexOf(post.terms[1].value) < 0)) {
                                post_code.push(post.structured_formatting.main_text);
                            }
                        }
                        resolve(post_code);
                    } else {
                        resolve([]);
                    }
                })
            } else {
                resolve([]);
            }
        });
    }

    public logoutUser() {
        Utils.deleteToken();
        this.token = null;
        this.user = new BaseUser();
        this.userBusinesses = [];
        this.userCharities = [];
        this.userProjects = [];
        this.userExtendedObj = {};
        this.userGroups = [];
        this.router.navigate(['login'])
    }

    public clearSession() {
        Utils.deleteToken();
        this.token = null;
        this.user = new BaseUser();
        this.userBusinesses = [];
        this.userCharities = [];
        this.userProjects = [];
        this.userGroups = [];
        this.userExtendedObj = {};
    }

    public changeThemeColor(theme: ThemeEnum) {
        const themeWrapper = document.querySelector('body');
        switch (theme) {
            case ThemeEnum.Individual: {
                cssVars({
                    variables: {
                        '--blue': '#6b8397',
                        '--dark-blue': '#6b8397',
                        '--very-dark-blue': '#6b8397',
                        '--hover': '#bed4f7',
                        '--body': '#efeff4',
                        '--white': '#ffffff',
                        '--black': '#000000',
                        '--light-grey': '#f2f2f4',
                        '--silver-chalice': '#a9a9a9',
                        '--medium-grey': '#828282',
                        '--dark-grey': '#5a5a5a',
                        '--just-blue': '#0071e0',
                        '--text-black': '#47525d',
                        '--green': '#30d68f',
                        '--red': '#ff256d',
                        '--grey': '#eaeaea',
                        '--blackcurrant': '#3B2B45',
                        '--eggblue': '#00C8CE',
                        '--color-ghost': '#CED4DA',
                        '--individual': '#6b8397',
                        '--business': '#323232',
                        '--charity': '#5e9957',
                        '--franchise': '#3B2B45',
                        '--yellow-warning': '#ffe399',
                        '--purple': '#7e21b9'
                    }
                });
                themeWrapper.style.setProperty('--blue', '#6b8397');
                themeWrapper.style.setProperty('--dark-blue', '#6b8397');
                themeWrapper.style.setProperty('--very-dark-blue', '#6b8397');
                break;
            }
            case ThemeEnum.Charity: {
                themeWrapper.style.setProperty('--blue', '#5e9957');
                themeWrapper.style.setProperty('--dark-blue', '#5e9957');
                themeWrapper.style.setProperty('--very-dark-blue', '#5e9957');
                cssVars({
                    variables: {
                        '--blue': '#5e9957',
                        '--dark-blue': '#5e9957',
                        '--very-dark-blue': '#5e9957',
                        '--hover': '#bed4f7',
                        '--body': '#efeff4',
                        '--white': '#ffffff',
                        '--black': '#000000',
                        '--light-grey': '#f2f2f4',
                        '--silver-chalice': '#a9a9a9',
                        '--medium-grey': '#828282',
                        '--dark-grey': '#5a5a5a',
                        '--just-blue': '#0071e0',
                        '--text-black': '#47525d',
                        '--green': '#30d68f',
                        '--red': '#ff256d',
                        '--grey': '#eaeaea',
                        '--blackcurrant': '#3B2B45',
                        '--eggblue': '#00C8CE',
                        '--color-ghost': '#CED4DA',
                        '--individual': '#6b8397',
                        '--business': '#323232',
                        '--charity': '#5e9957',
                        '--franchise': '#3B2B45',
                        '--yellow-warning': '#ffe399',
                        '--purple': '#7e21b9'
                    }
                });
                break;
            }
            case ThemeEnum.Business: {
                themeWrapper.style.setProperty('--blue', '#323232');
                themeWrapper.style.setProperty('--dark-blue', '#323232');
                themeWrapper.style.setProperty('--very-dark-blue', '#323232');
                cssVars({
                    variables: {
                        '--blue': '#323232',
                        '--dark-blue': '#323232',
                        '--very-dark-blue': '#323232',
                        '--hover': '#bed4f7',
                        '--body': '#efeff4',
                        '--white': '#ffffff',
                        '--black': '#000000',
                        '--light-grey': '#f2f2f4',
                        '--silver-chalice': '#a9a9a9',
                        '--medium-grey': '#828282',
                        '--dark-grey': '#5a5a5a',
                        '--just-blue': '#0071e0',
                        '--text-black': '#47525d',
                        '--green': '#30d68f',
                        '--red': '#ff256d',
                        '--grey': '#eaeaea',
                        '--blackcurrant': '#3B2B45',
                        '--eggblue': '#00C8CE',
                        '--color-ghost': '#CED4DA',
                        '--individual': '#6b8397',
                        '--business': '#323232',
                        '--charity': '#5e9957',
                        '--franchise': '#3B2B45',
                        '--yellow-warning': '#ffe399',
                        '--purple': '#7e21b9'
                    }
                });
                break;
            }
            case ThemeEnum.Franchise: {
                themeWrapper.style.setProperty('--blue', '#3B2B45');
                themeWrapper.style.setProperty('--dark-blue', '#3B2B45');
                themeWrapper.style.setProperty('--very-dark-blue', '#3B2B45');
                cssVars({
                    variables: {
                        '--blue': '#3B2B45',
                        '--dark-blue': '#3B2B45',
                        '--very-dark-blue': '#3B2B45',
                        '--hover': '#bed4f7',
                        '--body': '#efeff4',
                        '--white': '#ffffff',
                        '--black': '#000000',
                        '--light-grey': '#f2f2f4',
                        '--silver-chalice': '#a9a9a9',
                        '--medium-grey': '#828282',
                        '--dark-grey': '#5a5a5a',
                        '--just-blue': '#0071e0',
                        '--text-black': '#47525d',
                        '--green': '#30d68f',
                        '--red': '#ff256d',
                        '--grey': '#eaeaea',
                        '--blackcurrant': '#3B2B45',
                        '--eggblue': '#00C8CE',
                        '--color-ghost': '#CED4DA',
                        '--individual': '#6b8397',
                        '--business': '#323232',
                        '--charity': '#5e9957',
                        '--franchise': '#3B2B45',
                        '--yellow-warning': '#ffe399',
                        '--purple': '#7e21b9'
                    }
                });
                break;
            }
            default: {
                themeWrapper.style.setProperty('--blue', '#6b8397');
                themeWrapper.style.setProperty('--dark-blue', '#6b8397');
                themeWrapper.style.setProperty('--very-dark-blue', '#6b8397');
                cssVars({
                    variables: {
                        '--blue': '#6b8397',
                        '--dark-blue': '#6b8397',
                        '--very-dark-blue': '#6b8397',
                        '--hover': '#bed4f7',
                        '--body': '#efeff4',
                        '--white': '#ffffff',
                        '--black': '#000000',
                        '--light-grey': '#f2f2f4',
                        '--silver-chalice': '#a9a9a9',
                        '--medium-grey': '#828282',
                        '--dark-grey': '#5a5a5a',
                        '--just-blue': '#0071e0',
                        '--text-black': '#47525d',
                        '--green': '#30d68f',
                        '--red': '#ff256d',
                        '--grey': '#eaeaea',
                        '--blackcurrant': '#3B2B45',
                        '--eggblue': '#00C8CE',
                        '--color-ghost': '#CED4DA',
                        '--individual': '#6b8397',
                        '--business': '#323232',
                        '--charity': '#5e9957',
                        '--franchise': '#3B2B45',
                        '--yellow-warning': '#ffe399',
                        '--purple': '#7e21b9'
                    }
                });
                break;
            }
        }
    }
} 