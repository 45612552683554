import { Component, TemplateRef, ViewChild } from '@angular/core';
import { AppService } from '@appmodule/app.service';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  public modalRef: BsModalRef;
  public confirmModalMessage: string;
  public message: string;
  public modalSubs: Subscription;
  public isLoading: boolean = false;
  @ViewChild('modalTemplate') modalTemplate;
  @ViewChild('modalConfirmTemplate') modalConfirmTemplate;


  constructor(private router: Router, public appService: AppService) {
    this.appService.modalTitleSubject.subscribe((title: string) => {
      this.confirmModalMessage = title;
    });

    this.appService.modalConfirmTitleSubject.subscribe((title: string) => {
      this.message = title;
    });
    this.appService.appLoadingSubject.subscribe((status: boolean) => {
      this.isLoading = status;
    });
  }

  ngAfterViewInit() {
    this.appService.getUser();
    this.appService.modalTemplate = this.modalTemplate;
    this.appService.modalConfirmTemplate = this.modalConfirmTemplate;
  }
}
