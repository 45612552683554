/// <reference types="stripe-v3" />
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { WalletWebService } from '@providers/web.service';
import { environment } from '@app/environments/environment';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseUser } from '@app/models/base-user';
import { AppService } from '@app/modules/app/app.service';
import { DirectDebitType } from '@app/utils/Enumerations';


@Component(
    {
        selector: 'direct-debit',
        templateUrl: './direct-debit.component.html'
    }
)
export class DirectDebitComponent {
    private stripe: stripe.Stripe
    @Input()
    public subscription: boolean = false;
    @Input()
    public business_id: number = -1;
    @Output()
    public createPaymentIdFinish: EventEmitter<string> = new EventEmitter();
    @Output()
    public onError: EventEmitter<void> = new EventEmitter();

    public _user: BaseUser = new BaseUser();
    @Input()
    public get user(): BaseUser {
        return this._user;
    }
    public set user(v: BaseUser) {
        this._user = v;
        if(this._id < 0) {
            this.paymentForm.get("tax_refund").patchValue(this.user.tax_refund);
        } else {
            this.paymentForm.get("tax_refund").patchValue(false);
        }
    }
  
    private _id: number = -1;
    @Input()
    public get id(): number {
        return this._id;
    }
    public set id(v: number) {
        this._id = v;
        if(this._id < 0) {
            this.paymentForm.get("tax_refund").patchValue(this.user.tax_refund);
        } else {
            this.paymentForm.get("tax_refund").patchValue(false);
        }
    }
  
    public paymentForm: FormGroup = new FormGroup(
      {
          amount: new FormControl(0, Validators.required),
          tax_refund: new FormControl(this.user.tax_refund),
          recurring: new FormControl(false)
      }
    )
    @Input()
    public disabled: boolean = false;
    @Input()
    public showAddButton: boolean = true;
    @Input()
    public disableButton: boolean = true;
    @Input()
    public showGiftAid: boolean = true;
    @Input()
    public showAmount: boolean = true;

    constructor(private walletWebService: WalletWebService, private appService: AppService) {
    }

    ngOnInit() {
        if (this.subscription) {
            this.stripe = Stripe(environment.stripeSubKey)
        } else {
            this.stripe = Stripe(environment.stripeKey)
        }

        if (this.disabled) {
            this.paymentForm.get('amount').disable();
        }

        if(this.id < 0) {
            this.paymentForm.get("tax_refund").patchValue(this.user.tax_refund);
        } else {
            this.paymentForm.get("tax_refund").patchValue(false);
        }
        this.user = this.appService.getUser();
    }

    public async goToStripeCheckout(){
        let params = this.paymentForm.getRawValue()
        params.type = DirectDebitType.DirectDebitWalletCharge
        params.individual = true;
        
        if(this.business_id && this.business_id > 0 ){
            params.individual = false;
            let session = await this.walletWebService.createBusinessStripeCheckoutSession(this.business_id, params);
            let resp = await this.stripe.redirectToCheckout({sessionId: session,})
            return
        }
        if(this.user.id && this.user.id > 0 ){
            this.stripe = Stripe(environment.stripeKey)
            let session = await this.walletWebService.createUserStripeCheckoutSession(params);
            let resp = await this.stripe.redirectToCheckout({sessionId: session,})
            return
        }
        return
    }
}
