export enum CharityAdminPrivs {
    SuperAdmin = 1,
    Admin = 2
}

export enum CompanyAdminPrivs {
    SuperAdmin = 1,
    Admin = 2,
    Employee = 99
}

export enum DonationStatus {
    WaitingBusinessApproval = 20,
    WaitingCharityApproval = 30,
    WaitingIICAdminApproval = 40,
    Approved = 80,
    Completed = 85,
    Canceled = 90
}

export enum ProjectStatus {
    Canceled = 0,
    Draft = 1,
    Live = 2,
    Completed = 3,
    FinalState = 9
}

export enum GroupAdminPrivs {
    SuperAdmin = 1,
    Admin = 2,
    Member = 99
}

export enum GroupType {
    Business = 1,
    Individual = 2,
}

export enum DirectDebitType {
    DirectDebitWalletCharge   =  1,
	DirectDebitProjectDonation = 2,
    DirectDebitCharityDirectDonation = 3,
    DirectDebitBusinessSubscription = 4
}

export enum WalletType {
    Wallet   =  1,
	CreditOrDebitCard = 2,
	DirectDebit = 3,
}