import { Component, EventEmitter, ChangeDetectorRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CompanyService } from '@app/modules/business/business.service';
import { SubscriptionWebService, WalletWebService } from '@providers/web.service';
import { CompanyModel } from '@app/models/company-model';
import { GlobalConstants } from '@app/utils/Constants';
import { CompanySubscription } from '@app/models/company-subscription';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription, fromEvent } from 'rxjs';
import { CurrencyMaskConfig } from 'ng2-currency-mask/src/currency-mask.config';
import { AppService } from '@app/modules/app/app.service';
import { debounceTime } from 'rxjs/operators';
import { CreditCardComponent } from '../credit-card/credit-card.component';
import { DirectDebitType, WalletType } from '@app/utils/Enumerations';
import { environment } from '@app/environments/environment';


@Component({
  selector: 'business-profile-settings-subscription-edit',
  templateUrl: './subscription-edit.component.html'
})
export class BusinessSubscriptionEditComponent {

  public plan: number = null;

  public company: CompanyModel;

  public fee_types = Object.keys(GlobalConstants.FEE_TYPES).map((e)=> {return GlobalConstants.FEE_TYPES[e]})

  public company_employee_annual_fee = 10;
  public company_employee_monthly_fee = 1;

  public enterprise_employee_annual_fee = 1;
  public enterprise_employee_monthly_fee = 0.1;

  public employee_count: number = 0;
  public fee: any = null;

  public lines = [];

  public regular_fee: number = 0;

  public employees_2000 = 0;
  public employees_over_2000 = 0;

  public employees_2000_fee = 0;
  public employee_over_2000_fee = 0;

  public maxMembers = false;
  public disabled: boolean = false;

  public activePlans = [false, false];

  public isLoading: boolean = false;

  public companySub: Subscription;
  public subFormSub: Subscription;

  public createPaymentId: EventEmitter<void> = new EventEmitter();

  public subscriptionEditForm: FormGroup = new FormGroup({
    employees_count: new FormControl(5, Validators.required),
    discount_code: new FormControl(""),
    acceptAgreement: new FormControl(true),
    wallet_type: new FormControl(2)
  });

  public inputMaskConfig: CurrencyMaskConfig = {
    align: "right",
    allowNegative: false,
    decimal: ".",
    precision: 0,
    prefix: "",
    suffix: "",
    thousands: ","
  };

  @ViewChild(CreditCardComponent)
  public creditCard: CreditCardComponent
  private stripe: stripe.Stripe

  constructor(
    public companyService: CompanyService,
    public activeRoute: ActivatedRoute,
    private subscriptionWebService: SubscriptionWebService,
    private router: Router,
    private appService: AppService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    this.companyService.isSubscribing = true;
    this.companySub = this.companyService.companySource.subscribe(async (company: CompanyModel) => {
      this.company = company;
      await this.init()
    });

    this.company = this.companyService.getCompany();
    this.init();

    this.subFormSub = this.subscriptionEditForm.get('employees_count').valueChanges
      .pipe(debounceTime(1500))
      .subscribe(number_employees => {
        this.company.subscription_number_employees = number_employees;
        if (number_employees < 1 || number_employees < this.companyService.users.length) this.maxMembers = true; else this.maxMembers = false;
        this.getPreview();
      });
  }

  private async init() {
    if (!this.company || this.company.company_id < 0) {
      return;
    }

    if ((this.companyService.userPrivs > 1 || this.companyService.userPrivs == -1) && !this.companyService.inAdmin) {
      this.subscriptionEditForm.disable();
    }
    if (this.company.company_id > -1 && this.company.is_subscribed && !this.company.subscription) {
      let sub = await this.subscriptionWebService.getSubscription(this.company.company_id)
      let subModel = new CompanySubscription().fromJSON(sub);
      this.company.subscription_number_employees = subModel.quantity;
      this.company.subscription = subModel;
      this.companyService.setCompany(this.company);
    }
    if (this.company.is_subscribed) {
      this.subscriptionEditForm.patchValue({
        employees_count: this.company.subscription.quantity
      });
    } else if (this.company.users.length) {
      this.subscriptionEditForm.patchValue({
        employees_count: this.company.users.length
      });
    }
    if(this.companyService.inAdmin){
      this.subscriptionEditForm.get('acceptAgreement').patchValue(true)
    }
  }


  ngOnDestroy() {
    if (this.companySub) this.companySub.unsubscribe();
    if (this.subFormSub) this.subFormSub.unsubscribe();
    this.companyService.isSubscribing = false;
  }

  public selectPlan(plan) {
    this.activePlans = [false, false];

    this.plan = plan;
    this.activePlans[this.plan - 1] = true;

    this.getPreview();
  }

  public async getPreview() {
    let params = {
      plan: this.plan,
      discount_code: this.subscriptionEditForm.get('discount_code').value,
      employees_count: this.subscriptionEditForm.get('employees_count').value,
    };

    if (!params.plan || !this.company || this.company.company_id === -1 || params.employees_count === 0) return;

    this.employee_count = params.employees_count;

    this.isLoading = true;
    try {
      let res = await this.subscriptionWebService.getSubscriptionPreview(this.company.company_id, params)
      this.fee = res;
      this.fee.amount_due /= 100;
      this.fee.amount_off /= 100;
      this.fee.amount_total /= 100;
      this.fee.amount_subtotal /= 100;
      this.fee.amount_ballance /= 100;
      this.fee.total_tax_amounts /= 100;
      this.fee.tax_percentage = res.tax_percentage || Math.round((this.fee.amount_total - this.fee.amount_subtotal) / this.fee.amount_subtotal * 100);
      this.calculateNextFees(params.employees_count);
      this.lines = this.getSortedLines(this.fee.lines).map((l) => {
        l.amount /= 100;
        return l;
      });
      this.setTieredFees(params.employees_count);
    } finally {
      this.isLoading = false;
    }
  }

  private getSortedLines(lines) {
    return lines.sort((a, b) => {
      if (a.type < b.type)
        return -1;
      if (a.type > b.type)
        return 1;

      if (a.period_start < b.period_start)
        return -1;
      if (a.period_start > b.period_start)
        return 1;

      return 0;
    })
  }

  private calculateNextFees(number_employees) {
    if (!this.company) {
      return;
    }

    this.regular_fee = this.getFee(number_employees);
    if (this.fee.is_valid_discount_code && this.fee.duration == 'forever') {
      this.regular_fee = this.getWithDiscount(this.regular_fee);
    }
  }

  private setTieredFees(number_employees) {
    this.employees_2000 = number_employees;
    if (number_employees > 2000) {
      this.employees_2000 = 2000;
    }

    this.employees_2000_fee = this.getFee(this.employees_2000);

    this.employees_over_2000 = number_employees - 2000;
    if (this.employees_over_2000 < 0) {
      this.employees_over_2000 = 0;
      this.employee_over_2000_fee = 0;
      return;
    }
    this.employee_over_2000_fee = this.getEnterpriseFee(this.employees_over_2000);
  }

  private getFee(number_employees) {
    let amount;
    if (number_employees <= 2000) {
      switch (this.plan) {
        case 1: // Monthly
          amount = this.company_employee_monthly_fee * number_employees;
          break;
        case 2: // Annual
          amount = this.company_employee_annual_fee * number_employees;
          break;
      }
    } else {
      switch (this.plan) {
        case 1: // Monthly
          amount = this.company_employee_monthly_fee * 2000 + ((number_employees - 2000) * this.enterprise_employee_monthly_fee)
          break;
        case 2: // Annual
          amount = this.company_employee_annual_fee * 2000 + ((number_employees - 2000) * this.enterprise_employee_annual_fee)
          break;
      }
    }

    return amount;
  }

  private getEnterpriseFee(amount) {
    switch (this.plan) {
      case 1: // Monthly
        return amount * this.enterprise_employee_monthly_fee;
      case 2: // Annual
        return amount * this.enterprise_employee_annual_fee;
    }
  }

  public getWithDiscount(amount) {
    let res = amount;
    if (this.fee.percent_off) {
      res -= (res * this.fee.percent_off / 100)
    } else if (this.fee.amount_off) {
      res -= this.fee.amount_off;
    }

    if (res < 0) {
      res = 0;
    }

    return res;
  }

  public onSubmit() {
    if(this.subscriptionEditForm.get('wallet_type').value == WalletType.CreditOrDebitCard){
      this.payWithCreditDebitCard();
    }
    if(this.subscriptionEditForm.get('wallet_type').value == WalletType.DirectDebit){
      this.payWithDirectDebit()
    }
  }

  public payWithCreditDebitCard(){
    this.disabled = true;
    if (this.maxMembers) return;
    if(this.companyService.inAdmin){
      this.createPaymentIdFinish(null);
    } else {
      this.createPaymentId.next();
    }
  }

  public async payWithDirectDebit(){
    this.stripe = Stripe(environment.stripeSubKey)
    this.appService.setAppLoading(true);
    if(this.company && this.company.company_id > 0 ){
      let params = this.subscriptionEditForm.getRawValue();
      params.payment_type = DirectDebitType.DirectDebitBusinessSubscription
      params["plan"] = this.plan;
      
      if (this.company && this.company.company_id > 0 && this.company.is_subscribed){
        let session = await this.subscriptionWebService.createBusinessDirectDebitStripeCheckoutSession(this.company.company_id, params, false, true);
        this.appService.setAppLoading(false);
        let resp = await this.stripe.redirectToCheckout({sessionId: session,})
        this.router.navigate(['business', this.company.company_id, 'profile', 'thank-you-msg']);
        return
      }

      let session = await this.subscriptionWebService.createBusinessDirectDebitStripeCheckoutSession(this.company.company_id, params);
      this.appService.setAppLoading(false);
      let resp = await this.stripe.redirectToCheckout({sessionId: session,})
      this.router.navigate(['business', this.company.company_id, 'profile', 'thank-you-msg']);
      return
    }
  }

  onError() {
    this.disabled = false;
  }

  public async createPaymentIdFinish(event: string) {
    this.changeDetectorRef.detectChanges();
    this.appService.setAppLoading(true);
    let params = this.subscriptionEditForm.getRawValue();
    params["token"] = event;
    params["plan"] = this.plan;

    try {
      let sub = {};
      if(this.companyService.inAdmin){
        sub = await this.subscriptionWebService.createSubscriptionFromAdmin(this.company.company_id, params);
      } else {
        sub = await this.subscriptionWebService.createSubscription(this.company.company_id, params, false);
        await this.creditCard.handleServerResponse(sub, true);
      }
      
      //this.company.is_subscribed = true;
      this.company.subscription = new CompanySubscription().fromJSON(sub);
      this.companyService.setCompany(this.company);
      if(this.companyService.inAdmin){ 
        this.router.navigate(['../'], {relativeTo: this.activeRoute});
        this.companyService.hideSubscriptionWarning();
      } else {
        // this.router.navigate(['business', this.company.company_id, 'profile', 'settings']);
        this.router.navigate(['business', this.company.company_id, 'profile', 'thank-you-msg']);
        this.companyService.hideSubscriptionWarning();
      }

    } finally {
      this.disabled = false;
      this.appService.setAppLoading(false);
    }
  }
}
