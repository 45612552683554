import { BaseModel } from "./base-model";
import { ProjectModel } from "./project-model";
import { GlobalConstants } from "@app/utils/Constants";
import { GalleryModel } from "@app/models/gallery-model";

export class ImpactStatement extends BaseModel {
    public community_credits_amount: number = 0;
    public donations_value: number = 0;
    public gifting_value: number = 0;
    public hours_volunteered_pro_bono: number = 0;
    public hours_volunteered_total: number = 0;
    public volunteers_total: number = 0;
    public number_of_supporters: number = 0;
    public people_donated_gifts: number = 0;
    public impact_statement: string = null;
    public special_thanks: string = null;
    public published: boolean = false;
    public project: ProjectModel = null;
    public gifts: Array<IGift> = [];
    public item_received: number = 0;
    public gallery

    public fromJSON({ community_credits_amount,
        donations_value,
        gifting_value,
        hours_volunteered_pro_bono,
        hours_volunteered_total,
        volunteers_total,
        impact_statement,
        number_of_supporters,
        project,
        published,
        special_thanks,
        people_donated_gifts,
        gifts,
        galery }
    ) {
        this.community_credits_amount = community_credits_amount || this.community_credits_amount;
        this.donations_value = donations_value / 100 || this.donations_value;
        this.gifting_value = gifting_value || this.gifting_value;
        this.hours_volunteered_pro_bono = hours_volunteered_pro_bono || this.hours_volunteered_pro_bono;
        this.hours_volunteered_total = hours_volunteered_total || this.hours_volunteered_total;
        this.volunteers_total = volunteers_total || this.volunteers_total;
        this.impact_statement = impact_statement || this.impact_statement;
        this.number_of_supporters = number_of_supporters || this.number_of_supporters;
        this.published = !!published;
        this.special_thanks = special_thanks || this.special_thanks;
        this.people_donated_gifts = people_donated_gifts || this.people_donated_gifts
        this.project = new ProjectModel().fromJSON(project);
        this.gallery = (galery) ? galery.map(image => new GalleryModel().fromJSON(this.project.id, image)) : this.gallery;

        for(let gift of gifts) {
            let g: IGift = {
                amount: gift["amount"],
                group_id: gift["group_id"],
                group_name: GlobalConstants.giftTypesMap[gift["group_id"]]
            };
            this.item_received += gift["amount"];
            this.gifts.push(g);
        }

        return this;
    }
}


interface IGift {
    amount: number;
    group_id: number;
    group_name: string;
  }