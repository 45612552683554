import { BaseModel } from "./base-model";

export class BusinessSubGroup extends BaseModel {
    public id: number = null;
    public name: string = null;
    public location: string = null;

    public fromJSON({id,name,location }
    ) {
        this.id = id || this.id;
        this.name = name || this.name;
        this.location = location || this.location;

        return this;
    }
}