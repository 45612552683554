// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // webServiceUri: 'http://10.115.5.41:8282/api',
  // imageUrl: 'http://10.115.5.41:8282',
  // webServiceUri: 'http://127.0.0.1:8282/api',
  // imageUrl: 'http://127.0.0.1:8282',
  // webServiceUri: 'https://iicdev.conquex.com/api',
  // imageUrl: 'https://iicdev.conquex.com',
  // stripeKey: 'pk_test_CzsUtGwcq9t8sTv876WCYnok',
  // stripeSubKey: 'pk_test_9geOcaIFu6mhF7P1w4akhtd4009VgMBJ36',
  // stripeCA: 'ca_E85VxCC8wBD7E5Y8d98PVpoAekj1bIdn'

  // webServiceUri: "https://api.investorsincommunity.org/api",
  // imageUrl: "https://api.investorsincommunity.org/",
  // stripeKey: 'pk_live_qI89MGlZFxWyhPgNYXUjPEDb',
  // stripeSubKey: 'pk_live_niSPNApxClmOJl3gN70jXg2i',
  // stripeCA: 'ca_E6ZhuUMny9VIzZCmleDROn5GAAZyTv7T'

  webServiceUri: "https://iicapi.manodarpan.com/api",
  imageUrl: "https://iicapi.manodarpan.com/",
  stripeKey: 'pk_test_cYBKeXPsczPojzkkoIA1ZQ6w',
  stripeSubKey: 'sk_test_TmHpROesoKjDbFK1qQ8quq7m',
  stripeCA: 'ca_E85VxCC8wBD7E5Y8d98PVpoAekj1bIdn'
};

/*
 * In development mode, for easier debugging, you can ignore zone related error
 * stack frames such as `zone.run`/`zoneDelegate.invokeTask` by importing the
 * below file. Don't forget to comment it out in production mode
 * because it will have a performance impact when errors are thrown
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
