import { environment } from '@app/environments/environment';
import { BaseModel } from './base-model';
export class GalleryModel {

    public FileID: number = -1
    public FileUUID: string = null;
    public MIME: string = null;
    public Path: string = null;
    public ProjectID: number = -1;
    public RealName: string = null;
    public url: string = null;

    public fromJSON(id: string, json: any) {
        this.FileID = json["FileID"] || this.FileID;
        this.FileUUID = json["FileUUID"] || this.FileUUID;
        this.MIME = json["MIME"] || this.MIME;
        this.Path = json["Path"] || this.Path;
        this.ProjectID = json["ProjectID"] || this.ProjectID;
        this.RealName = json["RealName"] || this.RealName;
        this.url = `url('${environment.imageUrl}${this.Path}')`;
        return this;
    }
}