import { Component, OnInit, Input, TemplateRef, ViewChild } from '@angular/core';
import { GroupCardModel } from '@app/models/group-card-model';
import { Router } from '@angular/router';
import { AppService } from '@app/modules/app/app.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'group-card',
  templateUrl: './group-card.component.html'
})

export class GroupCard {
  @Input() group: GroupCardModel = null;
  @ViewChild('chouseUser') chouseUser: TemplateRef<any>;
  public businesses: any;
  public selectUserModal: BsModalRef;
    
    constructor(private router: Router, private appService: AppService, private modalService: BsModalService) {
    }

    onGroupClick(id){
      this.businesses = this.appService.getUserBusinesses()
      
      if(this.businesses.length > 0){
        this.selectUserModal = this.modalService.show(this.chouseUser)
      }else{
        this.router.navigate(['group', id]);
      }
    }
    
    onUserSelected(id, business){
      if(business){
        this.router.navigate(['group', id, 'business', business.business_id, 'profile']);
      }else{
        this.router.navigate(['group', id]);
      }
    }
}
