import { Component, Input } from '@angular/core';
import { ProjectModel } from '@models/project-model';
import { GlobalConstants } from '@utils/Constants';
import { Router } from '@angular/router';
import { CharityModel } from '@models/charity-model';
import { ProjectCardModel } from '@app/models/project-card-model';

@Component(
    {
        selector: 'project-card',
        templateUrl: './project-card.component.html'
    }
)

export class ProjectCard {
    @Input() project: ProjectCardModel = null;
    @Input() charity: CharityModel = null;
    
    public givingTypes = GlobalConstants.TypeOfGivingMap;
    public giftTypes = GlobalConstants.giftTypesMap;
    public skilledTypes = GlobalConstants.SkilledVolunteeringTypesMap;
    
    constructor(private router: Router) {
    }
}