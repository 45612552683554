import { InvitationWebService } from '@providers/web.service';
import { AppService } from '@appmodule/app.service';
import { Component, OnInit, isDevMode } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators'
import { Subscription } from 'rxjs';

@Component({
  selector: 'email-invite',
  templateUrl: './email-invite.component.html'
})
export class EmailInviteComponent {

  public routeSub: Subscription

  constructor(
    public appService: AppService,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private invitationWebService: InvitationWebService
  ) {
  }

  ngOnInit() {
    this.routeSub = this.activatedRoute.params.pipe(map(params => params.key))
      .subscribe(params => {
        this.invitationWebService.confirmInvitation(params).then(()=>{
          this.appService.showSuccess("Invitation Accepted", "Invitation accepted successfully")
          this.router.navigate(['/login']);
        });
      });
  }

  ngOnDestroy() {
    if(this.routeSub) this.routeSub.unsubscribe();
  }
}
