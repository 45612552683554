import { AppService } from '@appmodule/app.service';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { UploaderOptions, UploadFile, UploadInput, UploadOutput, UploadStatus } from 'ngx-uploader';
import { UploaderOptionsExtended } from '@interfaces/uploader-options-extended'

@Component(
    {
        selector: 'uploader',
        templateUrl: './uploader.component.html'
    }
)

export class Uploader {

    @Input()
    public options: UploaderOptionsExtended;
    @Input()
    public multiple: boolean;
    @Input()
    public imagePreview: any
    formData: FormData;
    files: UploadFile[];
    uploadInput: EventEmitter<UploadInput>;
    @Input()
    public uploadInputEvent: UploadInput = null;
    humanizeBytes: Function;
    dragOver: boolean;
    uploadPromiseResolve = null;
    @Input()
    public disabled: boolean = false;

    constructor(private appService: AppService) {
        this.files = [];
        this.uploadInput = new EventEmitter<UploadInput>();
    }

    private showCountNumber(count:number){
        let res = this.appService.showModal("You are about to import "+ count + " employee emails");
        if (res) {
            return
        }
    }

    onUploadOutput(output: UploadOutput): void {
        if(!this.multiple && this.files.length > 1){
            this.files = this.files.slice(-1);
        }
        
        if (output.type === 'allAddedToQueue') {
        } else if (output.type === 'addedToQueue' && typeof output.file !== 'undefined') {
            if (output.file.type === "text/csv"){
                this.readCSV(output.file.nativeFile).then(response => {
                    let count = this.checkEmailsCount(response)
                    this.showCountNumber(count)    
                    if(count == 0){
                        this.files = []
                    }
                });
            }
            if (!this.multiple && !this.disabled && output.file.size < this.options.maxFileSize) {
                this.previewImage(output.file).then(response => {
                    if (response) {
                        this.imagePreview = 'url(' + response + ')'; //Image preview
                    }
                });
            }
            if (!this.disabled && output.file.size < this.options.maxFileSize) this.files.push(output.file);
            else this.appService.showWarning("File size exceeded", "Please choose a smaller file.")
        } else if (output.type === 'uploading' && typeof output.file !== 'undefined') {
            const index = this.files.findIndex(file => typeof output.file !== 'undefined' && file.id === output.file.id);
            this.files[index] = output.file;
        } else if (output.type === 'removed') {
            this.files = this.files.filter((file: UploadFile) => file !== output.file);
        } else if (output.type === 'dragOver') {
            this.dragOver = true;
        } else if (output.type === 'dragOut') {
            this.dragOver = false;
        } else if (output.type === 'drop') {
            this.dragOver = false;
        } else if (output.type === 'rejected' && typeof output.file !== 'undefined') {
            this.appService.showWarning("Not supported extension", "We do not allow such files. Please choose a different one.")
        }

        let finishedFiles: Array<UploadFile> = this.files.filter(file => !((file.progress.status === UploadStatus.Done) || (file.progress.status === UploadStatus.Cancelled)));
        if (finishedFiles.length == 0) {
            if (output && output.file && output.file.response) {
                this.uploadPromiseResolve(output.file.response);
            } else {
                this.uploadPromiseResolve(null);
            }
            this.files = [];
        }
    }

    private readCSV(file:any){
        const fileReader = new FileReader();
        return new Promise(resolve => {
            fileReader.readAsText(file);
            fileReader.onload = function (e: any) {
                resolve(e.target.result);
            }
        });
    }

    private checkEmailsCount(response:any){
        let result = []
        let row = response.split(/\r\n|\n/)
        row.forEach(e => {
            let em = e.trim()
            if(this.validateEmail(em)){
                result.push(em)        
            }
        });
        return result.length           
    }

    private validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    public async startUpload(): Promise<any> {
        this.uploadInputEvent.type = 'uploadAll';
        this.uploadInputEvent.file = this.files[0];
        if (this.files.length > 0) {
            return new Promise<any>((resolve, reject) => {
                this.uploadPromiseResolve = resolve
                this.uploadInput.emit(this.uploadInputEvent);
            })
        }
        return null;
    }

    removeAllFiles(): void {
        this.uploadInput.emit({ type: 'removeAll' });
    }


    cancelUpload(id: string): void {
        this.uploadInput.emit({ type: 'cancel', id: id });
    }


    // The preview function
    private previewImage(file: any): Promise<any> {
        const fileReader = new FileReader();
        return new Promise(resolve => {
            fileReader.readAsDataURL(file.nativeFile);
            fileReader.onload = function (e: any) {
                resolve(e.target.result);
            }
        });
    }
}

