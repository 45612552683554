import { BaseModel } from "./base-model";

export class GiftModel extends BaseModel {
    public id: number = -1;
    public name: string = null;
    public price: number = 0;
    public cid: number = 1;
    public cname: string = "";
    public communityCredits: number = 0;
    public associated_givings_cnt: number = 0;


    public fromJSON(json: any) {
        this.id = json["id"] || this.id;
        this.name = json["name"] || this.name;
        this.price = json["price"] || this.price;

        if (isNaN(Number(json["price"]))) {
            this.price = this.price;
        } else {
            this.price = (json["price"] / 100);
        }

        this.cid = json["cid"] || this.cid;
        this.cname = json["cname"] || this.cname;
        this.communityCredits = this.price;
        this.associated_givings_cnt = json["associated_givings_cnt"] || this.associated_givings_cnt;
        return this;
    }

    public toJSON() {
        return {
            id: this.id,
            name: this.name,
            price: this.price,
            cid: this.cid,
            cname: this.cname
        };
    }
}