export interface IBaseModel {
    fromJSON(json: any): any;
    toJSON(): any;
}

export class BaseModel implements IBaseModel {
    public fromJSON(json: any): any {
        return this;
    }

    public toJSON() {
        return null;
    }

    public clear(obj): any {
        // Clear empty fields
        for (let prop in obj) {
            if (obj[prop] == null) {
                delete obj[prop];
            }
        }

        return obj;
    }
}