import {Component, Input, Output, EventEmitter} from '@angular/core';

@Component(
    {
        selector: 'iic-progress',
        templateUrl: './progress.component.html'
    }
)

export class Progress {

    public currentValue: number = 0;

    @Input()
    public total: number = 0;
    @Input()
    public current: number = 0;

    ngOnInit() {
        this.currentValue = (this.current / this.total) * 100;
    }
}