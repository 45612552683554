import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { AppService } from '@appmodule/app.service';
import { Router } from '@angular/router';

@Injectable()
export class AuthGuardNoRedirect implements CanActivate {

  constructor(protected appService: AppService,
    protected router: Router) {
  }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.appService.isUserLoggedIn()) {
        this.appService.clearSession();
    } 
    return true;
  }
}