import { Component, Input, forwardRef, EventEmitter, Output, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { ValueAccessorBase } from '@app/utils/ValueAccessorBase';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Utils } from '@app/utils/utils';

@Component(
    {
        selector: 'location-autocomplete',
        templateUrl: './location-autocomplete.component.html',
        providers: [
            {
                provide: NG_VALUE_ACCESSOR,
                useExisting: forwardRef(() => LocationAutocomplete),
                multi: true
            }
        ]
    }
)

export class LocationAutocomplete extends ValueAccessorBase<string>{
    private _locations: Array<string> = [];
    public suggestedLocations: Array<string> = [];

    public idx: number = -1;
    public current: any;
    public uniqId: number;

    @ViewChild('dropdown') dropdown;
    @ViewChild('search') searchInput: ElementRef;

    @Input('locations')
    set locations(value) {
        this._locations = value;
        this.suggestedLocations = this._locations.slice(0, 4);
    }

    @Output() onChange: EventEmitter<string> = new EventEmitter<string>();

    get locations(): Array<string> {
        return this.locations;
    }

    constructor() {
        super()
        this.uniqId = Utils.getUniqCounter();
    }

    public selectLocation(location) {
        this.value = location;
        this.dropdown.hide();
        this.suggestedLocations = this._locations;
        this.idx = -1;
        this.onChange.emit(this.value);
    }

    public searchLocation(searchTerm) {
        let search = searchTerm.toUpperCase();
        this.suggestedLocations = this._locations.filter((location) => {
            return location.toUpperCase().indexOf(search) > -1
        })
        this.idx = -1;
    }

    public setInputFocus() {
        this.suggestedLocations = this._locations.slice(0, 4);
        setTimeout(() => {
            this.searchInput.nativeElement.focus();
        }, 100)
    }

    public onKeyUp(event: KeyboardEvent) {
        if (event.keyCode == 40) {
            // Down Arrow Pressed
            if (this.suggestedLocations.length > 0) {
                if (this.idx == (this.suggestedLocations.length - 1)) {
                    return;
                }
                this.idx++;
                this.current = this.suggestedLocations[this.idx];
            }
            // if (this.current) {
            //     document.getElementById(this.uniqId + '-' + this.idx).scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
            // }
            return;
        }
        if (event.keyCode == 38) {
            // Up Arrow Pressed
            if (this.suggestedLocations.length > 0) {
                if (this.idx == 0) {
                    return;
                }
                this.idx--;
                this.current = this.suggestedLocations[this.idx];
            }
            // if (this.current) {
            //     document.getElementById(this.uniqId + '-' + this.idx).scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
            // }
            return;
        }
        if (event.keyCode == 13 && this.current) {
            this.selectLocation(this.current);
        }
    }

    onRemoveLocation() {
        this.selectLocation(null);
    }

    public onModalClick($event) {
        $event.stopPropagation();
        $event.preventDefault();
    }
}