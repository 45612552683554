import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CompanyService } from '@app/modules/business/business.service';
import { CompanyModel } from '@app/models/company-model';
import { Utils } from '@app/utils/utils';
import { SubscriptionWebService } from '@app/providers/web.service';
import { GlobalConstants, ThemeEnum } from '@app/utils/Constants';
import { Subscription } from 'rxjs';
import { AppService } from '@app/modules/app/app.service';
import { BaseWebService } from '@app/providers/base-web.service';

@Component({
  selector: 'business-profile-settings-subscription',
  templateUrl: './subscription.component.html'
})
export class BusinessSubscriptionComponent {

  public company: CompanyModel = new CompanyModel();

  public fee_types = Object.keys(GlobalConstants.FEE_TYPES).map((e)=> {return GlobalConstants.FEE_TYPES[e]})
  public companySub: Subscription;

  public fee: any;
  public next_payment_attempt: Date;
  public is_division: boolean = false;

  public businessId: any;
  public isBusiness=false;

  public subscriptionForm: FormGroup = new FormGroup({
    id: new FormControl('', [Validators.required]),
    number_employees: new FormControl({ value: '', disabled: true }),
    company_fee: new FormControl({ value: '', disabled: true })
  });

  constructor(
    public router: Router,
    public businessService: CompanyService,
    private activeRoute: ActivatedRoute,
    private subscriptionWebService: SubscriptionWebService,
    private appService: AppService
  ) {
    this.companySub = this.businessService.companySource.subscribe(async (company: CompanyModel) => {
      this.company = company;
      await this.init();
    });

    this.company = this.businessService.getCompany();
    this.init();
    this.appService.changeThemeColor(ThemeEnum.Individual);
  }


  ngOnDestroy() {
    if (this.companySub) this.companySub.unsubscribe();
  }

  private async init() {
    if (!this.company || this.company.company_id < 0) {
      return;
    }

    this.activeRoute.parent.params.subscribe(params => {
      this.businessId = params['id'];
    });

    this.activeRoute.parent.url.subscribe(urlSegments => {
      this.isBusiness = urlSegments[0] != undefined && urlSegments[0].path == 'business'?true:false;
    });
    
    if (this.company.is_subscribed && this.company.subscription) {
      this.subscriptionForm.patchValue({
        number_employees: this.company.subscription.quantity,
        company_fee: Utils.formatAsCurrency(this.company.subscription.amount),
        ...this.company,
      });
      
      this.is_division = this.company.company_parent_company != null;
      this.getPreview();
    }
  }

  public async getPreview() {
    this.subscriptionWebService.getCurrentSubscription(this.company.company_id).then(res => {
      this.fee = res;
      this.fee.amount_due = this.fee.amount_due / 100;
    });
  }

  public navigateSubscribe() {
    if(this.businessService.inAdmin){
      this.router.navigate(['../sub-edit'], { relativeTo: this.activeRoute });
    } else {
      this.router.navigate(['./sub'], { relativeTo: this.activeRoute });
    }
  }

  public async onboardingRequest() {
    this.appService.setAppLoading(true);
    this.subscriptionWebService.onboardingRequest(this.businessId).then((res:any) => {
      BaseWebService.successSubject.next({ summary: 'Successful!', detail: res.message });
      this.appService.setAppLoading(false);
    },
    ()=>{
      this.appService.setAppLoading(false);
    });
  }

}
