import { BaseUser } from '@models/base-user';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AppService } from '../app/app.service';

@Injectable()
export class IndividualService {
  public user: BaseUser = new BaseUser();
  public userSource: Subject<BaseUser> = new Subject();

  public setUser(val: BaseUser) {
    this.user = val;
    this.userSource.next(this.user)
  }

  constructor(private appService: AppService){  }

  public getUser(): BaseUser {
    return this.user;
  }
  public clearService(){
    this.user = new BaseUser();
  }
}
