import { BaseModel } from "./base-model";

export class GiftCategoryModel extends BaseModel {
    public id: number = -1;
    public name: string = null;


    public fromJSON(json: any) {
        this.id = json["id"] || this.id;
        this.name = json["name"] || this.name;

        return this;
    }

    public toJSON() {
        return {
            id: this.id,
            name: this.name
        };
    }
}