import {Pipe, PipeTransform} from '@angular/core';
/*
 * Converts newlines into html breaks
*/
@Pipe({ name: 'extlink' })
export class ExternalLinkPipe implements PipeTransform {
    transform(value: string, args: string[]): any {
        if(!value) return "";
        if(value.startsWith('http://') || value.startsWith('https://')){
            return value;
        } else {
            return 'http://' + value;
        }
    }
}