import { GivingUtils } from '@utils/giving-utils'
import { ProjectModel } from './project-model';
import { environment } from '@app/environments/environment';

export class ProjectCardModel extends ProjectModel {

    public charity_name: string = null;
    public charity_logo: string = null;
    public charityLogoSmall: string = null;

    public fromJSON(json: any) {
        super.fromJSON(json)
        this.charity_name = json["charity_name"] || this.charity_name;
        this.charity_logo = json["charity_logo"] || this.charity_logo;
        if(this.charity_logo) {
            this.charityLogoSmall = `url('${environment.imageUrl}${this.charity_logo}_small')`;
        }
        return this;
    }
}