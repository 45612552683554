/// <reference types="stripe-v3" />
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component(
    {
        selector: 'direct-debit-complete',
        templateUrl: './direct-debit-complete.component.html'
    }
)
export class DirectDebitCompleteComponent {
    public success = null;
    constructor(private route:ActivatedRoute) {
        this.route.queryParams
        .subscribe(params => {
          if(params.success=="true"){
              this.success = true;
          }
          if(params.success=="false"){
              this.success = false;
          }
      })
    }

    ngOnInit() {
    }

}
