import { Component, Input } from '@angular/core';
import { ProjectModel } from '@app/models/project-model';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { CharityWebService } from '@app/providers/web.service';
import { BsModalRef } from 'ngx-bootstrap';
import { GlobalConstants, TypeOfGivingEnum } from '@app/utils/Constants';
import { CurrencyMaskConfig } from 'ng2-currency-mask/src/currency-mask.config';
import { AppService } from '@app/modules/app/app.service';

@Component(
    {
        selector: 'extend-project',
        templateUrl: './extend-project.component.html'
    }
)
export class ExtendProjectComponent {
    @Input() project: ProjectModel;
    @Input() modalRef: BsModalRef;

    public typeOfGivingMap = GlobalConstants.TypeOfGivingMap;
    public extendProjectForm: FormGroup = new FormGroup({
        end_date: new FormControl('', [Validators.required])
    });
    public givings: Array<FormGroup> = [];
    public giftsTypes = GlobalConstants.giftTypes;
    public project_end_date: Date = null;

    public inputMaskConfig: CurrencyMaskConfig = {
        align: "right",
        allowNegative: false,
        decimal: ".",
        precision: 0,
        prefix: "",
        suffix: "",
        thousands: ","
    };
    public requiredDateTooltipText: string = GlobalConstants.RequiredDateTooltipText;
    
    constructor(private _charityWebService: CharityWebService, public appService: AppService) {

    }

    ngOnInit() {
        this.extendProjectForm.get("end_date").patchValue(this.project.end_date);

        for (let typeOfGivingGroup of this.project.givings) {
            (typeOfGivingGroup.controls.items as FormArray).controls.forEach(({ controls }: any) => {
                for (let key in controls) {
                    if (key != 'end_date' && key != 'date_needed' && key != 'id') {
                        controls[key].disable();
                    }
                }
            });
            if (typeOfGivingGroup.value.type == TypeOfGivingEnum.Funds || typeOfGivingGroup.value.type == TypeOfGivingEnum.Gifts) {
                this.givings.push(typeOfGivingGroup)
            }
        }
    }

    public onProjectDateChange() {
        let projectEndDate = this.extendProjectForm.get("end_date").value;

        for (let form of this.givings) {
            let items: FormArray = form.get('items') as FormArray
            if (items && items.controls) {
                for (let group of items.controls) {
                    let end_date = group.get("end_date")
                    if (end_date) {
                        let dateVal = end_date.value;
                        if ((dateVal > projectEndDate)) {
                            end_date.setValue(projectEndDate)
                        }
                    }
                }
            }
        }
    }

    public async extend() {
        let givings = [];
        this.givings.map((items) => {
            for (let giving of items.value.items) {
                givings.push(giving)
            }
        })

        let params = {
            project_id: this.project.id,
            end_date: this.extendProjectForm.get("end_date").value,
            givings: givings
        }
        await this._charityWebService.extendProject(this.project.id, params);
        this.project = this.project.fromJSON(await this._charityWebService.getProjectVolunteered(this.project.id));
        this.modalRef.hide();
    }


}
