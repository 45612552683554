import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { UploadWebService } from '@app/providers/web.service';
import { UploaderOptions, UploadInput } from 'ngx-uploader';
import { CompanyModel } from '@app/models/company-model';
import { Uploader } from '../file-uploader/uploader.component';
import { AppService } from '@app/modules/app/app.service';
import { BaseWebService } from '@app/providers/base-web.service';

@Component({
  selector: 'app-upload-csv-modal',
  templateUrl: './upload-csv-modal.component.html'
})
export class UploadCsvModalComponent implements OnInit {

  public options: UploaderOptions;
  public uploadInputEvent: UploadInput;
  public business: CompanyModel;
  public file: string = null;
  public disabled: boolean = false;
  @ViewChild(Uploader) public uploader: Uploader;

  constructor(
    public modalRef: BsModalRef,
    private _uploadWebService: UploadWebService, 
    public appService: AppService) {
  }
  
  ngOnInit() {
    this.options = this._uploadWebService.CSVOptions()  
    this.uploadInputEvent = this._uploadWebService.businessCSVUpload(this.business.company_id);
  }

  ngDoCheck(){
    if (this.uploader.files.length >0 ){
      this.file = this.uploader.files[this.uploader.files.length-1].name
    }
  }

  public async inviteUsers() {
    let uploadRes = await this.uploader.startUpload();
    this.modalRef.hide();
    if (uploadRes) {
      if (uploadRes.code == 200) {
        BaseWebService.successSubject.next({ summary: 'Your Invites have been sent!', detail: 'Please allow time for them to reach your employees.' });
      }
      if (uploadRes.code >= 400 && uploadRes.code < 500) {
        BaseWebService.errorSubject.next({ status: 400, message: uploadRes.message });
      }
      if (uploadRes.code >= 500) {
        BaseWebService.errorSubject.next({ status: 500, message: uploadRes.message });
      }
    }
  }
}
