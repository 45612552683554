/// <reference types="stripe-v3" />
import { Component, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms'
import { WalletWebService } from '@providers/web.service';
import { environment } from '@app/environments/environment';
import { AppService } from '@app/modules/app/app.service';
import { BaseWebService } from '@app/providers/base-web.service';
import { BaseUser } from '@app/models/base-user';



@Component(
    {
        selector: 'stripe',
        templateUrl: './stripe.component.html'
    }
)
export class StripeComponent {


    private stripe: stripe.Stripe
    private elements: stripe.elements.Elements
    public cardComplete: boolean = false;

    @ViewChild('cardElement')
    public cardEl: ElementRef
    public card: stripe.elements.Element
    public error: string = "";

    public _user: BaseUser = new BaseUser();
    @Input()
    public get user(): BaseUser {
        return this._user;
    }
    public set user(v: BaseUser) {
        this._user = v;
        if(this._id < 0) {
            this.paymentForm.get("tax_refund").patchValue(this.user.tax_refund);
        } else {
            this.paymentForm.get("tax_refund").patchValue(false);
        }
    }

    private _id: number = -1;
    @Input()
    public get id(): number {
        return this._id;
    }
    public set id(v: number) {
        this._id = v;
        if(this._id < 0) {
            this.paymentForm.get("tax_refund").patchValue(this.user.tax_refund);
        } else {
            this.paymentForm.get("tax_refund").patchValue(false);
        }
    }

    @Input()
    public disabled: boolean = false;

    @Input()
    public showAddButton: boolean = true;

    @Input()
    public chargePersonalWallet: boolean = false;

    @Output() onAddMoneyToWallet: EventEmitter<number> = new EventEmitter<number>();

    public paymentForm: FormGroup = new FormGroup(
        {
            amount: new FormControl(0, Validators.required),
            tax_refund: new FormControl(this.user.tax_refund)
        }
    )


    constructor(private walletWebService: WalletWebService, private appService: AppService) {
        this.stripe = Stripe(environment.stripeKey)
        this.elements = this.stripe.elements();
    }


    ngOnInit() {
        if (this.disabled) {
            this.paymentForm.get('amount').disable();
        }
        let style = {
            base: {
                color: '#32325d',
                lineHeight: '16px',
                fontFamily: 'inherit',
                fontSmoothing: 'antialiased',
                fontSize: '14px',
                '::placeholder': {
                    color: '#aab7c4'
                }
            },
            invalid: {
                color: '#fa755a',
                iconColor: '#fa755a'
            }
        };
        // Create an instance of the card Element.
        this.card = this.elements.create('card', { style: style });
        this.card.mount(this.cardEl.nativeElement);
        this.card.on('change', this.cardInfoChanged.bind(this));
        if(this.id < 0) {
            this.paymentForm.get("tax_refund").patchValue(this.user.tax_refund);
        } else {
            this.paymentForm.get("tax_refund").patchValue(false);
        }
        this.user = this.appService.getUser();
    }

    cardInfoChanged(event: any) {
        if (event.error) {
            this.error = event.error.message;
        } else {
            this.error = '';
        }
        if(!event.complete) {
            this.cardComplete = false;
        } else {
            this.cardComplete = true;
        }
    }

    public clearForm() {
        this.card.clear();
        this.paymentForm.get("amount").patchValue(0);
        if(this.id < 0) this.paymentForm.get("tax_refund").patchValue(this.user.tax_refund);
        this.appService.setAppLoading(false);
        this.disabled = false;
    }

    public async onSubmit(showModal: boolean = true, donate?: boolean): Promise<number> {
        let amount = this.paymentForm.get('amount').value;
        let tax_refund = this.paymentForm.get('tax_refund').value;
        if (amount <= 0) {
            this.appService.showWarning("Invalid amount", "Amount must be more than zero.")
            return
        }

        this.disabled = true;
        this.appService.setAppLoading(true);
        let paymentMethodRes: stripe.PaymentMethodResponse = await this.stripe.createPaymentMethod(
            'card',
            this.card
        );

        if (paymentMethodRes.error) {
            this.error = paymentMethodRes.error.message;
            this.appService.setAppLoading(false)
            this.disabled = false;
            return;
        }

        let params = {
            amount: amount,
            payment_method: paymentMethodRes.paymentMethod.id,
            tax_refund: tax_refund,
            is_charge_wallet: this.chargePersonalWallet,
        }
        try {
            let res = null
            if (this.id > -1) {
                params["business_id"] = this.id
                res = await this.walletWebService.addMoneyToBusinessWallet(this.id, params, false)
            } else {
                res = await this.walletWebService.addMoneyToUserWallet(params, false)
            }

            let err = await this.handleServerResponse(res, showModal)
            this.onAddMoneyToWallet.emit(amount);
            if(err && donate) throw err;
            return amount;
        } catch (e) {
            throw e;
        } finally {
            this.clearForm()
        }
    }
    public async handleServerResponse(response, showModal: boolean) {
        if (response.status == 'success') {
            if (response.requires_action) {
                let err = await this.handleAction(response, showModal);
                if(err) return err;
            } else {
                if (showModal) {
                    BaseWebService.successSubject.next({ summary: 'Money added to wallet', detail: 'Successfully added money to wallet!' });
                }
            }
        } else {
            if (response.next_action == 'redirect_to_url') {
                BaseWebService.errorSubject.next({ status: 400, message: 'This card is not supported!' });
                return "This card is not supported!"
            } else {
                BaseWebService.errorSubject.next({ status: 500, message: 'Internal server error' });
                return "Internal server error"
            }
        }
    }

    public async handleAction(response, showModal: boolean): Promise<string> {
        const stripeRes: stripe.PaymentIntentResponse = await this.stripe.handleCardAction(
            response.client_secret
        );
        let params = {};
        if (stripeRes.error) {
            this.error = stripeRes.error.message;
            return this.error;
        } else {
            params = {
                payment_intent_id: stripeRes.paymentIntent.id,
                payment_status: true,
                tax_refund: this.paymentForm.get('tax_refund').value
            }
            if (this.id > -1) {
                await this.walletWebService.confirmBusinessDeposit(this.id, params, showModal);
            } else {
                await this.walletWebService.confirmUserDeposit(params, showModal);
            }

        }
    }
}
