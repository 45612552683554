import { BaseModel } from "./base-model";
import { BusinessSubGroup } from "./business-subgroup";

export class BusinessGroup extends BaseModel {
    public id: number = null;
    public name: string = null;
    public sub_groups: Array<BusinessSubGroup> = [];
    public sub_groups_count: number = 0;

    public fromJSON({ id, name, sub_groups }
    ) {
        this.id = id || this.id;
        this.name = name || this.name;
        if (sub_groups) {
            this.sub_groups_count = sub_groups.length;
            for (let sg of sub_groups) {
                this.sub_groups.push(new BusinessSubGroup().fromJSON(sg));
            }
        }

        return this;
    }
}