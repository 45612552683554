import { Component } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { OrganisationWebService } from '@app/providers/web.service';


@Component({
    selector: 'transfer-funds-modal',
    templateUrl: './transfer-funds-modal.component.html'
})
export class TransferFundsModalComponent {

    public organisationFlat: Array<any> = [];

    public transferForm: FormGroup = new FormGroup({
        amount: new FormControl('',[Validators.required,]),
        from_business: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(100)]),
        to_business: new FormControl('',[Validators.required, Validators.minLength(2), Validators.maxLength(100)]),
    });

    constructor(
        private modalService: BsModalService,
        public bsModalRef: BsModalRef,
        private organisationWebService: OrganisationWebService,
    ) {
    }

    ngOnInit(){
    }



    public async onTransfer(){
        if(!this.transferForm.valid){
            return;
        }

        await this.organisationWebService.transferFunds(this.transferForm.getRawValue())
        this.bsModalRef.hide();
    }    
}
