import { Component } from '@angular/core';
import { CompanyModel } from '@app/models/company-model';
import { BsModalRef } from 'ngx-bootstrap';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CompanyWebService } from '@app/providers/web.service';
import { BusinessGroup } from '@app/models/business-group';
import { BusinessSubGroup } from '@app/models/business-subgroup';
import { Utils } from '@app/utils/utils';
import { ValidatorHelpers } from '@app/utils/validators';
import { AppService } from '@app/modules/app/app.service';

@Component(
    {
        selector: 'create-subgroup-modal',
        templateUrl: './create-subgroup-modal.component.html'
    }
)
export class CreateSubGroupModalComponent {
    public business: CompanyModel;
    public groups: Array<BusinessGroup> = [];
    public addGroupForm: FormGroup = new FormGroup({
        group_name: new FormControl('', [Validators.required, ValidatorHelpers.validateNotWhitespace]),
        group: new FormControl('', [Validators.required, ValidatorHelpers.validateNotWhitespace]),
        // location: new FormControl('', [Validators.required]),
    });

    public editMode: boolean = false;
    public group: BusinessGroup;
    public subGroup: BusinessSubGroup;

    constructor(public modalRef: BsModalRef, public companyWebService: CompanyWebService, private appService: AppService) {
    }

    ngOnInit() {
        if (this.editMode) {
            this.addGroupForm.get("group_name").patchValue(this.subGroup.name)
            this.addGroupForm.get("group").patchValue(this.group.id)
        }
    }

    public async addSubGroup() {
        this.appService.setAppLoading(true);
        const subGrpVal = this.addGroupForm.get("group_name").value
        const grpVal = this.addGroupForm.get("group").value
        if (!this.addGroupForm.valid) {
            this.appService.setAppLoading(false);
            Utils.validateAllFormFields(this.addGroupForm);
            return;
        } 
        this.addGroupForm.reset();
        let params = {
            name: subGrpVal
        }
        if (this.editMode) {
            await this.companyWebService.updateBusinessSubGroup(this.business.company_id, this.group.id, this.subGroup.id, params)
        } else {
            await this.companyWebService.createBusinessSubGroup(this.business.company_id, grpVal, params);
        }
        this.appService.setAppLoading(false);
        this.modalRef.hide();
    }
}
