import { Component, Input, forwardRef, EventEmitter, Output, ViewChild, ElementRef, Renderer } from '@angular/core';
import { ValueAccessorBase } from '@app/utils/ValueAccessorBase';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Utils } from '@app/utils/utils';
import { AppService } from '@app/modules/app/app.service';
import { GlobalConstants } from '@app/utils/Constants';

@Component(
    {
        selector: 'address-autocomplete',
        templateUrl: './address-autocomplete.component.html',
        providers: [
            {
                provide: NG_VALUE_ACCESSOR,
                useExisting: forwardRef(() => AddressAutocomplete),
                multi: true
            }
        ]
    }
)

export class AddressAutocomplete extends ValueAccessorBase<string>{
    public autoComplete: { service: google.maps.places.AutocompleteService };
    public locations: Array<string> = [];
    @Input() addresses: boolean = false;
    @Input() disabled: boolean = true;
    @Input() inputId: string = "";

    constructor(private appService: AppService, private elementRef: ElementRef) {
        super()
    }

    ngOnInit() {
        this.appService.getAutocomplete().then((res) => {
            this.autoComplete = res;
            this.disabled = false;
        })
    }

    setDisabledState(isDisabled: boolean): void {
        setTimeout(() => {
            this.disabled = isDisabled;
        });
    }

    public search(searchTerm) {
        if (this.inputId==GlobalConstants.AutocompleteItem.City) {
            this.appService.getCity(searchTerm["query"], 'UK').then((res) => {
                this.locations = res;
            })
        } else if (this.inputId==GlobalConstants.AutocompleteItem.Address) {
            this.appService.getAddress(searchTerm["query"], 'UK').then((res) => {
                this.locations = res;
            })
        } else if (this.inputId==GlobalConstants.AutocompleteItem.PostCode) {
            this.appService.getPostCode(searchTerm["query"], 'UK').then((res) => {
                this.locations = res;
            })
        }
    }

    public onSelect(value) {
        this.value = value;
    }

    public onClear() {
        this.value = null;
    }

}