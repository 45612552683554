import { Component } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { AppService } from '../app.service';
import { CharityWebService } from '@app/providers/web.service';
import { CharityModel } from '@app/models/charity-model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'link-stripe',
  templateUrl: './link-stripe.component.html'
})
export class LinkStripeComponent {

  public routeSub: Subscription
  public charityId: number;
  public charity: CharityModel;
  public authorizationCode: string;

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private appService: AppService, private charityWebService: CharityWebService) {
  }
  ngOnInit() {
    // this.routeSub = this.activatedRoute.queryParams.subscribe(async (params) => {
    //   this.charityId = Number(localStorage.getItem("stripe_link_charity_id"))
    //   localStorage.removeItem("stripe_link_charity_id");
    //   if (!this.charityId || !(this.charityId > -1)) {
    //     this.appService.showCustomError("Failed to get charity id", "We cannot link your stripe account. Failed to get the charity account you are trying to link.");
    //     this.router.navigateByUrl("");
    //     return;
    //   }

    //   let charityRes = await this.charityWebService.getCharity(String(this.charityId));
    //   this.charity = new CharityModel().fromJSON(charityRes);

    //   if (!this.charity || !(this.charityId > -1)) {
    //     this.appService.showCustomError("Failed to get charity!", "We cannot link your stripe account. Failed to get the charity account you are trying to link.");
    //     this.router.navigateByUrl("");
    //     return;
    //   }

    //   if (params["error"]) {
    //     this.appService.showCustomError("Failed to link stripe account!", "The request was denied!")
    //     this.router.navigateByUrl("");
    //     return;
    //   }

    //   if (params["code"]) {
    //     let connectParams = {
    //       "charity_id": this.charity.id,
    //       "code": params["code"]
    //     }
    //     try {
    //       let connectedCharityRes = await this.charityWebService.connectStripe(connectParams);
    //       let connectedCharity = new CharityModel().fromJSON(connectedCharityRes);
    //       if (connectedCharity && connectedCharity.id > -1) {
    //         this.appService.showSuccess("Stripe linked successfully!","You have successfully linked your stripe account!")
    //         this.router.navigateByUrl(`/charity/${connectedCharity.id}/profile/funds`);
    //         return;
    //       }
    //     } catch (err) {
    //       this.appService.showCustomError("Stripe connect failed!", "Failed to connect your stripe account!");
    //       this.router.navigateByUrl("");
    //       return;
    //     }
    //   }
    // });
  }

  ngOnDestroy() {
    if(this.routeSub) this.routeSub.unsubscribe();
  }

}
