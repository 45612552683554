export class CompanySubscription {
    public amount: number = 0;
    public quantity: number = 0;
    public interval: string = "";
    public name: string = "";
    public plan: number = 1;
    public company_employee_annual_fee = 10;
    public company_employee_monthly_fee = 1;
  
    public enterprise_employee_annual_fee = 1;
    public enterprise_employee_monthly_fee = 0.1;

    public fromJSON(json: any) {
      this.quantity = json["quantity"] || this.quantity;
        let plan = json['plan'];
        if (plan) {
          this.name = plan["nickname"] || this.name;
          this.plan = this.getPlan(this.name) || this.plan;
          this.interval = plan["interval"] || this.interval;
        }
        this.amount = this.getFee(this.quantity);
        return this;
    }

    private getPlan(name: string): number {
        if (name.includes('Monthly')) {
            return 1;
        } else if (name.includes('Annual')) {
            return 2;
        }
        return null;
    }


    private getFee(number_employees: number) {
        if (number_employees <= 2000) {
          switch (this.plan) {
            case 1: // Monthly
              return this.company_employee_monthly_fee * number_employees;
            case 2: // Annual
              return this.company_employee_annual_fee * number_employees;
          }
        } else {
          switch (this.plan) {
            case 1: // Monthly
              return this.company_employee_monthly_fee * 2000 + ((number_employees - 2000) * this.enterprise_employee_monthly_fee)
            case 2: // Annual
              return this.company_employee_annual_fee * 2000 + ((number_employees - 2000) * this.enterprise_employee_annual_fee)
          }
        }
      }
}