import { FormGroup, FormControl, FormArray } from '@angular/forms'

export class Utils {

    private static uniqCounter: number = -1;
    public static getUniqCounter(): number {
        return this.uniqCounter++;
    }

    public static getToken(): string {
        return localStorage.getItem('token');
    }

    public static setToken(token: string) {
        localStorage.setItem('token', token);
    }

    public static deleteToken() {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
    }

    public static validateAllFormFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.updateValueAndValidity({onlySelf: false, emitEvent: true});
                control.markAsDirty({ onlySelf: false });
            } else if (control instanceof FormGroup) {
                this.validateAllFormFields(control);
            } else if (control instanceof FormArray) {
                for (let innerControl of control.controls) {
                    if (innerControl instanceof FormControl) {
                        innerControl.updateValueAndValidity({ onlySelf: false, emitEvent: true });
                        innerControl.markAsDirty({ onlySelf: false});
                    } else if (innerControl instanceof FormGroup) {
                        this.validateAllFormFields(innerControl);
                    }
                }
            }
        });
    }

    public static formatAsCurrency(amount: number, ): string {
        var formatter = new Intl.NumberFormat('en-GB', {
            style: 'currency',
            currency: 'GBP',
            maximumFractionDigits: 2,
        });
        return formatter.format(amount).replace(/\.0+$/, "");
    }

    public static checkForMobile() {
        if ((navigator.userAgent.match(/Android/i) && navigator.userAgent.match(/Mobile/i))
            || navigator.userAgent.match(/iPhone/i)
            || navigator.userAgent.match(/iPod/i)) {
            return true;
        } else {
            return false;
        }
    }

    public static checkForIpad() {
        if (navigator.userAgent.match(/iPad/i)) {
            return true;
        } else {
            return false;
        }
    }

    public static checkForIOSDevice() {
        if (navigator.userAgent.match(/iPad||iPod||iPhone/i)) {
            return true;
        } else {
            return false;
        }
    }
}