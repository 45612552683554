import { GlobalConstants } from '@app/utils/Constants';
import { AppService } from '@app/modules/app/app.service';

export class TypesOfGivingModel {

    public id: number = null;
    public amount: number = 0;
    public type: number = null;
    public project_id: number = null;
    public min_amount: number = 0;
    public max_amount: number = 0;
    public end_date: Date = null;
    public hours: number = 0;
    public min_volunteers: number = 0;
    public max_volunteers: number = 0;
    public date_needed: Date = null;
    public volunteer_type_of_skill: string = null;
    public description: string = null;
    public gift_type: number = null;
    public current_amount: number  = 0;
    public can_exceed_max: boolean = false;
    public gift_item_id: number = null;
    public cid: number = 0;
    public gift_type_name: string = null;

    // optional, comes from getProjectVolunteered() call
    public volunteered: boolean = false;


    constructor(private appService?: AppService) {
    }

    public fromJSON(json: any) {
        this.id = Number(json["id"]) || this.id;
        this.type = Number(json["type"]) || this.type;
        this.project_id = json["project_id"] || this.project_id;
        this.min_amount = json["min_amount"] || this.min_amount;
        this.max_amount = json["max_amount"] || this.max_amount;
        this.amount = json["amount"] || this.amount;
        this.end_date = new Date(json["end_date"]) || this.end_date;
        this.hours = json["hours"] || this.hours;
        this.gift_type = json["gift_type"] || this.gift_type;
        this.min_volunteers = json["min_volunteers"] || this.min_volunteers;
        this.max_volunteers = json["max_volunteers"] || this.max_volunteers;
        this.date_needed = new Date(json["date_needed"]) || this.date_needed;
        this.volunteer_type_of_skill = json["volunteer_type_of_skill"] || this.volunteer_type_of_skill;
        this.description = json["description"] || this.description;
        this.current_amount = json["current_amount"] || this.current_amount;
        this.gift_item_id = json["gift_item_id"] || this.gift_item_id;
        this.cid = json["cid"] || this.cid;
        if (!this.gift_type) {
            this.gift_type = this.cid;
        }
        
        if (this.cid && this.appService) {
            let category = this.appService.giftCategories.find(g => g.id === this.cid);
            if(category){
                this.gift_type_name = category.name || this.gift_type_name;
            }
        }

        this.volunteered = !!json["volunteered"] || this.volunteered;

        if (this.type == GlobalConstants.TypeOfGivingMap.Funds) {
            this.current_amount /= 100;
            this.min_amount /= 100 ;
            this.max_amount /= 100;
            this.amount /= 100;
        }

        this.can_exceed_max = !!json["can_exceed_max"]
        return this;
    }

    public toJSON() {
        return {
            id: this.id,
            type: this.type,
            project_id: this.project_id,
            min_amount: this.min_amount,
            max_amount: this.max_amount,
            end_date: this.end_date,
            hours: this.hours,
            gift_type: this.gift_type,
            min_volunteers: this.min_volunteers,
            description: this.description,
            max_volunteers: this.max_volunteers,
            date_needed: this.date_needed,
            volunteer_type_of_skill: this.volunteer_type_of_skill,
            can_exceed_max: this.can_exceed_max,
            gift_item_id: this.gift_item_id
        }

    }
}