import { GlobalConstants } from '@app/utils/Constants';
import { TypesOfGivingModel } from '@models/types-of-giving';
import { FormGroup } from '@angular/forms';
import { GivingUtils } from '@utils/giving-utils'
import { Utils } from '@app/utils/utils';
import { environment } from '@app/environments/environment';
import { DocumentModel } from '@app/models/document-model';
import { GalleryModel } from '@app/models/gallery-model';
import { AppService } from '@app/modules/app/app.service';

export class ProjectModel {

    public id: string = null;
    public name: string = null;
    public creator_id: string = null;
    public goal: string = null;
    public start_date: Date = null;
    public end_date: Date = null;
    public status: number = null;
    public project_type: number = null;
    public project_status_name: string = null;
    public description: string = null;
    public city: string = null;
    public category_id: number = null;
    public category_name: string = null;
    public project_category_name: string = null;
    public admin_id: number = null;
    public charity_id: number = null;
    public is_deleted: number = null;
    public project_dates: string = null;
    public logo: string = null;
    public imageSmall: string = null; // 80x80
    public imageMedium: string = null; // 720x400
    public imageBig: string = null; // 1280x720
    public documents: Array<DocumentModel> = [];
    public gallery: Array<GalleryModel> = [];
    public charity_status: number = 1;
    public charity_status_name: string = 'New';
    public business_id: number = -1;
    public can_complete: boolean = false;
    public exceed_agreement: boolean = false;

    public project_funds: number = 10000;
    public project_funds_currency: string = Utils.formatAsCurrency(this.project_funds);

    public givings: Array<FormGroup> = [];
    public givingsFlat: Array<TypesOfGivingModel> = [];
    public type_of_giving_name: string = '';
    public addedGivings: Array<number> = [];
    public addedGivingsNames: Array<string> = [];

    public canCompleteProject: boolean = true;
    public weight: number = 0;
    public last_activity: Date

    constructor(private appService?: AppService) {
    }
    
    public fromJSON(json: any) {
        this.id = json["id"] || this.id;
        this.name = json["name"] || this.name;
        this.creator_id = json["creator_id"] || this.creator_id;
        this.goal = json["goal"] || this.goal;
        this.start_date = new Date(json["start_date"]) || this.start_date;
        this.end_date = new Date(json["end_date"]) || this.end_date;
        this.project_dates = (this.start_date && this.end_date) ? "START: <span class='semi'>" + this.start_date.toDateString() + "</span><br/> END: <span class='semi'>" + this.end_date.toDateString() : json["project_dates"] + '</span>';
        if (json["status"] != null && json["status"] != 'undefined') this.status = json["status"];
        this.project_status_name = GlobalConstants.PROJECT_STATUSES[this.status] || this.project_status_name;
        this.project_type = json["project_type"] || this.project_type;
        this.description = json["description"] || this.description;
        this.exceed_agreement = json["exceed_agreement"] || this.exceed_agreement;
        this.city = json["city"] || this.city;
        this.category_id = json["category_id"] || this.category_id;
        this.category_name = GlobalConstants.CHARITY_TYPES_MAP[this.category_id]
        this.admin_id = json["admin_id"] || this.admin_id;
        this.charity_id = json["charity_id"] || this.charity_id;
        this.is_deleted = json["is_deleted"] || this.is_deleted;
        this.logo = json["logo"] || this.logo;
        this.can_complete  = json["can_complete"] || this.can_complete;
        this.business_id = json["business_id"] || this.business_id;
        this.charity_status = json["charity_status"] || this.charity_status;
        this.charity_status_name = GlobalConstants.PROJECT_CHARITY_STATUS_MAP[this.charity_status];
        this.documents = (json["documents"]) ? json["documents"].map(document => new DocumentModel().fromJSON(this.id, document)) : this.documents;
        this.gallery = (json["galery"]) ? json["galery"].map(image => new GalleryModel().fromJSON(this.id, image)) : this.gallery;
        this.weight = json["weight"] || this.weight;
        this.last_activity = new Date(json["last_activity"]) || this.last_activity;
        if (this.logo) {
            this.imageSmall = `url('${environment.imageUrl}${this.logo}_small')`;
            this.imageMedium = `url('${environment.imageUrl}${this.logo}_medium')`;
            this.imageBig = `url('${environment.imageUrl}${this.logo}_big')`;
        }
        if (json["givings"]) {
            this.addedGivings = [];
            this.addedGivingsNames = [];
            this.givingsFlat = [];
            this.type_of_giving_name = '';
            for (let giving of json["givings"]) {
                let tmpGiving = new TypesOfGivingModel(this.appService).fromJSON(giving)
                this.givingsFlat.push(tmpGiving)
                if (this.addedGivings.indexOf(tmpGiving.type) == -1) {
                    this.addedGivings.push(tmpGiving.type);
                    this.addedGivingsNames.push(GlobalConstants.TYPES_OF_GIVING[tmpGiving.type]);
                    if (this.type_of_giving_name === '') {
                        this.type_of_giving_name = GlobalConstants.TYPES_OF_GIVING[tmpGiving.type];
                    } else {
                        this.type_of_giving_name += ', ' + GlobalConstants.TYPES_OF_GIVING[tmpGiving.type];
                    } 
                }
            }
        }
        this.calcCanComplete();
        this.unflattenItems();
        return this;
    }

    public toJSON() {
        return {
            id: this.id,
            name: this.name,
            start_date: this.start_date,
            end_date: this.end_date,
            status: this.status,
            project_type: this.project_type,
            description: this.description,
            city: this.city,
            category_id: this.category_id,
            charity_id: this.charity_id,
            typeOfGivingItems: GivingUtils.flattenItems(this.givings),
            exceed_agreement: this.exceed_agreement
        };
    }



    private unflattenItems() {
        this.givings = [];
        for (let item of this.givingsFlat) {
            let added = false;
            if (this.givings.length != 0) {
                for (let obj of this.givings) {
                    if (item.type == obj.value.type) {
                        GivingUtils.addAdditionalItem(obj, item, this.end_date);
                        added = true;
                    }
                }
            }
            if (!added) {
                let newItem = GivingUtils.getTypeOfGivingGroup(item.type, this.end_date)
                this.givings.push(newItem);
                newItem.patchValue({ type: item.type, items: [item] });
            }
        }
    }

    private calcCanComplete() {
        this.canCompleteProject = true;
        for (let giving of this.givingsFlat) {
            if (giving.current_amount < giving.min_amount) {
                this.canCompleteProject = false;
                return;
            }

            let volunteers = giving.current_amount / giving.hours;
            if (volunteers < giving.min_volunteers) {
                this.canCompleteProject = false;
                return;
            }
        }
    }
}
