import { Component } from '@angular/core';
import { CompanyModel } from '@app/models/company-model';
import { BsModalRef } from 'ngx-bootstrap';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CompanyWebService } from '@app/providers/web.service';
import { BusinessGroup } from '@app/models/business-group';
import { BaseUser } from '@app/models/base-user';

@Component(
    {
        selector: 'add-user-subgroup-modal',
        templateUrl: './add-user-subgroup-modal.component.html'
    }
)
export class AddUserSubGroupModalComponent {
    public business: CompanyModel;
    public groups: Array<BusinessGroup> = [];
    public currentSubgroupId: number = -1;
    public currentGroupId: number = -1;

    public selectedIds: Array<number> = [];
    public allSelected: boolean = false;

    public users: Array<BaseUser> = [];
    public subGroupUsers: Array<BaseUser> = [];

    public cols: any[] = [
        { field: 'full_name', header: 'NAME' },
        { field: 'email', header: 'EMAIL' }
    ];

    constructor(public modalRef: BsModalRef, public companyWebService: CompanyWebService) {
        
    }

    ngOnInit() {
        this.loadUsers();
    }

    public async loadUsers() {
        let currentUsersMap = {};
        for(let u of this.subGroupUsers) {
            currentUsersMap[u.id] = u;
        }
        this.users = [];
        let usersRes = await this.companyWebService.getAllBusinessEmployees(this.business.company_id);
        for(let u of usersRes) {
            let user = new BaseUser().fromJSON(u)
            if(currentUsersMap[u.id]) continue;
            this.users.push(user);
        }
    }

    public onCheckAll({ target }, rows) {
        rows.forEach(row => {
            if (row) {
                row.isChecked = target.checked
            }
        });
    }

    public async addUserToGroup() {

        this.modalRef.hide();
    }

    public async onAddNewUsers() {
        this.selectedIds = [];
        this.selectedIds = this.users.filter((row: any) => row.isChecked).map(user => user.id);
        if (this.selectedIds.length < 1) return;
        let params = {
            'users': this.selectedIds
        }
        await this.companyWebService.addUserToBusinessSubGroup(this.business.company_id, this.currentGroupId, this.currentSubgroupId, params)
        this.modalRef.hide();
    }
}
