import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { CharityModel } from '@app/models/charity-model';
import { FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { BaseUser } from '@app/models/base-user';
import { Subject, Subscription } from 'rxjs';
import { UserType, MessageStatus } from '@app/utils/Constants';
import { MessageWebService, CharityWebService } from '@app/providers/web.service';
import { AppService } from '@app/modules/app/app.service';
import { ProjectModel } from '@app/models/project-model';

@Component({
  selector: 'message-modal',
  templateUrl: './message-modal.component.html'
})
export class MessageModalComponent implements OnInit {

    @ViewChild('selectAdmins') public tempRefselectAdmins: TemplateRef<any>;
    @ViewChild('selectUsers') public tempRefselectUsers: TemplateRef<any>;
    @ViewChild('sendMessageTemplate') public templateRefSendMessage: TemplateRef<any>;

    public currentUser: BaseUser;
    public charity: CharityModel;
    public selectUserModal: BsModalRef;
    public selectAdminsModal: BsModalRef;
    public sendMessageModal: BsModalRef;
    public nameRequired = false;
    public isDisabled = true;
    public onComplete: Subject<boolean>;
    public childBsModalRef: BsModalRef;
    public senderId: number;
    public senderType: UserType;
    public check_vol = false;
    public check_gif = false;
    public donators: any = null ; // volunteersAndGifters,
    public selectUsersModal = false;
    
    public volunteers: any[] = [];
    public gifters: any[] = [];

    public receivers = [];
    public formSubs: Subscription;
    public donatorsFormSubmit = false;
    public project: ProjectModel;
    public projectAdminsArr: Array<any> = [];

    public adminsForm: FormGroup = new FormGroup({
      projectAdmins: new FormControl(false),
      charityAdmins: new FormControl(false),
    }, {
      validators: [this.oneChjeckBoxIsChecked]
    });

    public messageForm: FormGroup = new FormGroup({
      subject: new FormControl('',[Validators.required, Validators.pattern("[0-9a-zA-Z]{1}.*$")]),
      content: new FormControl('',[Validators.required, Validators.pattern("[0-9a-zA-Z]{1}.*$")]),
    })

    public donatorsForm = new FormGroup({
      volunteersFormArr: new FormArray([]),
      giftersFormArr: new FormArray([]),
    });

    get volunteersFormArr() {
      return this.donatorsForm.get('volunteersFormArr') as FormArray;
    }

    get giftersFormArr() {
      return this.donatorsForm.get('giftersFormArr') as FormArray;
    }

    constructor(
      public bsModalRef: BsModalRef,
      private modalService: BsModalService,
      private messageWebService: MessageWebService,
      private appService: AppService,
      private charityWebService: CharityWebService,
    ) {}

    ngOnInit() {
      this.currentUser = this.appService.getUser();
      this.initForms();
    }

    ngOnDestroy() {
      this.formSubs.unsubscribe();
    }

    public async initForms() {
      if (this.selectUsersModal) {
        this.openSelectUsersModal();
        
      } else{
        this.projectAdminsArr = await this.charityWebService.getCharityProjectAdmins(this.project.id)
        
        if (this.projectAdminsArr.length > 0 || (this.project.business_id && this.project.business_id > -1)){
          this.openSelectAdminsModal();
        } else {
          this.openSendMessageModal();
        }
      }

      if (this.donators) {
        this.separateDonators(this.donators);
      }
      this.onFormChanges();
    }

    public onFormChanges(): void {
      this.formSubs = this.donatorsForm.valueChanges.subscribe(() => {
        this.validateSeletUsersForm()
      });
    }

    public separateDonators(donators) {
      donators.forEach(element => {
        const d = {
          userId: element.DonatorID,
          userType: element.DonatorType,
          donationType: element.DonationType,
          userName: element.DonatorName,
        };
        if (element.DonationType === 5) {
          this.gifters.push(d);
          this.giftersFormArr.push(new FormControl(false,));
        } else {
          this.volunteers.push(d);
          this.volunteersFormArr.push(new FormControl(false, ));
        }
      });
    }

    public checkAllVolunteers() {
      if (this.volunteersFormArr.controls.length>0 && !this.check_vol) {
        this.volunteersFormArr.controls.map(el => {el.setValue(true);})
      } else {
        this.volunteersFormArr.controls.map(el => {el.setValue(false);})
      }
    }

    public checkAllGifters() {
      if (this.giftersFormArr.controls.length>0 && !this.check_gif) {
        this.giftersFormArr.controls.map(el => {el.setValue(true);})
      } else {
        this.giftersFormArr.controls.map(el => {el.setValue(false);})
      }
    }

    public oneChjeckBoxIsChecked(formGroup: FormGroup): {[key: string]: any} | null {
      if (formGroup.get('projectAdmins').value === false && formGroup.get('charityAdmins').value === false ) {
        return { 'projectAdmins': true, 'charityAdmins': true};
      }
      return null;
    }

    public validateSeletUsersForm() {
      let count = 0
      this.volunteersFormArr.controls.forEach(el=>{
        if (el.value===true) { 
          count++;
        }
      })
      this.giftersFormArr.controls.forEach(el=>{
        if (el.value===true) { 
          count++;
        }
      })

      if (count===0) {
        // this.donatorsForm.setErrors({ 'invalid': true });
        
        this.volunteersFormArr.controls.forEach(el=>{
          el.setErrors({'invalid': true})
        })
        this.giftersFormArr.controls.forEach(el=>{
          el.setErrors({'invalid': true})
        })
        this.donatorsFormSubmit=false;
      }else{
        this.donatorsFormSubmit=true;
      }

    }

    public openSelectUsersModal() {
      this.selectUserModal = this.modalService.show(this.tempRefselectUsers, {class: 'modal-lg', backdrop: 'static', });
    }

    public openSelectAdminsModal() {
      this.selectAdminsModal = this.modalService.show(this.tempRefselectAdmins, { backdrop: 'static'});
    }

    public openSendMessageModal() {
      this.sendMessageModal = this.modalService.show(this.templateRefSendMessage);
    }

    public async sendMessage() {
      let  receivers = [];

      if ((!this.selectUsersModal && this.projectAdminsArr.length == 0)|| (!this.selectUsersModal && this.adminsForm.get('charityAdmins').value)) {
        receivers.push(
          {
            receiver_id: this.project.charity_id,
            receiver_type: UserType.Charity
          }
        );
      }

      if (!this.selectUsersModal && this.adminsForm.get('projectAdmins').value) {
        this.projectAdminsArr.forEach(el =>{
          receivers.push(
            {
              receiver_id: el.id,
              receiver_type: UserType.Individual
            }
          )
        });
      }

      if(this.project.business_id && this.project.business_id > -1 ) {
        receivers.push(
          {
            receiver_id: this.project.business_id,
            receiver_type: UserType.Business
          }
        )
      }

      if (this.selectUsersModal){
        this.senderId = this.project.charity_id
        this.senderType = UserType.Charity

        for (var i = 0; i < this.giftersFormArr.controls.length; i++) {
            if( this.giftersFormArr.controls[i].value ) {
              receivers.push({
                receiver_id: this.gifters[i].userId,
                receiver_type: this.gifters[i].userType
              })
            }
        }
        for (var i = 0; i < this.volunteersFormArr.controls.length; i++) {
          if( this.volunteersFormArr.controls[i].value ) {
            receivers.push({
              receiver_id: this.volunteers[i].userId,
              receiver_type: this.volunteers[i].userType
            })
          }
        }
      }

      const params = {
        sender_id: this.senderId,
        sender_type: this.senderType,
        receivers: receivers,
        subject: this.messageForm.get('subject').value,
        content: this.messageForm.get('content').value,
        project_id: this.project.id,
      };
      
      await this.messageWebService.sendMessage(params);

    }

    public async saveAsDraft() {
      let  receivers = [];

      if (!this.selectUsersModal && this.adminsForm.get('projectAdmins').value) {
        this.projectAdminsArr.forEach(el =>{
          receivers.push(
            {
              receiver_id: el.id,
              receiver_type: UserType.Individual
            }
          )
        });
      }

      if (!this.selectUsersModal && this.projectAdminsArr.length == 0) {
        receivers.push(
          {
            receiver_id: this.project.charity_id,
            receiver_type: UserType.Charity
          }
        );
      }

      if (this.selectUsersModal){
        this.senderId = this.project.charity_id
        this.senderType = UserType.Charity

        for (var i = 0; i < this.giftersFormArr.controls.length; i++) {
            if( this.giftersFormArr.controls[i] ) {
              receivers.push({
                receiver_id: this.gifters[i].userId,
                receiver_type: this.gifters[i].userType
              })
            }
        }
        for (var i = 0; i < this.volunteersFormArr.controls.length; i++) {
          if( this.volunteersFormArr.controls[i] ) {
            receivers.push({
              receiver_id: this.volunteers[i].userId,
              receiver_type: this.volunteers[i].userType
            })
          }
        }
      }

      const params = {
        sender_id: this.senderId,
        sender_type: this.senderType,
        receivers: receivers,
        subject: this.messageForm.get('subject').value,
        content: this.messageForm.get('content').value,
        project_id: this.project.id,
        is_draft: true
      };
      await this.messageWebService.saveDraftMessage(params);

    }
}
