import { GroupModel } from './group-model';
import { environment } from '@app/environments/environment';

export class GroupCardModel extends GroupModel {

    public group_name: string = null;
    public group_logo: string = null;
    public groupLogoSmall: string = null;

    public fromJSON(json: any) {
        super.fromJSON(json)
        this.group_name = json["charity_name"] || this.group_name;
        this.group_logo = json["charity_logo"] || this.group_logo;
        if(this.group_logo) {
            this.groupLogoSmall = `url('${environment.imageUrl}${this.group_logo}_small')`;
        }
        return this;
    }
}