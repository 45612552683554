import { BaseModel } from './base-model';
import { CharityMember } from './charity-member';
import { GlobalConstants } from '@utils/Constants';
import { environment } from '@app/environments/environment';
import { CompanySubscription } from './company-subscription';

export class CompanyModel extends BaseModel {

    public company_id: number = -1;
    public users: Array<CharityMember> = [];
    public company_name: string = null;
    public company_registration_number: string = null;
    public company_city: string = null;
    public company_address: string = null;
    public company_post_code: string = null;
    public company_email: string = null;
    public company_website: string = null;
    public company_description: string = null;
    public formatted_address: string = null;
    public company_phone: string = null;
    public company_discount_code: string = null;
    public company_fee_amount: number = null;
    public company_fee_type: number = null;
    public company_fee_type_name: string = null;
    public wallet_amount: number = 0;
    public credits: number = 0;
    public is_locked: boolean = false;
    public avatar: string = null;
    public imageSmall: string = null; //80x80
    public imageMedium: string = null; //150x150
    public imageBig: string = null; //320x320
    public is_subscribed: boolean = false;
    public is_auto_approve: boolean = false;
    public company_parent_company = null;
    public company_parent_company_name = null;
    public company_main_company_id = null;

    // Subscription number employees.
    public subscription_number_employees: number = 0;
    public subscription: CompanySubscription = null;
    public unread_messages: number = 0;

    public fromJSON(json: any) {
        this.company_id = json["company_id"];
        this.users = json["users"] || this.users;
        this.company_name = json["company_name"] || this.company_name;
        this.company_registration_number = json["company_registration_number"] || this.company_registration_number;
        this.company_city = json["company_city"] || this.company_city;
        this.company_address = json["company_address"] || this.company_address;
        this.company_post_code = json["company_post_code"] || this.company_post_code;
        this.company_email = json["company_email"] || this.company_email;
        this.company_website = json["company_website"] || this.company_website;
        this.company_description = json["company_description"] || this.company_description;
        this.company_phone = json["company_phone"] || this.company_phone;
        this.company_discount_code = json["company_discount_code"] || this.company_discount_code;
        this.company_fee_amount = json["company_fee_amount"] || this.company_fee_amount;
        this.company_fee_type = json["company_fee_type"] || this.company_fee_type;
        this.company_fee_type_name = GlobalConstants.FEE_TYPES[this.company_fee_type] || this.company_fee_type_name;
        if (isNaN(Number(json["wallet_amount"]))) {
            this.wallet_amount = this.wallet_amount;
        } else {
            this.wallet_amount = (json["wallet_amount"] / 100);
        }
        this.credits = json["credits"];
        this.is_locked = json["is_locked"] || this.is_locked;
        this.avatar = json["logo"] || this.avatar;
        this.is_subscribed = (json["is_subscribed"] == 'true') || this.is_subscribed;
        this.subscription = json["subscription"] || this.subscription;
        this.is_auto_approve = json["is_auto_approve"];
        if (json["logo"]) {
            this.imageSmall = `url('${environment.imageUrl}${this.avatar}_small')`;
            this.imageMedium = `url('${environment.imageUrl}${this.avatar}_medium')`;
            this.imageBig = `url('${environment.imageUrl}${this.avatar}_big')`;
        } else {
            this.imageSmall = `url(/assets/img/user-placeholder.svg)`;
            this.imageMedium = `url(/assets/img/user-placeholder.svg)`;
            this.imageBig = `url(/assets/img/user-placeholder.svg)`;
        }

        this.formatted_address = `${this.company_address}, ${this.company_city}`;
        this.unread_messages = json["unread_messages"] || this.unread_messages;
        this.company_parent_company = json["parent_business"] || this.company_parent_company;
        this.company_parent_company_name = json["parent_business_name"] || this.company_parent_company_name;
        this.company_main_company_id = json["main_business_id"] || this.company_main_company_id;
        return this;
    }

    public toJSON() {
        let jsonUsers = [];
        for (let user of this.users) {
            jsonUsers.push(user.toJSON());
        }

        return {
            company_id: this.company_id,
            user: jsonUsers,
            company_name: this.company_name,
            registration_company_number: this.company_registration_number,
            company_city: this.company_city,
            company_address: this.company_address,
            company_email: this.company_email,
            company_description: this.company_description,
            company_website: this.company_website,
            company_phone: this.company_phone,
            company_discount_code: this.company_discount_code,
            company_fee_type: this.company_fee_type,
            company_fee_type_name: this.company_fee_type_name,
            is_auto_approve: this.is_auto_approve,
            company_post_code: this.company_post_code
        }
    }
}