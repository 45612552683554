import { AppService } from '@appmodule/app.service';
import { BaseUser } from '@models/base-user';
import { CharityModel } from '@models/charity-model';
import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { CharityAdminPrivs } from '@utils/Enumerations';

@Injectable()
export class CharityService {

    public charitySource: Subject<CharityModel> = new Subject();
    public reloadSupporters: Subject<void> = new Subject();
    public charity: CharityModel = new CharityModel();
    public charityUser: BaseUser = null;
    public userPrivs: CharityAdminPrivs = 99;

    constructor(private appService: AppService) {}

    public setCharity(val: CharityModel) {
        this.charity = val;
        this.charityUser = this.appService.getUser();
        let charityPrivs = this.appService.getUserCharities().filter((item) => {
            return item.charity_id == this.charity.id;
        });

        if (charityPrivs.length > 0) {
            this.userPrivs = charityPrivs[0].permissions;
        } else {
            this.userPrivs = -1;
        }
        this.charitySource.next(this.charity);
    }

    public getCharity(): CharityModel {
        return this.charity;
    }

    public clearService() {
        this.charity = new CharityModel();
        this.charityUser = null;
        this.userPrivs = 99;
    }
    
}