import { GlobalConstants } from "@app/utils/Constants";
import { environment } from '@app/environments/environment';

export class GroupModel {

    public id: number = null;
    public name: string = null;
    public date_created: string = null;
    public description: string = null;
    public email: string = null;
    public website: string = null;
    public group_type: number = null;
    public group_type_name: string = null;
    public is_public: boolean = false;
    public is_public_name: string = null;
    public logo: string = null;
    public imageSmall: string = null; // 80x80
    public imageMedium: string = null; // 280x280
    public imageBig: string = null;
    public permission: number = null;
    public pin: string = null;
  
    constructor() {}

    public fromJSON(json: any) {
        this.id = json.group_id|| this.id;
        this.name = json.name || this.name;
        this.date_created = json.date_created || this.date_created;
        this.description = json.description || this.description;
        this.email = json.email || this.email;
        this.group_type = json.group_type || this.group_type
        this.group_type_name = GlobalConstants.GROUP_TYPE_MAP[this.group_type] || this.group_type_name
        this.is_public = json.is_public || this.is_public
        this.is_public_name = this.is_public ? 'Visible' : 'Hidden';
        this.logo = json.logo || this.logo;
        this.website = json.website || this.website;
        this.permission = json.permission || this.permission;
        if (this.logo) {
            this.imageSmall = `url('${environment.imageUrl}${this.logo}_small')`;
            this.imageMedium = `url('${environment.imageUrl}${this.logo}_medium')`;
            this.imageBig = `url('${environment.imageUrl}${this.logo}_big')`;
        } else {
            this.imageSmall = `url(/assets/img/user-placeholder.svg)`;
            this.imageMedium = `url(/assets/img/user-placeholder.svg)`;
            this.imageBig = `url(/assets/img/user-placeholder.svg)`;
        }
        this.pin = json.pin || this.pin;
        return this;
    }

    public toJSON(){
        return {
            id:this.id,
            name:this.name,
            date_created:this.date_created,
            description:this.description,
            email: this.email,
            group_type: this.is_public,
            is_public: this.is_public,
            logo: this.logo,
            website: this.website
        };
    }
}