import { ValidatorFn, AbstractControl, ValidationErrors, FormGroup } from "@angular/forms";
import { notImplemented } from "@angular/core/src/render3/util";

export class ValidatorHelpers {
    public static validateEmail(controlName: string): ValidatorFn {
        return (control: AbstractControl): { [key: string]: boolean } | null => {
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!re.test(String(control.value).toLowerCase())) {
                let ret = {};
                ret[controlName] = true;
                return ret;
            }
            return null;
        }
    }

    public static validateNumberField(controlName: string): ValidatorFn {
        let re = /^\d+$/;
        return (control: AbstractControl): { [key: string]: boolean } | null => {
            if (!re.test(String(control.value).toLowerCase())) {
                let ret = {};
                ret[controlName] = true;
                return ret;
            }
            return null;
        }
    }

    public static validateNIN(controlName: string): ValidatorFn {
        let re = /[A-Za-z]{2}[0-9]{6}[A,B,C,D,a,b,c,d]{1}\b/;
        return (control: AbstractControl): { [key: string]: boolean } | null => {
            if (!re.test(String(control.value))) {
                let ret = {};
                ret[controlName] = true;
                return ret;
            }
            return null;
        }
    }

    public static validateRegCompanyNum(controlName: string): ValidatorFn {
        let re = /[A-Za-z]{2}[0-9]{6}|[0-9]{8}/;
        return (control: AbstractControl): { [key: string]: boolean } | null => {
            if (!re.test(String(control.value))) {
                let ret = {};
                ret[controlName] = true;
                return ret;
            }
            return null;
        }
    }

    public static compareFields(controlName: string, controlName2: string): ValidatorFn {
        return (control: AbstractControl): { [key: string]: boolean } | null => {
            if (control.get(controlName).value !== control.get(controlName2).value) {
                let ret = {};
                ret[controlName] = true;
                ret[controlName2] = true;
                return ret;
            }
            return null;
        }
    }

    public static conditionalRequired(required: any): ValidatorFn {
        return (control: AbstractControl): { [key: string]: boolean } | null => {
            if (required.required) {
                if (control.value == null || control.value == 'undefined' || (control.value === 0) || control.value === '') return { controlName: true };
                return null;
            }
            return null;
        }
    }

    public static validateNotWhitespace(control: AbstractControl): { [key: string]: any } | null {
        if (typeof control.value === 'string' && control.value != '' && control.value.trim() === '') {
            return { isWhitespace: true };
        }
        return null;
    }

    public static validateLargerThan(value: number): ValidatorFn {
        return (control: AbstractControl): { [key: string]: number } | null => {
            if (typeof control.value === 'number' && control.value <= value) {
                return { largerThan: value };
            }
            return null;
        }
    }

    public static notZero(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: number } | null => {
            if (control.value == 0 || control.value === 0) {
                return { notZero: control.value };
            }
            return null;
        }
    }

    public static requiredGivingType(givings: Array<FormGroup>, type: number): ValidatorFn {
        return (control: AbstractControl): { [key: string]: boolean } | null => {
            for (let giving of givings) {
                if (giving.value.type == type) {
                    if(control.value) return null;
                    return { control: true };
                }
            }
            return null;
        }
    }
}