/// <reference types="stripe-v3" />
import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms'
import { WalletWebService } from '@providers/web.service';
import { environment } from '@app/environments/environment';
import { Subscription } from 'rxjs'
import { BaseWebService } from '@app/providers/base-web.service';



@Component(
    {
        selector: 'credit-card',
        templateUrl: './credit-card.component.html'
    }
)
export class CreditCardComponent {
    private stripe: stripe.Stripe
    private elements: stripe.elements.Elements

    @ViewChild('cardElement')
    public cardEl: ElementRef
    public card: stripe.elements.Element
    public error: string = "";
    public createTokenSub: Subscription
    @Input()
    public createPaymentId: EventEmitter<void> = new EventEmitter();
    @Input()
    public subscription: boolean = false;
    @Input()
    public business_id: number = -1;
    @Output()
    public createPaymentIdFinish: EventEmitter<string> = new EventEmitter();
    @Output()
    public onError: EventEmitter<void> = new EventEmitter();

    constructor(private walletWebService: WalletWebService, private changeDetectorRef: ChangeDetectorRef) {
    }


    ngOnInit() {
        if (this.subscription) {
            this.stripe = Stripe(environment.stripeSubKey)
            this.elements = this.stripe.elements();
        } else {
            this.stripe = Stripe(environment.stripeKey)
            this.elements = this.stripe.elements();
        }

        let style = {
            base: {
                color: '#32325d',
                lineHeight: '16px',
                fontFamily: 'inherit',
                fontSmoothing: 'antialiased',
                fontSize: '14px',
                '::placeholder': {
                    color: '#aab7c4'
                }
            },
            invalid: {
                color: '#fa755a',
                iconColor: '#fa755a'
            }
        };
        // Create an instance of the card Element.
        this.card = this.elements.create('card', { style: style });
        this.card.mount(this.cardEl.nativeElement);
        this.card.on('change', this.cardInfoChanged.bind(this))
        this.createTokenSub = this.createPaymentId.subscribe(this.createTokenEvent.bind(this))
    }

    cardInfoChanged(event: any) {
        if (event.error) {
            this.error = event.error.message;
        } else {
            this.error = '';
        }
    }

    ngOnDestroy() {
        if (this.createTokenSub) this.createTokenSub.unsubscribe();
    }

    public async createTokenEvent() {
        let res = await this.stripe.createToken(this.card);
        if (res.error) {
            this.error = res.error.message;
            return;
        }/*
        let paymentMethodRes: stripe.PaymentMethodResponse = await this.stripe.createPaymentMethod(
            'card',
            this.card
        );

        if (paymentMethodRes.error) {
            this.error = paymentMethodRes.error.message;
            this.onError.emit();
            return;
        }*/
        this.createPaymentIdFinish.emit(res.token.id);
    }

    public async handleServerResponse(response, showModal: boolean) {
        if (response.status == 'success') {
            if (response.requires_action) {
                const stripeRes: stripe.PaymentIntentResponse = await this.stripe.handleCardPayment(
                    response.client_secret
                );
                if (!stripeRes.error) {
                    BaseWebService.successSubject.next({ summary: 'Subscription created', detail: 'Your subscription has been created. It may take some time for it to get processed. Please check back at a later point in time.' });
                } else {
                    this.error = stripeRes.error.message;
                    this.onError.emit();
                    return;
                }
            } else {
                if (showModal) {
                    BaseWebService.successSubject.next({ summary: 'Subscription created', detail: 'Your subscription has been created. It may take some time for it to get processed. Please check back at a later point in time.' });
                }
            }
        } else {
            if (response.next_action == 'redirect_to_url') {
                BaseWebService.errorSubject.next({ status: 400, message: 'This card is not supported!' });
            } else {
                BaseWebService.errorSubject.next({ status: 500, message: 'Internal server error' });
            }
        }
    }

    public async goToStripeCheckout(){
        if(this.business_id < 0 ){
            return
        }

        let params = {
            amount: 100,
        }

        let session = await this.walletWebService.createBusinessStripeCheckoutSession(this.business_id, params);
        let resp = await this.stripe.redirectToCheckout({sessionId: session,})

    }
}
