import { Injectable, isDevMode } from '@angular/core';
import { Http, Headers, Response } from '@angular/http';
import { catchError, map, tap } from 'rxjs/operators'
import { Observable, throwError, Subject, of } from 'rxjs'
import { JwtHelperService } from '@auth0/angular-jwt';
import { Utils } from '@utils/utils'
import { environment } from '../environments/environment';

const jwtHelper = new JwtHelperService();

export interface ErrorResponse {
    status: number,
    message: string
}

export interface SuccessResponse {
    summary: string,
    detail: string
}

export class BaseWebService {
    public webServiceUri: string = environment.webServiceUri;
    protected basePathWebService: string = "";
    public static errorSubject = new Subject<ErrorResponse>();
    public static successSubject = new Subject<SuccessResponse>();
    public static shouldLogoutSubject = new Subject<void>();

    constructor(protected http: Http, basePath?: string) {
        this.webServiceUri = environment.webServiceUri;

        if (this.webServiceUri.endsWith('/')) {
            this.webServiceUri = this.webServiceUri.substring(0, this.webServiceUri.length - 1);
        }

        if (basePath) {
            if (!basePath.startsWith("/")) {
                basePath = "/" + basePath;
            }
            this.basePathWebService = this.webServiceUri + basePath
            if (!this.basePathWebService.endsWith("/")) {
                this.basePathWebService += "/";
            }
        }
    }

    protected extractData(res: Response) {
        let body = res.json();
        return body || {};
    }

    protected handleError(error: Response | any) {
        let errorObj = null;

        try {
            errorObj = error.text().trim();
        } catch (ex) { }

        try {
            errorObj = JSON.parse(errorObj);
            let err = errorObj[Object.keys(errorObj)[0]];

            BaseWebService.errorSubject.next({ status: error.status, message: err });
        } catch (e) { }

        // if (isDevMode()) {
        if (errorObj) {
            return throwError(errorObj);
        } else if (error && error.text) {
            return throwError(error.text().trim());
        } else {
            return throwError(error);
        }
        // }
    }

    protected async apiGetCall(url: string, headers?: Headers): Promise<any> {
        if (!headers) {
            headers = new Headers();
        }
        headers.set('Content-Type', 'application/json');
        let token = localStorage.getItem("token");
        if (token) {
            const res = await this.checkTokenExpiryDate(token)
            if(!res) throw new Error("Token Expired");
            headers.set('Authorization', 'Bearer ' + token);
        }
        return this.http.get(url + '?ts=' + new Date().valueOf(), { headers: headers }).pipe(map(this.extractData), catchError(this.handleError.bind(this))).toPromise();
    }

    protected async apiPutCall(url: string, params?: any, headers?: Headers): Promise<any> {
        if (!headers) {
            headers = new Headers();
        }
        headers.set('Content-Type', 'application/json');
        let token = Utils.getToken();
        if (token) {
            const res = await this.checkTokenExpiryDate(token)
            if(!res) throw new Error("Token Expired");
            headers.set('Authorization', 'Bearer ' + token);
        }

        if (!params) {
            params = {};
        }
        return this.http.put(url + '?ts=' + new Date().valueOf(), params, { headers: headers }).pipe(map(this.extractData), catchError(this.handleError)).toPromise();
    }

    protected async apiPatchCall(url: string, params?: any, headers?: Headers): Promise<any> {
        if (!headers) {
            headers = new Headers();
        }
        headers.set('Content-Type', 'application/json');
        let token = Utils.getToken();
        if (token) {
            const res = await this.checkTokenExpiryDate(token)
            if(!res) throw new Error("Token Expired");
            headers.set('Authorization', 'Bearer ' + token);
        }

        if (!params) {
            params = {};
        }
        return this.http.patch(url + '?ts=' + new Date().valueOf(), params, { headers: headers }).pipe(map(this.extractData), catchError(this.handleError)).toPromise();
    }

    protected async apiRawCall(url: string, headers?: Headers): Promise<any> {
        if (!headers) {
            headers = new Headers();
        }
        headers.set('Content-Type', 'application/json');
        let token = Utils.getToken();
        if (token) {
            const res = await this.checkTokenExpiryDate(token)
            if(!res) throw new Error("Token Expired");
            headers.set('Authorization', 'Bearer ' + token);
        }
        return this.http.get(url + '?ts=' + new Date().valueOf(), { headers: headers }).pipe(catchError(this.handleError.bind(this))).toPromise();
    }

    protected async apiDeleteCall(url: string, headers?: Headers): Promise<any> {
        if (!headers) {
            headers = new Headers();
        }
        headers.set('Content-Type', 'application/json');

        let token = Utils.getToken();
        if (token) {
            const res =await this.checkTokenExpiryDate(token)
            if(!res) throw new Error("Token Expired");
            headers.set('Authorization', 'Bearer ' + token);
        }
        return this.http.delete(url + '?ts=' + new Date().valueOf(), { headers: headers }).pipe(map(this.extractData), catchError(this.handleError)).toPromise();
    }

    protected async apiPostCall(url: string, params?: any, headers?: Headers): Promise<any> {
        if (!headers) {
            headers = new Headers();
        }
        headers.set('Content-Type', 'application/json');
        let token = Utils.getToken();
        if (token) {
            const res = await this.checkTokenExpiryDate(token);
            if(!res) throw new Error("Token Expired");
            headers.set('Authorization', 'Bearer ' + token);
        }

        if (!params) {
            params = {};
        }
        return this.http.post(url + '?ts=' + new Date().valueOf(), params, { headers: headers }).pipe(map(this.extractData), catchError(this.handleError)).toPromise();
    }

    private async checkTokenExpiryDate(token: string): Promise<boolean> {
        if (jwtHelper.isTokenExpired(token)) {
            BaseWebService.shouldLogoutSubject.next();
            return false;
        }
        let expiryDateTs = jwtHelper.getTokenExpirationDate(token).valueOf();
        let todayTs = new Date().valueOf();
        let diff = (expiryDateTs - todayTs) / 1000 / 60;
        if (diff <= 15) {
            let headers = new Headers();
            headers.set('Content-Type', 'application/json');
            headers.set('Authorization', 'Bearer ' + token);
            let res = await this.http.get(this.webServiceUri + "/user/refresh" + '?ts=' + new Date().valueOf(), { headers: headers })
                .pipe(
                    // tap(()=>BaseWebService.scheduleSessionTimeout.next()),
                    map(this.extractData),
                    catchError(err => {
                        BaseWebService.shouldLogoutSubject.next();
                        return of(null)
                    })
                ).toPromise();
            if (res) {
                Utils.setToken(res);
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    }
}