import { Component, OnInit, ViewChild, TemplateRef, OnDestroy, AfterContentChecked } from '@angular/core';
import { CompanyModel } from '@app/models/company-model';
import { GroupModel } from '@app/models/group-model';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { CompanyWebService, GroupWebService } from '@app/providers/web.service';
import { BaseUser } from '@app/models/base-user';
import { Utils } from '@app/utils/utils';
import { AppService } from '@app/modules/app/app.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { GroupType } from '@app/utils/Enumerations';

@Component({
  selector: 'create-group-modal',
  templateUrl: './create-group-modal.component.html'
})
export class CreateGroupModalComponent implements OnInit {

  @ViewChild('selectUserType') public templateRefselectUserType: TemplateRef<any>;
  @ViewChild('selectGroupType') public templateRefSelectGroupType: TemplateRef<any>;
  @ViewChild('joinGroup') public templateRefJoinGroup: TemplateRef<any>;
  @ViewChild('groupData') public templateRefGroupData: TemplateRef<any>;
  @ViewChild('finish') public templateFinish: TemplateRef<any>;
  @ViewChild('childModal',) public templateChildModal: TemplateRef<any>;

  public bsModalRef: BsModalRef;
  public business: CompanyModel;
  public editMode: boolean = false;
  public group: GroupModel;
  public businessMembership: Array<any>;
  public currentUser: BaseUser;
  public selectUserModal: BsModalRef;
  public selectGroupTypeModal: BsModalRef;
  public groupDataModal: BsModalRef;
  public completeModal: BsModalRef;
  public nameRequired: boolean = false;
  public isDisabled: boolean = true;
  public createdGroup: GroupModel;
  public onComplete: Subject<boolean>;
  public childBsModalRef: BsModalRef;

  public createGroupForm: FormGroup = new FormGroup({
    creator_type: new FormControl('personal', [Validators.required]),
    business_id: new FormControl({value:'0', disabled: true},[]),
    group_type: new FormControl('2', [Validators.required] ),
    group_name: new FormControl('',),
    group_description: new FormControl('', ),
    is_public: new FormControl(true, )
  },{
    validators: [this.setBusinessIDFieldRequired]
  });


  constructor(
    private modalRef: BsModalRef,
    public modalService: BsModalService,
    private groupWebService: GroupWebService,
    private appService: AppService,
    private router: Router) {
  }

  ngOnInit() {
    this.openSelectUserTypeModal();
    this.onComplete = new Subject();
  }

   public setBusinessIDFieldRequired(formGroup: FormGroup) {
    if (formGroup.value.creator_type == 'business'){
      return Validators.required(formGroup.get('business_id'))
    }
    return null;
  }

  public openSelectUserTypeModal(){
    this.selectUserModal = this.modalService.show(this.templateRefselectUserType)
  }
  
  public openSelectGroupTypeModal(){
    if(this.createGroupForm.get('creator_type').value == 'business'){
      this.selectGroupTypeModal = this.modalService.show(this.templateRefSelectGroupType);
    }else{
      this.openGroupDataModal();
    }

  }

  public openGroupDataModal(){
    this.createGroupForm.get('group_name').setValidators([Validators.required])
    this.createGroupForm.get('group_description').setValidators([Validators.required])
    this.groupDataModal = this.modalService.show(this.templateRefGroupData)
  }
  
  public joinGroupModal(){
    this.bsModalRef =this.modalService.show(this.templateRefJoinGroup)
  }

  public async createGroup(){
    this.modalRef.hide();
    let createGroupFormValue = this.createGroupForm.getRawValue();
    let res = await this.groupWebService.createGroup(createGroupFormValue);

    if(res.name == this.createGroupForm.get('group_name').value){
      this.appService.reloadUser()
    }

    this.createdGroup = new GroupModel().fromJSON(res);
    this.completeModal = this.modalService.show(this.templateFinish);
  }

  public selectUser(){
    if (this.createGroupForm.value.creator_type=="business"){
      this.createGroupForm.controls['business_id'].disable();
    }else{
      this.createGroupForm.controls['business_id'].enable();
    }    
  }

  public complete(){
    let groupId = this.createdGroup.id
    let companyId = this.createGroupForm.get('business_id').value

    if(this.createdGroup.group_type === GroupType.Business){
      this.router.navigate(['group', groupId, 'business', companyId, 'profile']);
    }else{
      this.router.navigate(['group', groupId])
    }

    this.onComplete.next(true);
    this.completeModal.hide();
  }

  showChildModal(): void {
    this.childBsModalRef = this.modalService.show(this.templateChildModal, { class: 'gray modal-lg' })
  }
 
  hideChildModal(): void {
    this.childBsModalRef.hide();
  }
}
