import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map, timeout } from 'rxjs/operators'
import { UserWebService } from '@app/providers/web.service';
import { AppService } from '@app/modules/app/app.service';

@Component({
  selector: 'app-activation',
  templateUrl: './activation.component.html',
})

export class ActivationComponent implements OnInit {

  public email: any = null;
  public seconds: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private userWebService: UserWebService,
    private appService: AppService 
    ) { }

  ngOnInit() {
    this.email = this.activatedRoute.snapshot.params.email;
    
  }

  public async onClickSend(){
    this.appService.setAppLoading(true);
    try {
      await this.userWebService.reSendActivationEmail(this.email);
    } finally {
      this.appService.setAppLoading(false);
    }
    this.seconds = true;
    setTimeout(() => this.seconds = false, 10000);

    
  }
}
