import { Component } from '@angular/core';
import { ModalDirective, BsModalRef } from 'ngx-bootstrap';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { CharityWebService } from '@app/providers/web.service';
import { AppService } from '@app/modules/app/app.service';
import { BaseWebService } from '@app/providers/base-web.service';
import { CharityProjectSummaryModel } from '@app/models/charity-project-summary';
import { ProjectModel } from '@app/models/project-model';
import { GlobalConstants } from '@app/utils/Constants';
import { CharityDonation } from '@app/models/charity-donation';
import { CurrencyMaskConfig } from 'ng2-currency-mask/src/currency-mask.config';

@Component({
    selector: 'project-complete-modal',
    templateUrl: './project-complete-modal.component.html'
})
export class ProjectCompleteModal {

    public projectSummaryModel: CharityProjectSummaryModel;
    public project: ProjectModel;
    public onProjectComplete;

    public givingFundsAvailable = false;
    public givingGiftsAvailable = false;
    public givingSkilledVolunteeringAvailable = false;
    public volunteeringAvailable = false;

    public skilledVolunteeringConfirmationCols = [
        { field: 'user_full_name', header: 'NAME' },
        { field: 'skill', header: 'SKILLS' },
        { field: 'date', header: 'DATES', isDate: true },
        { field: 'time', header: 'TIME OFFERED' },
    ];

    public volunteeringConfirmationCols = [
        { field: 'user_full_name', header: 'NAME' },
        { field: 'date', header: 'DATES', isDate: true },
        { field: 'time', header: 'TIME OFFERED' },
    ];

  public inputMaskConfig: CurrencyMaskConfig = {
    align: "right",
    allowNegative: false,
    decimal: ".",
    precision: 0,
    prefix: "",
    suffix: "",
    thousands: ","
  };

    public skilledVolunteeringsDonations: Array<any> = [];
    public volunteeringsDonations: Array<any> = [];

    constructor(
        private appService: AppService,
        private charityWebService: CharityWebService,
        public modalRef: BsModalRef
    ) { }

    ngAfterViewInit() {
        this.projectSummaryModel.skilled_volunteers.map((volunteer: any) => {
            if(volunteer.type == GlobalConstants.TypeOfGivingMap.Volunteering) {
                (this.completeForm.get('volunteers_hours') as FormArray).push(new FormGroup({
                    id: new FormControl(volunteer.id),
                    hours: new FormControl(0)
                }));
            } else {
                (this.completeForm.get('skilled_volunteers_hours') as FormArray).push(new FormGroup({
                    id: new FormControl(volunteer.id),
                    hours: new FormControl(0)
                }));
            }
        });

        this.skilledVolunteeringsDonations = (this.projectSummaryModel.skilled_volunteers || []).filter((skilledVolunteer: any) => {
            if (skilledVolunteer.type == GlobalConstants.TypeOfGivingMap.SkilledVolunteering) {
                return true;
            }
        }).map((skilledVolunteer: any) => {
            return { ...skilledVolunteer, user_full_name: skilledVolunteer.first_name + ' ' + skilledVolunteer.last_name };
        });


        this.volunteeringsDonations = (this.projectSummaryModel.skilled_volunteers || []).filter((skilledVolunteer: any) => {
            if (skilledVolunteer.type == GlobalConstants.TypeOfGivingMap.Volunteering) {
                return true;
            }
        }).map((skilledVolunteer: any) => {
            return { ...skilledVolunteer, user_full_name: skilledVolunteer.first_name + ' ' + skilledVolunteer.last_name };
        });

        this.givingFundsAvailable = this.project.addedGivings.indexOf(GlobalConstants.TypeOfGivingMap.Funds) != -1;
        this.givingGiftsAvailable = this.project.addedGivings.indexOf(GlobalConstants.TypeOfGivingMap.Gifts) != -1;
        this.givingSkilledVolunteeringAvailable = this.project.addedGivings.indexOf(GlobalConstants.TypeOfGivingMap.SkilledVolunteering) != -1;
        this.volunteeringAvailable = this.project.addedGivings.indexOf(GlobalConstants.TypeOfGivingMap.Volunteering) != -1;

        this.completeForm.patchValue({ project_id: this.project.id });
    }

    public completeForm: FormGroup = new FormGroup({
        project_id: new FormControl(),
        comment: new FormControl(),
        volunteers_hours: new FormArray([]),
        skilled_volunteers_hours: new FormArray([])
    });

    public today = new Date();

    async onProjectCompleteSubmit() {
        this.appService.setAppLoading(true);
        let res = await this.charityWebService.completeProject(this.project.id, this.completeForm.value);
        this.appService.setAppLoading(false);

        if (res.code == 200) {
            this.project.status = 3;
            this.project.project_status_name = GlobalConstants.PROJECT_STATUSES[3];

            if (this.onProjectComplete) {
                this.onProjectComplete.emit();
            }

            BaseWebService.successSubject.next({ summary: 'Project Completed', detail: 'Project completed successful!' });
        } else {
            BaseWebService.errorSubject.next(res);
        }

        this.modalRef.hide();
    }

}
