import { BaseModel } from './base-model'
import { environment } from '@app/environments/environment';

export class BaseUser extends BaseModel {
    public address: string = null;
    public email: string = null;
    public first_name: string = null;
    public last_name: string = null;
    public full_name: string = null;
    public national_insurance_number: string = null;
    public gender: string = null;
    public birth_date: string = null;
    public phone: string = null;
    public city: string = null;
    public credits: number = null;
    public is_iic_admin: boolean = false;
    public user_description: string = null;
    public charity_membership: Array<any> = [];
    public charity_permissions: Array<any> = [];
    public id: number = -1;
    public wallet_amount: number = 0;
    public year: number = null;
    public month: number = null;
    public day: number = null;
    public avatar: string = null;
    public tax_refund: boolean = false;
    public is_public: boolean = false;
    public imageSmall: string = null; // 80x80
    public imageMedium: string = null; // 280x280
    public imageBig: string = null;
    public unread_messages: number = 0;
    public is_active: boolean = false;
    public is_active_string: string = "Activated"
    public post_code: string = null;

    public fromJSON(json: any) {
        this.address = json["address"] || this.address;
        this.email = json["email"] || this.email;
        this.first_name = json["first_name"] || this.first_name;
        this.last_name = json["last_name"] || this.last_name;
        this.national_insurance_number = json["national_insurance_number"] || this.national_insurance_number;
        this.birth_date = json["birth_date"] || this.birth_date;
        this.phone = json["phone"] || this.phone;
        this.city = json["city"] || this.city;
        this.credits = json["credits"];
        this.user_description = json["user_description"] || this.user_description;
        this.gender = json["gender"] || this.gender;
        this.is_iic_admin = json["is_iic_admin"] || this.is_iic_admin;
        this.id = json["id"] || this.id;
        if (json["pk"]) this.id = json["pk"];
        this.charity_membership = json['charity_membership'] || this.charity_membership;
        this.charity_permissions = json['charity_permissions'] || this.charity_permissions;
        this.full_name = json["first_name"] + ' ' + json["last_name"] || this.full_name;
        this.tax_refund = json["tax_refund"] === true;
        this.is_public = json["is_public"] === true;
        if (isNaN(Number(json["wallet_amount"]))) {
            this.wallet_amount = this.wallet_amount;
        } else {
            this.wallet_amount = (json["wallet_amount"] / 100);
        }

        this.avatar = json['avatar'] || this.avatar;
        if (this.avatar) {
            this.imageSmall = `url('${environment.imageUrl}${this.avatar}_small')`;
            this.imageMedium = `url('${environment.imageUrl}${this.avatar}_medium')`;
            this.imageBig = `url('${environment.imageUrl}${this.avatar}_big')`;
        } else {
            this.imageSmall = `url(/assets/img/user-placeholder.svg)`;
            this.imageMedium = `url(/assets/img/user-placeholder.svg)`;
            this.imageBig = `url(/assets/img/user-placeholder.svg)`;
        }

        if (json["birth_date"]) {
            let tmpDate = new Date(json["birth_date"])
            this.year = tmpDate.getFullYear();
            this.month = tmpDate.getMonth() + 1;
            this.day = tmpDate.getDate();
        }
        this.unread_messages = json["unread_messages"] || this.unread_messages;
        this.is_active = json["is_active"] || this.is_active;
        if(!this.is_active)
        this.is_active_string = "Not activated";
        this.post_code = json["post_code"] || this.post_code;

        return this;
    }

    public toJSON(): any {
        let user = {
            id: this.id,
            address: this.address,
            email: this.email,
            first_name: this.first_name,
            last_name: this.last_name,
            national_insurance_number: this.national_insurance_number,
            birth_date: this.birth_date,
            charity_membership: this.charity_membership,
            charity_permissions: this.charity_permissions,
            phone: this.phone,
            is_iic_admin: this.is_iic_admin,
            year: this.year,
            user_description: this.user_description,
            month: this.month,
            day: this.day,
            city: this.city,
            credits: this.credits,
            gender: this.gender,
            tax_refund: this.tax_refund,
            is_public: this.is_public,
            post_code: this.post_code
        }

        return user;
    }
}