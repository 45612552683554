import { Component } from '@angular/core';
import { CompanyModel } from '@app/models/company-model';
import { BsModalRef } from 'ngx-bootstrap';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CompanyWebService, InvitationWebService } from '@app/providers/web.service';
import { BusinessGroup } from '@app/models/business-group';
import { TreeNode } from 'primeng/api';
import { ValidatorHelpers } from '@app/utils/validators';
import { Utils } from '@app/utils/utils';
import { AppService } from '@app/modules/app/app.service';
import { CompanyService } from '@app/modules/business/business.service';

@Component({
    selector: 'add-employee-business-modal',
    templateUrl: './add-employee-business-modal.component.html'
})
export class AddEmployeeBusinessModalComponent {
    public business: CompanyModel;
    public groups: Array<BusinessGroup> = [];

    public cols: any[] = [
        { field: 'full_name', header: 'NAME' },
        { field: 'email', header: 'EMAIL' }
    ];

    public groupsTreeNode: TreeNode[] = [];
    public selectedSubGroup: TreeNode = null;

    public addEmployeeForm = new FormGroup({
        email: new FormControl('', [Validators.required, ValidatorHelpers.validateEmail("email")]),
        role: new FormControl(1, [Validators.required]),
        business_id: new FormControl()
    });

    constructor(
        public appService: AppService,
        public modalRef: BsModalRef,
        public companyWebService: CompanyWebService,
        public invitationWebService: InvitationWebService,
        public companyService: CompanyService
    ) {

    }

    async ngOnInit() {
        this.addEmployeeForm.patchValue({ business_id: this.business.company_id });
        let groupsRes = await this.companyWebService.getBusinessGroups(this.business.company_id);
        this.groups = groupsRes.map(group => new BusinessGroup().fromJSON(group));
        this.groups.forEach(group => {
            this.groupsTreeNode.push({
                label: group.name,
                data: group,
                selectable: false,
                children: group.sub_groups.map(subgroup => ({ label: subgroup.name, data: subgroup } as TreeNode))
            });
        });
    }

    public async addUserToGroup() {
        this.modalRef.hide();
    }

    public async onAddNewUsers() {
        Utils.validateAllFormFields(this.addEmployeeForm);
        if (!this.addEmployeeForm.valid) {
            return;
        }
        const subgroupIds = [];
        if(this.selectedSubGroup)subgroupIds.push(this.selectedSubGroup.data.id);
        this.appService.setAppLoading(true);
        try {
            let res = await this.invitationWebService.inviteAdmin({ ...this.addEmployeeForm.value, subgroups: subgroupIds });
        } catch (error) {}
        finally{
            this.appService.setAppLoading(false);
            this.modalRef.hide();
        }
    }
}
