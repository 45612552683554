import {Pipe, PipeTransform} from '@angular/core';
import linkifyStr from 'linkifyjs/string';

/*
 * Converts string links to anchor tags
*/
@Pipe({ name: 'linkify' })
export class LinkifyPipe implements PipeTransform {
    transform(str: string): string {
        return str ? linkifyStr('' + str) : str;
    }
}