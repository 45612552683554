import { FormControl, FormArray, Validators, FormGroup } from '@angular/forms';
import { GlobalConstants } from '@utils/Constants'
import { TypesOfGivingModel } from '@models/types-of-giving';
import { ValidatorHelpers } from '@utils/validators'
import { Utils } from './utils';

export class GivingUtils {

  public static requiredObj = { required: false };

  public static getTypeOfGivingGroup(type, projectEndDate: Date) {
    let definedTypesOfGivingGroups = {};

    definedTypesOfGivingGroups[GlobalConstants.TypeOfGivingMap.Empty] = {
      type: new FormControl(GlobalConstants.TypeOfGivingMap.Empty, Validators.required),
      items: new FormArray([])
    };

    definedTypesOfGivingGroups[GlobalConstants.TypeOfGivingMap.Funds] = {
      ...definedTypesOfGivingGroups[GlobalConstants.TypeOfGivingMap.Empty],
      items: new FormArray([new FormGroup({
        id: new FormControl(),
        min_amount: new FormControl(0, ValidatorHelpers.conditionalRequired(GivingUtils.requiredObj)),
        max_amount: new FormControl(0, ValidatorHelpers.conditionalRequired(GivingUtils.requiredObj)),
        end_date: new FormControl(projectEndDate, ValidatorHelpers.conditionalRequired(GivingUtils.requiredObj)),
        can_exceed_max: new FormControl(false),
      })])
    };

    definedTypesOfGivingGroups[GlobalConstants.TypeOfGivingMap.Volunteering] = {
      ...definedTypesOfGivingGroups[GlobalConstants.TypeOfGivingMap.Empty],
      items: new FormArray([new FormGroup({
        id: new FormControl(),
        hours: new FormControl(0, ValidatorHelpers.conditionalRequired(GivingUtils.requiredObj)),
        min_volunteers: new FormControl(0, ValidatorHelpers.conditionalRequired(GivingUtils.requiredObj)),
        max_volunteers: new FormControl(0, ValidatorHelpers.conditionalRequired(GivingUtils.requiredObj)),
        date_needed: new FormControl(projectEndDate, ValidatorHelpers.conditionalRequired(GivingUtils.requiredObj)),
        can_exceed_max: new FormControl(false)
      }/*, [ValidatorHelpers.maxValue('min_volunteers', 'max_volunteers', GivingUtils.required), ValidatorHelpers.minValue('max_volunteers', 'min_volunteers', GivingUtils.required)]*/)])
    };

    definedTypesOfGivingGroups[GlobalConstants.TypeOfGivingMap.SkilledVolunteering] = {
      ...definedTypesOfGivingGroups[GlobalConstants.TypeOfGivingMap.Empty],
      items: new FormArray([new FormGroup({
        id: new FormControl(),
        hours: new FormControl(0, ValidatorHelpers.conditionalRequired(GivingUtils.requiredObj)),
        min_volunteers: new FormControl(0, ValidatorHelpers.conditionalRequired(GivingUtils.requiredObj)),
        max_volunteers: new FormControl(0, ValidatorHelpers.conditionalRequired(GivingUtils.requiredObj)),
        volunteer_type_of_skill: new FormControl('', ValidatorHelpers.conditionalRequired(GivingUtils.requiredObj)),
        date_needed: new FormControl(projectEndDate, ValidatorHelpers.conditionalRequired(GivingUtils.requiredObj)),
        description: new FormControl('', [ValidatorHelpers.conditionalRequired(GivingUtils.requiredObj), Validators.minLength(2), Validators.maxLength(10000)]),
        can_exceed_max: new FormControl(false)
      }/*, [ValidatorHelpers.maxValue('min_volunteers', 'max_volunteers', GivingUtils.required), ValidatorHelpers.minValue('max_volunteers', 'min_volunteers', GivingUtils.required)]*/)])
    };

    definedTypesOfGivingGroups[GlobalConstants.TypeOfGivingMap.Gifts] = {
      ...definedTypesOfGivingGroups[GlobalConstants.TypeOfGivingMap.Empty],
      items: new FormArray([new FormGroup({
        id: new FormControl(),
        min_amount: new FormControl(0, ValidatorHelpers.conditionalRequired(GivingUtils.requiredObj)),
        max_amount: new FormControl(0, ValidatorHelpers.conditionalRequired(GivingUtils.requiredObj)),
        cid: new FormControl(1, ValidatorHelpers.conditionalRequired(GivingUtils.requiredObj)),
        gift_item_id: new FormControl(null, [ValidatorHelpers.conditionalRequired(GivingUtils.requiredObj), Validators.min(1)]),
        end_date: new FormControl(projectEndDate, ValidatorHelpers.conditionalRequired(GivingUtils.requiredObj)),
        description: new FormControl('', [ValidatorHelpers.conditionalRequired(GivingUtils.requiredObj), Validators.minLength(2), Validators.maxLength(10000)]),
        can_exceed_max: new FormControl(false)
      }/*, [ValidatorHelpers.maxValue('min_amount', 'max_amount', GivingUtils.required), ValidatorHelpers.minValue('max_amount', 'min_amount', GivingUtils.required)]*/)])
    };

    let formGroup = new FormGroup(definedTypesOfGivingGroups[type]);
    //Utils.removeControlsValidators(formGroup);
    return formGroup;
  }


  public static getTypeOfGivingItems(addedTypesOfGiving) {
    let typeOfGivingFormValue = addedTypesOfGiving;
    let ret = [];
    for (let typeOfGiving of typeOfGivingFormValue) {
      let typeOfGivingFormGroup: FormGroup = typeOfGiving;
      ret.push(typeOfGivingFormGroup.value)
    }
    return ret;
  }

  public static addAdditionalItem(typeOfGivingGroup: FormGroup, data: TypesOfGivingModel, projectEndDate: Date) {
    let newItem = GivingUtils.getTypeOfGivingGroup(typeOfGivingGroup.get('type').value, projectEndDate).get('items') as FormArray
    let formArr = typeOfGivingGroup.get('items') as FormArray
    newItem.controls[0].patchValue(data);
    formArr.push(newItem.controls[0]);
  }

  public static flattenItems(addedTypesOfGiving) {
    let ret: Array<TypesOfGivingModel> = [];
    for (let item of GivingUtils.getTypeOfGivingItems(addedTypesOfGiving)) {
      for (let subItem of item.items) {
        let tmpItem = new TypesOfGivingModel().fromJSON(subItem)
        tmpItem.type = item.type;
        ret.push(tmpItem);
      }
    }
    return ret;
  }
}