import { Component } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CharityService } from '@app/modules/charity/charity.service';
import { CharityWebService } from '@providers/web.service';
import { BsModalRef } from 'ngx-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectModel } from '@app/models/project-model';
import { BaseWebService } from '@app/providers/base-web.service';
import { AppService } from '@app/modules/app/app.service';


@Component({
    selector: 'cancel-project-modal',
    templateUrl: './cancel-project-modal.html'
})
export class CancelProjectModalComponent {

    public cancelProjectForm: FormGroup = new FormGroup({
        message: new FormControl(null, Validators.required),
        emails: new FormControl(null, Validators.required),
        project_id: new FormControl(null, Validators.required)
    });

    constructor(
        private modalService: BsModalService,
        private charityWebService: CharityWebService,
        private charityService: CharityService,
        private appService: AppService,
        private router: Router,
        public bsModalRef: BsModalRef
    ) {
    }

    async cancelProject() {
        this.appService.setAppLoading(true);
        let projectId = this.cancelProjectForm.get('project_id').value;
        await this.charityWebService.cancelProject(projectId, this.cancelProjectForm.getRawValue());
        let charityId = this.charityService.getCharity().id;
        this.router.navigate(['charity', charityId]);
        this.appService.setAppLoading(false);
        this.bsModalRef.hide();
    }
}
