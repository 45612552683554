import {Component, Input, Output, EventEmitter} from '@angular/core';

@Component(
    {
        selector: 'iic-pagination',
        templateUrl: './pagination.component.html'
    }
)

export class Pagination {

    @Input()
    public totalRecords: number = 0;
    @Input()
    public rows: number = 0;
    @Input()
    public first: number = 0;
    @Input()
    public pageLinkSize: number = 0;
    @Input()
    public rowsPerPageOptions: Array<number> = null;

    @Output()
    public onPageChange: EventEmitter<any> = new EventEmitter();
}