import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'charity-project-thank-you-modal',
  templateUrl: './thank-you-modal.component.html'
})
export class ThankYouModalComponent {

  public text: string = "";

  constructor(public bsModalRef: BsModalRef) {
  }
}
