import { AppService } from '@appmodule/app.service';
import { Component, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { UploaderOptions, UploadFile, UploadInput, UploadOutput, UploadStatus } from 'ngx-uploader';
import { UploaderOptionsExtended } from '@interfaces/uploader-options-extended'

@Component(
    {
        selector: 'uploader-multiple',
        templateUrl: './uploader-multiple.component.html'
    }
)

export class UploaderMultiple {

    @Input()
    public options: UploaderOptionsExtended;
    @Input()
    public multiple: boolean;
    @Input()
    public imagePreview: any
    formData: FormData;
    files: UploadFile[];
    uploadInput: EventEmitter<UploadInput>;
    @Input()
    public uploadInputEvent: UploadInput = null;
    humanizeBytes: Function;
    dragOver: boolean;
    uploadPromiseResolve = null;
    @Input()
    public disabled: boolean = false;
    @Input()
    public filesToShow: Array<any> = [];
    @Output()
    public filesToShowUpdated: EventEmitter<Array<any>> = new EventEmitter();

    @ViewChild('fileInput') public fileInput: ElementRef

    constructor(private appService: AppService) {
        this.files = [];
        this.uploadInput = new EventEmitter<UploadInput>();
    }

    onUploadOutput(output: UploadOutput): void {
        if (output.type === 'allAddedToQueue') {
        } else if (output.type === 'addedToQueue' && typeof output.file !== 'undefined') {

            if (!this.disabled && output.file.size < this.options.maxFileSize) {
                let file = output.file
                if(this.isImage(output.file.type)) {
                    this.previewImage(file).then(response => {
                        if (response) {
                            this.filesToShow = [...this.filesToShow, {file: 'url(' + response + ')', index: file.fileIndex}]; //Image preview
                            this.filesToShowUpdated.emit(this.filesToShow);
                        }
                    });
                } else {
                    this.filesToShow = [...this.filesToShow, {file: output.file.name, index: file.fileIndex}];
                    this.filesToShowUpdated.emit(this.filesToShow);
                }
            }
            if (!this.disabled && output.file.size < this.options.maxFileSize) this.files.push(output.file);
            else this.appService.showWarning("File size exceeded", "Please choose a smaller file.")
        } else if (output.type === 'uploading' && typeof output.file !== 'undefined') {
            const index = this.files.findIndex(file => typeof output.file !== 'undefined' && file.id === output.file.id);
            this.files[index] = output.file;
        } else if (output.type === 'removed') {
            this.files = this.files.filter((file: UploadFile) => file !== output.file);
        } else if (output.type === 'dragOver') {
            this.dragOver = true;
        } else if (output.type === 'dragOut') {
            this.dragOver = false;
        } else if (output.type === 'drop') {
            this.dragOver = false;
        } else if (output.type === 'rejected' && typeof output.file !== 'undefined') {
            this.appService.showWarning("Not supported extension", "We do not allow such files. Please choose a different one.")
        }

        let finishedFiles: Array<UploadFile> = this.files.filter(file => !((file.progress.status === UploadStatus.Done) || (file.progress.status === UploadStatus.Cancelled)));
        if (finishedFiles.length == 0) {
            if (output && output.file && output.file.response) {
                this.uploadPromiseResolve(output.file.response);
            } else {
                if(this.uploadPromiseResolve) this.uploadPromiseResolve(null);
            }
            this.files = [];
            this.fileInput.nativeElement.value = null;
        }
    }

    public async startUpload(): Promise<any> {
        if (this.files.length > 0) {
            return new Promise<any>((resolve, reject) => {
                this.uploadPromiseResolve = resolve
                this.uploadInput.emit(this.uploadInputEvent);
            })
        }
        return null;
    }

	removeFile(id: string): void {
		this.uploadInput.emit({ type: 'remove', id: id });
	}

    removeAllFiles(): void {
        this.uploadInput.emit({ type: 'removeAll' });
    }

    cancelUpload(id: string): void {
        this.uploadInput.emit({ type: 'cancel', id: id });
    }

    // The preview function
    private previewImage(file: any): Promise<any> {
        const fileReader = new FileReader();
        return new Promise(resolve => {
            fileReader.readAsDataURL(file.nativeFile);
            fileReader.onload = function (e: any) {
                resolve(e.target.result);
            }
        });
    }

    private isImage(type:string): boolean {
        return ((type == "image/png") || (type == "image/jpeg"))
    }
}