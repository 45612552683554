import { AppService } from '@appmodule/app.service';
import { BaseUser } from '@models/base-user';
import { GroupModel } from '@models/group-model';
import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { GroupAdminPrivs } from '@utils/Enumerations';
import { GroupWebService } from '@app/providers/web.service';

@Injectable()
export class GroupService {

    public groupSource: Subject<GroupModel> = new Subject();
    //public reloadSupporters: Subject<void> = new Subject();
    public group: GroupModel = new GroupModel();
    public groupUser: BaseUser = null;
    public userPrivs: GroupAdminPrivs = -1;

    constructor(private appService: AppService, private groupWebService: GroupWebService) {}

    public setGroup(val: GroupModel) {
        this.group = val;
        this.groupUser = this.appService.getUser();
        let groupPrivs = this.appService.getUserGroups().filter((item) => {
            return item.group_id == this.group.id;
        });
        if (groupPrivs.length > 0) {
            this.userPrivs = groupPrivs[0].permissions;
        }
        this.groupSource.next(this.group);
    }

    public getUserPrivileges(){
        return this.userPrivs;
    }

    public async getIndividualPrivs(id: number, group_id: number){
        this.userPrivs = -1;
        let resGroups = await this.groupWebService.getIndividualGroupList(id)
        let groups = resGroups.map(g => {
            return new GroupModel().fromJSON(g);
          });
           
        let privs = groups.find(g=>g.id == group_id);
        if(privs){
            this.userPrivs = privs.permission
        }
        return this.userPrivs
    }

    public async getCompanyPrivs(id: number, group_id: number){
        this.userPrivs = -1;
        let resGroups = await this.groupWebService.getCompanyGroupList(id)
        let groups = resGroups.map(g => {
            return new GroupModel().fromJSON(g);
          });
           
        let privs = groups.find(g=>g.id == group_id);
        if(privs){
            this.userPrivs = privs.permission
        }
        return this.userPrivs
    }

    public getGroup(): GroupModel {
        return this.group;
    }

    public clearService() {
        this.group = new GroupModel();
        this.groupUser = null;
        this.userPrivs = -1;
    }
    
}