import { AppService } from '@appmodule/app.service';
import { Component, OnInit, isDevMode } from '@angular/core';
import { Router } from '@angular/router';
import { CharityService } from '@app/modules/charity/charity.service';
import { CompanyService } from '@app/modules/business/business.service';

@Component({
  selector: 'top-bar',
  templateUrl: './top-bar.component.html'
})
export class TopBarComponent {

  isDeveloper = isDevMode();
  public remainingMin = null;
  
  constructor(
    public appService: AppService,
    public charityService: CharityService,
    public companyService: CompanyService,
    public router: Router
  ) { 
  }

  async logout() {
    this.charityService.clearService();
    this.companyService.clearService();
    await this.appService.logoutUser();
  }
}
