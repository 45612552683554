import { Component, Input } from '@angular/core';
import { CharityWebService, UploadWebService } from '@app/providers/web.service';
import { ImpactStatement } from '@app/models/impact-statement';
import { CharityModel } from '@app/models/charity-model';
import { ViewChild } from '@angular/core';
import { UploaderMultiple } from '@app/modules/common/file-uploader-multiple/uploader-multiple.component';
import { UploaderOptionsExtended } from '@app/interfaces/uploader-options-extended';
import { UploadInput } from 'ngx-uploader';
import { Carousel } from 'primeng/components/carousel/carousel';

@Component(
    {
        selector: 'impact-statement',
        templateUrl: './impact-statement.component.html'
    }
)
export class ImpactStatementComponent {
    @Input() projectImpactStatement: ImpactStatement;
    @Input() charity: CharityModel;
    public galleryToShow: Array<any> = [];
    @ViewChild(Carousel) public carousel: Carousel

    constructor(private charityWebService: CharityWebService, private uploaderWebService: UploadWebService) {
    }

    ngOnInit() {
        this.galleryToShow = this.projectImpactStatement.gallery.map((image) => { return { file: image.url, fileUUID: image.FileUUID } });
    }
}
