import { environment } from "@app/environments/environment";

export class GlobalConstants {

  // public static readonly ONBOARD_URL = `https://dashboard.stripe.com/oauth/authorize?response_type=code&client_id=${environment.stripeCA}&scope=read_write`;
  public static readonly CHARITY_TYPES_MAP = {
    1: 'Animals',
    2: 'Armed Forces',
    3: 'Arts & Heritage',
    4: 'Children and Young People',
    5: 'Community',
    6: 'Disability',
    7: 'Disaster Relief',
    8: 'Elderly',
    9: 'Education and Skills',
    10: 'Environment and Conservation',
    11: 'General',
    12: 'Health and Social Care',
    13: 'Human rights',
    14: 'International',
    15: 'Poverty',
    16: 'Public Services',
    17: 'Social Welfare',
    18: 'Sport'
  }

  public static readonly DONATION_STATUS = {
    80: 'Pending',
    90: 'Rejected'
  }

  public static readonly CHARITY_TYPES = [
    { id: 1, title: 'Animals' },
    { id: 2, title: 'Armed Forces' },
    { id: 3, title: 'Arts & Heritage' },
    { id: 4, title: 'Children and Young People' },
    { id: 5, title: 'Community' },
    { id: 6, title: 'Disability' },
    { id: 7, title: 'Disaster Relief' },
    { id: 8, title: 'Elderly' },
    { id: 9, title: 'Education and Skills' },
    { id: 10, title: 'Environment and Conservation' },
    { id: 11, title: 'General' },
    { id: 12, title: 'Health and Social Care' },
    { id: 13, title: 'Human rights' },
    { id: 14, title: 'International' },
    { id: 15, title: 'Poverty' },
    { id: 16, title: 'Public Services' },
    { id: 17, title: 'Social Welfare' },
    { id: 18, title: 'Sport' }
  ]

  public static readonly CHARITY_TYPES_ICONS = {
    1: "fa-paw",
    2: "fa-shield-alt",
    3: "fa-paint-brush",
    4: "fa-child",
    5: "fa-users",
    6: "fa-wheelchair",
    7: "fa-paw",
    8: "fa-paw",
    9: "fa-graduation-cap",
    10: "fa-envira",
    11: "fa-paw",
    12: "fa-briefcase-medical",
    13: "fa-fist-raised",
    14: "fa-globe-americas",
    15: "fa-paw",
    16: "fa-hands",
    17: "fa-hand-holding-heart",
    18: "fa-futbol"
  }

  public static readonly GENDERS = [
    { id: 'male', title: 'Male' },
    { id: 'female', title: 'Female' },
    { id: 'trans-male', title: 'Trans-Male' },
    { id: 'trans-female', title: 'Trans-Female' },
    { id: 'gender neutral', title: 'Gender Neutral' },
    { id: 'other', title: 'Other' },
    { id: 'prefer not to say', title: 'Prefer not to say' }
  ];

  public static readonly GENDERS_MAP = {
    'male': 'Male',
    'female': 'Female',
    'trans-male': 'Trans-Male',
    'trans-female': 'Trans-Female',
    'gender neutral': 'Gender Neutral',
    'other': 'Other',
    'prefer not to say': 'Prefer not to say',
  };

  public static readonly ADMIN_TYPES = {
    1: 'Super Admin',
    2: 'Admin',
    99: 'Employee'
  }

  public static readonly PROJECT_ADMIN_TYPES = {
    1: 'Admin'
  }

  // begins with 2 because GIVING_TYPES...
  public static readonly TYPES_OF_GIVING = {
    1: 'MANUAL ADJUSTMENT',
    2: 'Funds',
    3: 'Volunteering',
    4: 'Skilled Volunteering',
    5: 'Gifts'
  }

  public static readonly PROJECT_STATUSES = {
    0: "Cancelled",
    1: "Draft",
    2: "Live",
    3: "Completed",
    9: "Finished"
  }

  public static readonly FREQUENCIES = {
    1: 'Daily',
    2: 'Weekly',
    3: 'Monthly',
    4: 'Yearly',
  }

  public static readonly REQUEST_STATUSES = {
    1: 'Pending',
    2: 'Accepted',
  }


  public static readonly GIVING_TYPES = [
    { id: 1, title: 'Type of Giving' },
    { id: 2, title: 'Funds' },
    { id: 3, title: 'Volunteering' },
    { id: 4, title: 'Skilled Volunteering' },
    { id: 5, title: 'Gifts' },
  ]

  public static readonly GIVING_TYPES_FILTER = [
    { id: 2, title: 'Funds' },
    { id: 3, title: 'Volunteering' },
    { id: 4, title: 'Skilled Volunteering' },
    { id: 5, title: 'Gifts' },
  ]

  public static readonly TypeOfGiving = {
    Empty: "Type of Giving",
    Funds: "Funds",
    Volunteering: "Volunteering",
    SkilledVolunteering: "Skilled Volunteering",
    Gifts: "Gifts"
  }


  public static readonly TypeOfGivingMap = {
    Empty: 1,
    Funds: 2,
    Volunteering: 3,
    SkilledVolunteering: 4,
    Gifts: 5
  }

  public static SkilledVolunteeringTypesMap = {
    1: "Arts/Music/Drama/Dance/Crafts",
    2: "Finance & Accounting",
    3: "Legal/Governance",
    4: "Marketing & Social Media",
    5: "Business Development",
    6: "IT/Tech",
    7: "Graphics/Design/Web",
    8: "PR/Comms/Writing",
    9: "Business Mentoring/Leadership/Strategy",
    10: "HR",
    11: "Teaching/Training/Education",
    12: "Translating",
    13: "Public Speaking/Inspirational Talks",
    14: "Skilled Trades; e.g. Electrician/Plumber",
    15: "Admin",
    16: "Other"
  }

  public static skilledVolunteeringTypes = [
    { id: 1, title: "Arts/Music/Drama/Dance/Crafts" },
    { id: 2, title: "Finance & Accounting" },
    { id: 3, title: "Legal/Governance" },
    { id: 4, title: "Marketing & Social Media" },
    { id: 5, title: "Business Development" },
    { id: 6, title: "IT/Tech" },
    { id: 7, title: "Graphics/Design/Web" },
    { id: 8, title: "PR/Comms/Writing" },
    { id: 9, title: "Business Mentoring/Leadership/Strategy" },
    { id: 10, title: "HR" },
    { id: 11, title: "Teaching/Training/Education" },
    { id: 12, title: "Translating" },
    { id: 13, title: "Public Speaking/Inspirational Talks" },
    { id: 14, title: "Skilled Trades; e.g. Electrician/Plumber" },
    { id: 15, title: "Admin" },
    { id: 16, title: "Other" }
  ]

  public static giftTypesMap = {
    1: "Technology ",
    2: "Food related",
    3: "Equipment and Furniture ",
    4: "Supplies ",
    5: "Clothing or Bedding ",
    6: "Room hire ",
    7: "Household",
    8: "Transport ",
    9: "Garden",
    10: "Experiences",
    11: "General",
    12: "Other",
  }

  public static giftTypes = [
    { id: 1, title: "Technology " },
    { id: 2, title: "Food related" },
    { id: 3, title: "Equipment and Furniture " },
    { id: 4, title: "Supplies " },
    { id: 5, title: "Clothing or Bedding " },
    { id: 6, title: "Room hire " },
    { id: 7, title: "Household" },
    { id: 8, title: "Transport " },
    { id: 9, title: "Garden" },
    { id: 10, title: "Experiences" },
    { id: 11, title: "General" },
    { id: 12, title: "Other" }
  ]

  public static readonly FEE_TYPES = {
    1: 'Monthly fee',
    2: 'Annual fee'
  }

  public static PROJECT_CHARITY_STATUS_MAP = {
    1: 'New',
    2: 'Pending',
    3: 'Rejected',
    4: 'Accepted',
    5: 'Charity Project'
  }

  public static readonly DONATION_METHOD_MAP = {
    1: "Project",
    2: "Direct Donation"
  }

  public static readonly TRANSACTION_STATUS_MAP = {
    20: 'Awaiting Business Approval',
    30: 'Awaiting Charity Approval',
    40: 'Awaiting IIC Admin Approval',
    80: 'Approved',
    85: 'Completed',
    90: 'Canceled',
  }

  public static GROUP_TYPE_MAP = {
    1: 'Business',
    2: 'Individual',
    3: 'Franchise'
  }

  public static RequiredDateTooltipText = `Please insert a required date in which you require this element of the project,
    otherwise leave blank and it will automaticaly use the end date of the project`;

  public static readonly AutocompleteItem = {
    City: "city",
    Address: "address",
    PostCode: "post_code"
  }

  public static readonly DIRECT_DEBIT_TYPE = {
    1: 'Wallet charge',
    3: 'Charity subscription'
  }

  public static readonly CursorWayAfter = "after" 
  public static readonly CursorWayBefore = "before" 
}

export enum TypeOfGivingEnum {
  Empty = 1,
  Funds,
  Volunteering,
  SkilledVolunteering,
  Gifts,
}

export enum PROJECT_ADMIN_TYPES {
  Admin = 1
}

export enum ProjectCharityStatus {
  New = 1,
  Pending,
  Rejected,
  Accepted,
  CharityProject
}


export enum ThemeEnum {
  Individual = 1,
  Charity,
  Business,
  Franchise,
  Default
}

export enum GroupTypes {
  All = 0,
  BusinessOnly,
  IndividualOnly,
  Franchise,
}

export enum UserType {
  Individual = 1,
  Business,
  Charity,
  Project
}

export enum MessageStatus {
  Draft = 1,
  Sent = 2,
  // Read 	= 3,
  Archived = 9
}

