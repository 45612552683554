import { Utils } from '@app/utils/utils';
import { Injectable } from '@angular/core';
import { BaseWebService } from './base-web.service';
import { Http } from '@angular/http';
import { CharityModel } from '@models/charity-model';
import { CompanyModel } from '@app/models/company-model';
import { UploadInput } from 'ngx-uploader';
import { UploaderOptionsExtended } from '@interfaces/uploader-options-extended';
import { GroupModel } from '@models/group-model'


@Injectable()
export class UserWebService extends BaseWebService {
    constructor(protected http: Http) {
        super(http);
    }

    public async login(params: any, showModal: boolean = true) {
        let url = this.webServiceUri + "/user/login";
        let res = await this.apiPostCall(url, params)
        if (showModal) {
            BaseWebService.successSubject.next({ summary: 'Logged in', detail: 'Successfully logged in!' });
        }
        return res;
    }

    public async register(user: any, endpoint, showModal: boolean = true) {
        let url = this.webServiceUri + "/user/register" + endpoint;
        let res = await this.apiPostCall(url, user)
        if (showModal) {
            BaseWebService.successSubject.next({ summary: 'Confirmation email', detail: 'Successfully sent confirmation email!' });
        }
        return res;
    }

    public async registerCharity(charity: any, showModal: boolean = true) {
        let url = this.webServiceUri + "/user/add/charity";
        let res = await this.apiPostCall(url, charity)
        if (showModal) {
            BaseWebService.successSubject.next({ summary: 'Charity Registration Successfull', detail: 'Successfully registered charity!' });
        }
        return res;
    }

    public async registerBusiness(business: any, showModal: boolean = true) {
        let url = this.webServiceUri + "/user/add/business";
        let res = await this.apiPostCall(url, business)
        if (showModal) {
            BaseWebService.successSubject.next({ summary: 'Business Registration Successfull', detail: 'Successfully registered business!' });
        }
        return res;
    }

    public async resetPassword(email: string, showModal: boolean = true) {
        let url = this.webServiceUri + `/user/passwordreset/${email}`;
        let res = await this.apiPostCall(url);
        if (showModal) {
            BaseWebService.successSubject.next({ summary: 'Password reset', detail: `Successfully sent your new password on ${email}!` });
        }
        return res;
    }

    public async getUserDetail() {
        let url = this.webServiceUri + "/user/get";
        return this.apiGetCall(url)
    }

    public async updateUser(id, params: any, showModal: boolean = true) {
        let url = this.webServiceUri + `/user`;
        let res = await this.apiPatchCall(url, params);
        if (showModal) {
            BaseWebService.successSubject.next({ summary: 'Update successful', detail: 'Successfully updated user!' });
        }
        return res;
    }

    public async changePassword(params, showModal: boolean = true) {
        let url = this.webServiceUri + `/user/password`;
        let res = await this.apiPatchCall(url, params);
        if (showModal) {
            BaseWebService.successSubject.next({ summary: 'Password updated', detail: 'Successfully updated password!' });
        }
        return res;
    }

    public async changeEmail(params, showModal: boolean = true) {
        let url = this.webServiceUri + `/user/email`;
        let res = await this.apiPostCall(url, params);
        if (showModal) {
            BaseWebService.successSubject.next({ summary: 'Email updated', detail: 'Successfully updated email!' });
        }
        return res;
    }

    public async deleteUser(id: number, showModal: boolean = true) {
        let url = this.webServiceUri + `/user/${id}`;
        let res = await this.apiDeleteCall(url)
        if (showModal) {
            BaseWebService.successSubject.next({ summary: 'Deleted account', detail: 'Successfully deleted user account!' });
        }
        return res;
    }

    public async getUserBusinessJoinRequests(id: string) {
        let url = this.webServiceUri + `/user/requests/${id}`;
        return this.apiGetCall(url);
    }

    public async getProjectListForIndividual() {
        let url = this.webServiceUri + `/project/individual`;
        return this.apiGetCall(url);
    }

    public async getUserProjectList(company_id: string) {
        let url = this.webServiceUri + `/project/business/${company_id}`;
        return this.apiGetCall(url);
    }

    public async getUserDonations() {
        let url = this.webServiceUri + `/user/donations`;
        return this.apiGetCall(url);
    }

    public async getUserDonationsByType(type: number) {
        let url = this.webServiceUri + `/user/donations_by_type/${type}`;
        return this.apiGetCall(url);
    }

    public async getUserWallet() {
        let url = this.webServiceUri + `/user/wallet`;
        return this.apiGetCall(url);
    }

    public async getUserTotalStatistics() {
        let url = this.webServiceUri + `/user/total_statistics`;
        return this.apiGetCall(url);
    }

    public async getUserVolunteeringByType(type: number) {
        let url = this.webServiceUri + `/user/volunteering/${type}`;
        return this.apiGetCall(url);
    }

    public async getUserFutureDonations() {
        let url = this.webServiceUri + `/user/future_donations`;
        return this.apiGetCall(url);
    }

    public async reSendActivationEmail(email: any, showModal: boolean = true) {
        let url = this.webServiceUri + `/user/send_activation_email/${email}`;
        let res = await this.apiPostCall(url)
        if (showModal) {
            BaseWebService.successSubject.next({ summary: 'Confirmation email', detail: 'Successfully sent confirmation email!' });
        }
        return res;
    }

    public async getUserRejectedDonations() {
        let url = this.webServiceUri + `/user/rejected_donations`;
        return this.apiGetCall(url);
    }

    public async getUserDonationSubscriptions(){
        let url = this.webServiceUri + `/user/donation_subscriptions`;
        return this.apiGetCall(url);
    }

    //cancelDonationSubscription
    public async cancelDonationSubscription(subscription_id: string, showModal: boolean = true){
        let url = this.webServiceUri + `/user/cancel_donation_subscription/${subscription_id}`;
        let res = this.apiPostCall(url);
        if (showModal) {
            BaseWebService.successSubject.next({ summary: 'Recurring Donation Cancelled', detail: 'Successfully cancelled your recurring donation!' });
        }
        return res
    }

    public async getUserStripeTransctions(way:string, start_at: string){
        let url = this.webServiceUri + `/user/payments/${way}/${start_at}`;
        return this.apiGetCall(url);
    }
}

@Injectable()
export class CharityWebService extends BaseWebService {
    constructor(protected http: Http) {
        super(http);
    }

    public async getCharity(id: string) {
        let url = this.webServiceUri + `/charity/${id}`;
        return this.apiGetCall(url);
    }

    public async getAllCharities() {
        let url = this.webServiceUri + "/charity/list";
        return this.apiGetCall(url);
    }

    public async getCharityUsers(id: string) {
        let url = this.webServiceUri + `/charity/users/${id}`;
        return this.apiGetCall(url);
    }

    public async updateCharity(id: string, charity: CharityModel, showModal: boolean = true) {
        let url = this.webServiceUri + `/charity/${id}`;
        let res = await this.apiPatchCall(url, charity)
        if (showModal) {
            BaseWebService.successSubject.next({ summary: 'Updated charity', detail: 'Successfully updated charity details!' });
        }
        return res;
    }

    public async lockCharity(id: string) {
        return this.changeCharityLock(id, true);
    }

    public async unlockCharity(id: string) {
        return this.changeCharityLock(id, false);
    }

    private async changeCharityLock(id: string, lockStatus: boolean, showModal: boolean = true) {
        let summary = 'Unlocked charity';
        let detail = 'Successfully unlocked charity!';
        if (lockStatus) {
            summary = 'Locked charity';
            detail = 'Successfully locked charity!';
        }
        let url = this.webServiceUri + `/charity/${id}`;
        let res = await this.apiPatchCall(url, { lock_status: lockStatus });
        if (showModal) {
            BaseWebService.successSubject.next({ summary: summary, detail: detail });
        }
        return res;
    }

    public async deleteCharity(id: number, showModal: boolean = true) {
        let url = this.webServiceUri + `/charity/${id}`;
        let res = await this.apiDeleteCall(url)
        if (showModal) {
            BaseWebService.successSubject.next({ summary: 'Deleted charity', detail: 'Successfully deleted charity!' });
        }
        return res;
    }

    public async deleteMember(user: string, charity: string, showModal: boolean = true) {
        let url = this.webServiceUri + `/membership/charity/${user}/${charity}`;
        let res = await this.apiDeleteCall(url)
        if (showModal) {
            BaseWebService.successSubject.next({ summary: 'Removed charity member', detail: 'Successfully removed charity member!' });
        }
        return res;
    }

    public async deleteProjectMember(userId: string, projectId: string, showModal: boolean = true) {
        let url = this.webServiceUri + `/membership/project/${userId}/${projectId}`;
        let res = await this.apiDeleteCall(url)
        if (showModal) {
            BaseWebService.successSubject.next({ summary: 'Removed project member', detail: 'Successfully removed project member!' });
        }
        return res;
    }

    public async deleteProject(projectId: string, showModal: boolean = true) {
        let url = this.webServiceUri + `/project/${projectId}`;
        let res = await this.apiDeleteCall(url);
        if (showModal) {
            BaseWebService.successSubject.next({ summary: 'Removed project', detail: 'Successfully removed project!' });
        }
        return res;
    }

    public async getProject(id: string) {
        let url = this.webServiceUri + `/project/${id}`;
        return this.apiGetCall(url);
    }

    public async getProjectVolunteered(id: string) {
        let url = this.webServiceUri + `/project/volunteered/${id}`;
        return this.apiGetCall(url);
    }

    public async getProjectSummary(project_id: string) {
        let url = this.webServiceUri + `/project/${project_id}/summary`;
        return this.apiGetCall(url);
    }

    public async createProject(params: any, showModal: boolean = true) {
        let url = this.webServiceUri + `/project`;
        let res = await this.apiPostCall(url, params)
        if (showModal) {
            BaseWebService.successSubject.next({ summary: 'Project created', detail: 'Successfully created project!' });
        }
        return res;
    }

    public async cloneProject(params: any, showModal: boolean = true) {
        let url = this.webServiceUri + `/project/clone`;
        let res = await this.apiPostCall(url, params)
        if (showModal) {
            BaseWebService.successSubject.next({ summary: 'Project cloned', detail: 'Successfully clone project!' });
        }
        return res;
    }

    public async updateProject(id: string, project: any, showModal: boolean = true) {
        let url = this.webServiceUri + `/project/${id}`;
        let res = await this.apiPatchCall(url, project);
        if (showModal) {
            BaseWebService.successSubject.next({ summary: 'Updated project', detail: 'Successfully updated project!' });
        }

        return res;
    }

    public async updateProjectStatus(id: string, params: any, showModal: boolean = true) {
        let url = this.webServiceUri + `/project/update/${id}`;
        let res = await this.apiPatchCall(url, params)
        if (showModal) {
            BaseWebService.successSubject.next({ summary: 'Updated project status', detail: 'Successfully updated project status!' });
        }
        return res;
    }

    public async completeProject(id: string, params: any) {
        let url = this.webServiceUri + `/project/${id}/complete`;
        let res = await this.apiPostCall(url, params)
        return res;
    }

    public async createProjectUpdate(params: any, showModal: boolean = true) {
        let url = this.webServiceUri + `/project/updates`;
        let res = await this.apiPostCall(url, params)
        if (showModal) {
            BaseWebService.successSubject.next({ summary: 'Update added', detail: 'Successfully added new update!' });
        }
        return res;
    }

    public async getProjectUpdates(id: string) {
        let url = this.webServiceUri + `/project/updates/${id}`;
        return this.apiGetCall(url);
    }

    public async getCharityProjectsByStatus(id: string, status: number) {
        let url = this.webServiceUri + `/charity/projects/${id}/${status}`;
        return this.apiGetCall(url);
    }

    public async getCharityProjects(id: string) {
        let url = this.webServiceUri + `/charity/projects/${id}`;
        return this.apiGetCall(url);
    }

    public async getCharityProjectAdmins(id: string) {
        let url = this.webServiceUri + `/project/admins/${id}`;
        return this.apiGetCall(url);
    }

    public async getCharityFunds(id: number) {
        let url = this.webServiceUri + `/charity/funds/${id}`;
        return this.apiGetCall(url);
    }

    public async getPendingProjectsDonations(charity_id: number) {
        let url = this.webServiceUri + `/charity/${charity_id}/projects_donations`;
        return this.apiGetCall(url);
    }

    public async getApprovedProjectsDonations(project_id: string) {
        let url = this.webServiceUri + `/project/${project_id}/all-donations`;
        return this.apiGetCall(url);
    }

    // public async getPendingProjectDonations(project_id: string) {
    //     let url = this.webServiceUri + `/charity/${project_id}/projects_donations`;
    //     return this.apiGetCall(url);
    // }

    public async getPendingDirectDonations(charity_id: number) {
        let url = this.webServiceUri + `/charity/${charity_id}/direct_donations`;
        return this.apiGetCall(url);
    }

    public async getExpirdeProjects(charity_id: number) {
        let url = this.webServiceUri + `/charity/${charity_id}/expired_projects`;
        return this.apiGetCall(url);
    }

    public async approveDonations(id: number, params: any) {
        let url = this.webServiceUri + `/charity/${id}/approve_donations`;
        return this.apiPostCall(url, params);
    }

    // public async connectStripe(params: any) {
    //     let url = this.webServiceUri + `/stripe/connect`;
    //     return this.apiPostCall(url, params);
    // }

    public async AssociateCharity(charity_id: number, project_id: string) {
        let url = this.webServiceUri + `/project/associate/${charity_id}/${project_id}`;
        return this.apiPostCall(url, {});
    }

    public async getPendingProjectRequests(id: number) {
        let url = this.webServiceUri + `/charity/associated_requests/${id}`;
        return this.apiGetCall(url);
    }

    public async rejectAssociatedProject(params: any) {
        let url = this.webServiceUri + `/charity/associated_reject`;
        return this.apiPostCall(url, params);
    }

    public async acceptAssociatedProject(params: any) {
        let url = this.webServiceUri + `/charity/associated_approve`;
        return this.apiPostCall(url, params);
    }

    public async getProjectPendingDonations(id: string) {
        let url = this.webServiceUri + `/project/${id}/donations`;
        return this.apiGetCall(url);
    }

    public async extendProject(id: string, params: any, showModal: boolean = true) {
        let url = this.webServiceUri + `/project/extend/${id}`;
        let res = await this.apiPatchCall(url, params);
        if (showModal) {
            BaseWebService.successSubject.next({ summary: 'Project extended', detail: 'Successfully extend project!' });
        }
        return res;
    }

    public async updateProjectImpactStatement(id: string, params: any, showModal: boolean = true) {
        let url = this.webServiceUri + `/project/${id}/impact`;
        let res = await this.apiPostCall(url, params);
        if (showModal) {
            BaseWebService.successSubject.next({ summary: 'Project impact published', detail: 'Successfully published project impact!' });
        }
        return res;
    }

    public async getProjectImpactStatement(id: string) {
        let url = this.webServiceUri + `/project/${id}/impact`;
        return this.apiGetCall(url);
    }

    public async cancelProject(id: string, params: any, showModal: boolean = true) {
        let url = this.webServiceUri + `/project/${id}/cancel`;
        let res = await this.apiPostCall(url, params);
        if (showModal) {
            BaseWebService.successSubject.next({ summary: 'Project canceled', detail: 'Successfully canceled project!' });
        }
        return res
    }

    public async getCharityTotalStatistics(id: string) {
      let url = this.webServiceUri + `/charity/total_statistics/${id}`;
      return this.apiGetCall(url);
    }

  public async getProjectAcceptedVolunteersAndGifters(id: string) {
    let url = this.webServiceUri + `/project/${id}/donators_without_funds`;
    return this.apiGetCall(url);
  }
}

@Injectable()
export class CompanyWebService extends BaseWebService {
    constructor(protected http: Http) {
        super(http);
    }

    public async getCompany(id: string) {
        let url = this.webServiceUri + `/business/${id}`;
        return this.apiGetCall(url);
    }

    public async getCompanyList(id: string) {
        let url = this.webServiceUri + `/business/list`;
        return this.apiGetCall(url);
    }

    public async updateCompany(id: string, company: CompanyModel, showModal: boolean = true) {
        let url = this.webServiceUri + `/business/${id}`;
        let res = await this.apiPatchCall(url, company)
        if (showModal) {
            BaseWebService.successSubject.next({ summary: 'Business updated', detail: 'Successfully updated business details!' });
        }
        return res;
    }

    public async deleteMember(business: string, params: any, showModal: boolean = true) {
        let url = this.webServiceUri + `/membership/business/${business}/delete`;

        let res = await this.apiPostCall(url, params) //TODO
        if (showModal) {
            BaseWebService.successSubject.next({ summary: 'Removed business member', detail: 'Successfully removed business member!' });
        }
        return res;
    }

    public async getCompanyAdmins(id: string) {
        let url = this.webServiceUri + `/business/admins/${id}`;
        return this.apiGetCall(url);
    }

    public async getPendingJoinRequests(id: number) {
        let url = this.webServiceUri + `/business/requests/${id}`;
        return this.apiGetCall(url);
    }

    public async getBusinessAutocomplete() {
        let url = this.webServiceUri + `/business/autocomplete`;
        return this.apiGetCall(url);
    }

    public async getBusinessEmployeesWithoutSignOffs(company_id: string) {
        let url = this.webServiceUri + `/business/employees-without-signoff/${company_id}`;
        return this.apiGetCall(url);
    }

    public async makeEmployeeSignOffAdmin(company_id: string, params: any, showModal: boolean = true) {
        let url = this.webServiceUri + `/membership/signoff/${company_id}`;
        if (showModal) {
            BaseWebService.successSubject.next({ summary: 'Sign off admins added', detail: 'Successfully added new sign off admins!' });
        }
        return this.apiPostCall(url, params);
    }

    public async deleteSignOffAdmin(company_id: string, user_id: string, showModal: boolean = true) {
        let url = this.webServiceUri + `/membership/signoff/${company_id}/${user_id}`;
        if (showModal) {
            BaseWebService.successSubject.next({ summary: 'Sign off admin removed', detail: 'Successfully removed new sign off admin!' });
        }
        return this.apiDeleteCall(url);
    }

    public async getBusinessSignOffAdmins(company_id: string) {
        let url = this.webServiceUri + `/business/signoff-admins/${company_id}`;
        return this.apiGetCall(url);
    }

    public async getBusinessPendingDonations(company_id: string) {
        let url = this.webServiceUri + `/business/${company_id}/donations`;
        return this.apiGetCall(url);
    }

    public async approveBusinessPendingDonations(company_id: string, params: any) {
        let url = this.webServiceUri + `/business/${company_id}/approve_donations`;
        return this.apiPostCall(url, params);
    }

    public async getBusinessProjectList(company_id: string) {
        let url = this.webServiceUri + `/project/business/${company_id}`;
        return this.apiGetCall(url);
    }

    public async getBusinessTotalStatistics(company_id: number) {
        let url = this.webServiceUri + `/business/total_statistics/${company_id}`;
        return this.apiGetCall(url);
    }

    public async getBusinessDonations(company_id: number) {
        let url = this.webServiceUri + `/business/donations/${company_id}`;
        return this.apiGetCall(url);
    }

    public async getBusinessDonationsByType(company_id: number, type: number) {
        let url = this.webServiceUri + `/business/donations_by_type/${company_id}/${type}`;
        return this.apiGetCall(url);
    }

    public async getBusinessDonationsVolunteering(company_id: number, type: number) {
        let url = this.webServiceUri + `/business/volunteering/${company_id}/${type}`;
        return this.apiGetCall(url);
    }

    public async getBusinessWallet(company_id: number) {
        let url = this.webServiceUri + `/business/wallet/${company_id}`;
        return this.apiGetCall(url);
    }

    public async getBusinessGroups(company_id: number) {
        let url = this.webServiceUri + `/business/${company_id}/employees-group`;
        return this.apiGetCall(url);
    }

    public async createBusinessGroup(company_id: number, params: any) {
        let url = this.webServiceUri + `/business/${company_id}/employees-group`;
        let res = await this.apiPostCall(url, params);
        BaseWebService.successSubject.next({ summary: 'Business group created!', detail: 'Successfully created new business group!' });
        return res;
    }

    public async createBusinessSubGroup(company_id: number, group_id: number, params: any) {
        let url = this.webServiceUri + `/business/${company_id}/employees-group/${group_id}`;
        let res = await this.apiPostCall(url, params);
        BaseWebService.successSubject.next({ summary: 'Business subgroup created!', detail: 'Successfully created new business subgroup!' });
        return res;
    }

    public async getBusinessUsersForGroup(company_id: number, group_id: number, subgroup_id: number) {
        let url = this.webServiceUri + `/business/${company_id}/employees-group/${group_id}/${subgroup_id}/users`;
        return this.apiGetCall(url);
    }

    public async getAllBusinessEmployees(company_id: number) {
        let url = this.webServiceUri + `/business/${company_id}/members`;
        return this.apiGetCall(url);
    }

    public async addUserToBusinessSubGroup(company_id: number, group_id: number, subgroup_id: number, params: any) {
        let url = this.webServiceUri + `/business/${company_id}/employees-group/${group_id}/${subgroup_id}/users`;
        let res = await this.apiPostCall(url, params);
        BaseWebService.successSubject.next({ summary: 'User added to subgroup!', detail: 'Successfully added user to the subgroup!' });
        return res;
    }

    public async updateBusinessGroup(company_id: number, group_id: number, params) {
        let url = this.webServiceUri + `/business/${company_id}/employees-group/${group_id}`;
        let res = await this.apiPatchCall(url, params);
        BaseWebService.successSubject.next({ summary: 'Business group updated!', detail: 'Successfully updated business group!' });
        return res;
    }

    public async updateBusinessSubGroup(company_id: number, group_id: number, subgroup_id: number, params: any) {
        let url = this.webServiceUri + `/business/${company_id}/employees-group/${group_id}/${subgroup_id}`;
        let res = await this.apiPatchCall(url, params);
        BaseWebService.successSubject.next({ summary: 'Business subgroup updated!', detail: 'Successfully updated business subgroup!' });
        return res;
    }

    public async deleteUserToBusinessSubGroup(company_id: number, group_id: number, subgroup_id: number, params: any) {
        let url = this.webServiceUri + `/business/${company_id}/employees-group/${group_id}/${subgroup_id}/users/delete`;
        let res = await this.apiPostCall(url, params);
        return res;
    }

    public async getBusinessDonationsForCharities(company_id: number) {
        let url = this.webServiceUri + `/business/donations_by_charity/${company_id}`;
        return this.apiGetCall(url);
    }

    public async getBusinessDonationsForProjects(company_id: number) {
        let url = this.webServiceUri + `/business/donations_by_project/${company_id}`;
        return this.apiGetCall(url);
    }

    public async getBusinessFutureDonations(company_id: number) {
        let url = this.webServiceUri + `/business/future_donations/${company_id}`;
        return this.apiGetCall(url);
    }

    public async promoteBusinessPermission(company_id: number, params: any, showModal: boolean = true) {
        let url = this.webServiceUri + `/membership/business/${company_id}/set`;
        let res = await this.apiPostCall(url, params);
        if (showModal) {
            BaseWebService.successSubject.next({ summary: 'Permission updated', detail: 'Successfully updated business user permissions!' });
        }
        return res;
    }

    public async getBusinessRejectedDonations(company_id: number) {
        let url = this.webServiceUri + `/business/rejected_donations/${company_id}`;
        return this.apiGetCall(url);
    }

    public async getBusinessSubscriptions(company_id: number) {
        let url = this.webServiceUri + `/business/${company_id}/subscription_list`;
        return this.apiGetCall(url);
    }

    public async getBusinessInvoices(company_id: number) {
        let url = this.webServiceUri + `/business/${company_id}/invoices`;
        return this.apiGetCall(url);
    }

    public async getUserDonationSubscriptions(company_id: number) {
        let url = this.webServiceUri + `/business/${company_id}/donation_subscriptions`;
        return this.apiGetCall(url);
    }

    public async cancelDonationSubscription(company_id: number, subscription_id: string,showModal: boolean = true) {
        let url = this.webServiceUri + `/business/${company_id}/cancel_donation_subscription/${subscription_id}`;
        let res = await this.apiPostCall(url);
        if (showModal) {
            BaseWebService.successSubject.next({ summary: 'Recurring Donation Cancelled', detail: 'Successfully cancelled your recurring donation!' });
        }
        return res;
    }

    public async getCompanyStripeTransctions(company_id: number, way:string, start_at: string){
        let url = this.webServiceUri + `/business/${company_id}/payments/${way}/${start_at}`;
        return this.apiGetCall(url);
    }
}


@Injectable()
export class GiftWebService extends BaseWebService {
    constructor(protected http: Http) {
        super(http);
    }

    public async getGiftsList() {
        let url = this.webServiceUri + "/gift/list";
        return this.apiGetCall(url);
    }

    public async getGiftsCategories() {
        let url = this.webServiceUri + "/gift/categories";
        return this.apiGetCall(url);
    }

    public async deleteGift(id: number) {
        let url = this.webServiceUri + `/gift/${id}`;
        return this.apiDeleteCall(url);
    }

    public async addGift(params: any) {
        let url = this.webServiceUri + "/gift";
        return this.apiPostCall(url, params);
    }

    public async updateGift(id: number, params: any) {
        let url = this.webServiceUri + `/gift/${id}`;
        return this.apiPatchCall(url, params);
    }

    public async getGift(id: number) {
        let url = this.webServiceUri + `/gift/${id}`;
        return this.apiGetCall(url);
    }
}

@Injectable()
export class AdminWebService extends BaseWebService {
    constructor(protected http: Http) {
        super(http);
    }

    public async updateUser(params: any, showModal: boolean = true) {
        let url = this.webServiceUri + `/admin/user`;
        let res = await this.apiPatchCall(url, params)
        if (showModal) {
            BaseWebService.successSubject.next({ summary: 'Updated user', detail: 'Successfully updated user!' });
        }
        return res;
    }

    public async createCompany(company: any, showModal: boolean = true) {
        let url = this.webServiceUri + "/business";
        let res = await this.apiPostCall(url, company)
        if (showModal) {
            BaseWebService.successSubject.next({ summary: 'Business created', detail: 'Successfully created business!' });
        }
        return res;
    }

    public async createCharity(charity: any, showModal: boolean = true) {
        let url = this.webServiceUri + "/charity";
        let res = await this.apiPostCall(url, charity)
        if (showModal) {
            BaseWebService.successSubject.next({ summary: 'Charity created', detail: 'Successfully created charity!' });
        }
        return res;
    }

    public async getBusinessList() {
        let url = this.webServiceUri + "/admin/business_list";
        return this.apiGetCall(url);
    }

    public async lockCompany(id: string) {
        return this.changeCompanyLock(id, true);
    }

    public async unlockCompany(id: string) {
        return this.changeCompanyLock(id, false);
    }

    private async changeCompanyLock(id: string, lockStatus: boolean, showModal: boolean = true) {
        let summary = 'Unlocked Business';
        let detail = 'Successfully unlocked Business!';
        if (lockStatus) {
            summary = 'Locked Business';
            detail = 'Successfully locked Business!';
        }
        let url = this.webServiceUri + `/business/${id}`;
        let res = await this.apiPatchCall(url, { lock_status: lockStatus });
        if (showModal) {
            BaseWebService.successSubject.next({ summary: summary, detail: detail });
        }
        return res;
    }

    public async getCompletedProjectList() {
        let url = this.webServiceUri + `/admin/complete`;
        return this.apiGetCall(url);
    }

    public async getPendingCharities() {
        let url = this.webServiceUri + `/admin/charity/pending/list`;
        return this.apiGetCall(url);
    }

    
    public async getExpiredProjects() {
        let url = this.webServiceUri + `/admin/projects/all_expire_projects`;
        return this.apiGetCall(url);
    }

    public async approvePendingCharities(params: any) {
        let url = this.webServiceUri + `/admin/charity/pending/update`;
        return this.apiPostCall(url, params);
    }

    public async IICAdminApproveDonation(params: any, showModal: boolean = true) {
        let url = this.webServiceUri + `/admin/complete`;
        let res = await this.apiPostCall(url, params);
        if (showModal) {
            BaseWebService.successSubject.next({ summary: "Donations Approved", detail: "Successfully approved donations!" });
        }
        return res;
    }

    public async getProjectDetails(id: String) {
        let url = this.webServiceUri + `/admin/project/${id}`;
        return this.apiGetCall(url);
    }

    public async rejectDonations(params, showModal: boolean = true) {
        let url = this.webServiceUri + `/admin/donation/reject`;
        let res = await this.apiPostCall(url, params);
        if (showModal) {
            BaseWebService.successSubject.next({ summary: "Donations Rejected", detail: "Successfully rejected donations!" });
        }
        return res;
    }

    public async returnProjectToCharity(projectIds: Array<any>, showModal: boolean = true) {
        let url = this.webServiceUri + `/admin/return`;
        let res = this.apiPostCall(url, projectIds);
        if (showModal) {
            BaseWebService.successSubject.next({ summary: "Project returned to charity", detail: "Successfully returned project to charity!" });
        }
        return res;
    }

    public async getProjectList() {
        let url = this.webServiceUri + `/admin/projects_list`;
        return this.apiGetCall(url);
    }

    public async getCharitiesList() {
        let url = this.webServiceUri + `/admin/charity_list`;
        return this.apiGetCall(url);
    }

    public async getUserList() {
        let url = this.webServiceUri + `/admin/user_list`;
        return this.apiGetCall(url);
    }

    public async getUserAdmin(id: string) {
        let url = this.webServiceUri + `/admin/user/${id}`;
        return this.apiGetCall(url);
    }

    public async connectStripeAccount(params) {
        let url = this.webServiceUri + `/stripe/account`;
        return this.apiPatchCall(url, params);
    }

    public async getStripeDetails(charity_id) {
        let url = this.webServiceUri + `/stripe/account/${charity_id}`;
        return this.apiGetCall(url);
    }

    public async getAdminTotalStatistics() {
        let url = this.webServiceUri + `/admin/statistics/total_statistics`;
        return this.apiGetCall(url);
    }

    public async getAdminTotalCCStatistics() {
        let url = this.webServiceUri + `/admin/statistics/all_cc`;
        return this.apiGetCall(url);
    }

    public async getAdminTotalWalletsMoneyStatistics() {
        let url = this.webServiceUri + `/admin/statistics/all_wallets_money`;
        return this.apiGetCall(url);
    }

    public async getAdminTotalGiftAidStatistics() {
        let url = this.webServiceUri + `/admin/statistics/all_gift_aid`;
        return this.apiGetCall(url);
    }

    public async getAdminTotalMoneyDonatedStatistics() {
        let url = this.webServiceUri + `/admin/statistics/all_money_donated`;
        return this.apiGetCall(url);
    }

    public async getAdminTotalVolunteeringStatistics() {
        let url = this.webServiceUri + `/admin/statistics/all_volunteering`;
        return this.apiGetCall(url);
    }

    public async getAdminTotalSkilledVolunteeringStatistics() {
        let url = this.webServiceUri + `/admin/statistics/all_skilled_volunteering`;
        return this.apiGetCall(url);
    }

    public async getAdminTotalGiftsDonatedStatistics() {
        let url = this.webServiceUri + `/admin/statistics/all_gifts`;
        return this.apiGetCall(url);
    }

    public async getAdminTotalIndividualsStatistics() {
        let url = this.webServiceUri + `/admin/statistics/all_individuals`;
        return this.apiGetCall(url);
    }

    public async getAdminTotalBusinessesStatistics() {
        let url = this.webServiceUri + `/admin/statistics/all_businesses`;
        return this.apiGetCall(url);
    }

    public async getAdminTotalCharitiesStatistics() {
        let url = this.webServiceUri + `/admin/statistics/all_charities`;
        return this.apiGetCall(url);
    }

    public async getAdminTotalLiveProjectsStatistics() {
        let url = this.webServiceUri + `/admin/statistics/all_live_projects`;
        return this.apiGetCall(url);
    }

    public async getAdminTotalProjectsStatistics() {
        let url = this.webServiceUri + `/admin/statistics/all_projects`;
        return this.apiGetCall(url);
    }

    public async getAdminTotalSubscriptionStatistics() {
        let url = this.webServiceUri + `/admin/statistics/all_subscriptions`;
        return this.apiGetCall(url);
    }

    public async updateWallet(id: string, params: any, showModal?: boolean) {
        let url = this.webServiceUri + `/admin/business/${id}/deposit`;
        let res = this.apiPostCall(url, params);
        if (showModal) {
            BaseWebService.successSubject.next({ summary: 'Wallet updated', detail: 'Successfully updated business wallet!' });
        }
        return res
    }

    public async updateUserWallet(id: string, params: any, showModal?: boolean) {
        let url = this.webServiceUri + `/admin/user/${id}/deposit`;
        let res = this.apiPostCall(url, params);
        if (showModal) {
            BaseWebService.successSubject.next({ summary: 'Wallet updated', detail: 'Successfully updated user wallet!' });
        }
        return res
    }

    public async updateCommunityCredits(id: string, params: any, showModal?: boolean) {
        let url = this.webServiceUri + `/admin/business/${id}/cc`;
        let res = this.apiPostCall(url, params);
        if (showModal) {
            BaseWebService.successSubject.next({ summary: 'Community credits updated', detail: 'Successfully updated business community credits!' });
        }
        return res
    }

    public async IICAdminsSendExpirationMail(params: any, showModal: boolean = true) {
        let url = this.webServiceUri + `/admin/projects/mails`;
        let res = await this.apiPostCall(url, params);
        if (showModal) {
            BaseWebService.successSubject.next({ summary: "Email send", detail: "Email is send to all project admins" });
        }
        return res;
    }

    public async deleteCompany(id: number) {
        let url = this.webServiceUri + `/business/${id}`;
        return this.apiDeleteCall(url);
    }

    public async getGroupList() {
        let url = this.webServiceUri + `/admin/group_list`;
        return this.apiGetCall(url);
    }

    public async getDirectyDebitsList(){
        let url = this.webServiceUri + `/admin/direct_debits_list`;
        return this.apiGetCall(url);
    }
}

@Injectable()
export class ExploreWebService extends BaseWebService {
    constructor(protected http: Http) {
        super(http);
    }

    public async getExploreProjects() {
        let url = this.webServiceUri + `/explore/project/list`;
        return this.apiGetCall(url);
    }

    public async getExploreCharities() {
        let url = this.webServiceUri + `/explore/charity/list`;
        return this.apiGetCall(url);
    }

    public async getExploreProjectsLocations() {
        let url = this.webServiceUri + `/explore/project/locations`
        return this.apiGetCall(url);
    }

    public async getExploreCharityLocations() {
        let url = this.webServiceUri + `/explore/charity/locations`
        return this.apiGetCall(url);
    }

    public async getExploreGroups() {
        let url = this.webServiceUri + `/explore/group/list`;
        return this.apiGetCall(url);
    }
}

@Injectable()
export class InvitationWebService extends BaseWebService {
    constructor(protected http: Http) {
        super(http);
    }

    public async inviteAdmin(params: any, showModal: boolean = true) {
        let url = this.webServiceUri + '/invitation/create';
        let res = await this.apiPostCall(url, params)
        if (showModal) {
            BaseWebService.successSubject.next({ summary: 'Invitation email sent', detail: 'Successfully sent invitation email to user!' });
        }
        return res;
    }

    public async activate(key: string) {
        let url = this.webServiceUri + `/user/activate/${key}`;
        return this.apiGetCall(url)
    }

    public async confirmInvitation(key: string) {
        let url = this.webServiceUri + `/invitation/confirm/${key}`;
        return this.apiGetCall(url)
    }

    public async updateMembershipPermission(params: any, showModal: boolean = true) {
        let url = this.webServiceUri + '/membership';
        let res = await this.apiPatchCall(url, params);
        if (showModal) {
            BaseWebService.successSubject.next({ summary: 'Permission updated', detail: 'Successfully updated user permissions!' });
        }
        return res;
    }

    public async updateJoinRequestStatus(params: any) {
        let url = this.webServiceUri + `/join`;
        return this.apiPatchCall(url, params);
    }

    public async createBusinessJoinRequest(params, showModal: boolean = true) {
        let url = this.webServiceUri + `/join`;
        let res = await this.apiPostCall(url, params);
        if (showModal) {
            BaseWebService.successSubject.next({ summary: 'Join request send!', detail: 'Successfully send Business join request !' });
        }
        return res;
    }

    public async deleteJoinRequest(params: any, showModal: boolean = true) {
        let url = this.webServiceUri + `/join/remove`;
        let res = await this.apiPatchCall(url, params);
        if (showModal) {
            BaseWebService.successSubject.next({ summary: 'Join request removed!', detail: 'Successfully remove join request !' });
        }
        return res;
    }
}


@Injectable()
export class UploadWebService extends BaseWebService {
    constructor(protected http: Http) {
        super(http);
    }

    public individualAvatar(id: number): UploadInput {
        let token = Utils.getToken();
        return {
            type: 'uploadAll',
            url: this.webServiceUri + `/user/avatar/${id}`,
            headers: { 'Authorization': 'Bearer ' + token },
            method: 'POST'
        };
    }


    public charityAvatar(id: number): UploadInput {
        let token = Utils.getToken();
        return {
            type: 'uploadAll',
            url: this.webServiceUri + `/charity/logo/${id}`,
            headers: { 'Authorization': 'Bearer ' + token },
            method: 'POST'
        };
    }

    public projectAvatar(id: number): UploadInput {
        let token = Utils.getToken();
        return {
            type: 'uploadAll',
            url: this.webServiceUri + `/project/logo/${id}`,
            headers: { 'Authorization': 'Bearer ' + token },
            method: 'POST'
        };
    }

    public businessAvatar(id: number): UploadInput {
        let token = Utils.getToken();
        return {
            type: 'uploadAll',
            url: this.webServiceUri + `/business/logo/${id}`,
            headers: { 'Authorization': 'Bearer ' + token },
            method: 'POST'
        };
    }

    public groupAvatar(id: number): UploadInput {
        let token = Utils.getToken();
        return {
            type: 'uploadAll',
            url: this.webServiceUri + `/group/logo/${id}`,
            headers: { 'Authorization': 'Bearer ' + token },
            method: 'POST'
        };
    }

    public AvatarOptions(): UploaderOptionsExtended {
        return {
            concurrency: 1,
            allowedContentTypes: ["image/png", "image/jpeg"],
            maxFileSize: 10 * 1024 * 1024,
        }
    }

    public projectGallery(id: number): UploadInput {
        let token = Utils.getToken();
        return {
            type: 'uploadAll',
            url: this.webServiceUri + `/project/image/${id}`,
            headers: { 'Authorization': 'Bearer ' + token },
            method: 'POST'
        };
    }

    public projectImpactGallery(id: number): UploadInput {
        let token = Utils.getToken();
        return {
            type: 'uploadAll',
            url: this.webServiceUri + `/project/${id}/impact/image`,
            headers: { 'Authorization': 'Bearer ' + token },
            method: 'POST'
        };
    }

    public projectDocuments(id: number): UploadInput {
        let token = Utils.getToken();
        return {
            type: 'uploadAll',
            url: this.webServiceUri + `/project/document/${id}`,
            headers: { 'Authorization': 'Bearer ' + token },
            method: 'POST'
        };
    }

    public galleryOptions(): UploaderOptionsExtended {
        return {
            concurrency: 1,
            allowedContentTypes: ["image/png", "image/jpeg"],
            maxFileSize: 20 * 1024 * 1024,
        }
    }

    public documentsOptions(): UploaderOptionsExtended {
        return {
            concurrency: 1,
            maxFileSize: 20 * 1024 * 1024,
            allowedContentTypes: ["text/plain", "application/pdf", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/vnd.openxmlformats-officedocument.presentationml.presentation", "application/vnd.ms-powerpoint", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-excel"],
        }
    }

    public async deleteDocument(id: string, filename: string, fileId: number, showModal: boolean = true) {
        let url = this.webServiceUri + `/project/document/${id}/${filename}/${fileId}`;
        let res = await this.apiDeleteCall(url) //TODO
        if (showModal) {
            BaseWebService.successSubject.next({ summary: 'Deleted document', detail: 'Successfully deleted document!' });
        }
        return res;
    }

    public async deleteImage(project_id: string, fileUUID: string) {
        let url = this.webServiceUri + `/project/galery/${project_id}/${fileUUID}`;
        return await this.apiDeleteCall(url);
    }

    public businessCSVUpload(id: number): UploadInput {
        let token = Utils.getToken();
        return {
            type: 'uploadAll',
            url: this.webServiceUri + `/business/${id}/csv_upload`,
            headers: { 'Authorization': 'Bearer ' + token },
            method: 'POST'
        }
    }
    
    public CSVOptions(): UploaderOptionsExtended {
        return {
            concurrency: 1,
            allowedContentTypes: ["text/plain", "text/csv", "application/csv", "application/excel", "application/vnd.ms-excel", "application/vnd.msexcel "],
            maxFileSize: 10 * 1024 * 1024,
        }
    }
}

@Injectable()
export class WalletWebService extends BaseWebService {
    constructor(protected http: Http) {
        super(http);
    }

    public async addMoneyToUserWallet(params: any, showModal: boolean = true) {
        let url = this.webServiceUri + '/user/deposit';
        let res = await this.apiPostCall(url, params)
        if (showModal) {
            BaseWebService.successSubject.next({ summary: 'Money added to wallet', detail: 'Successfully added money to wallet!' });
        }
        return res;
    }


    public async addMoneyToBusinessWallet(id: number, params: any, showModal: boolean = true) {
        let url = this.webServiceUri + `/business/${id}/deposit`;
        let res = await this.apiPostCall(url, params)
        if (showModal) {
            BaseWebService.successSubject.next({ summary: 'Money added to wallet', detail: 'Successfully added money to wallet!' });
        }
        return res;
    }

    public async confirmUserDeposit(params: any, showModal: boolean = true) {
        let url = this.webServiceUri + `/user/deposit/confirm`;
        let res = await this.apiPostCall(url, params)
        if (showModal) {
            BaseWebService.successSubject.next({ summary: 'Money added to wallet', detail: 'Successfully added money to wallet!' });
        }
        return res;
    }


    public async confirmBusinessDeposit(id: number, params: any, showModal: boolean = true) {
        let url = this.webServiceUri + `/business/${id}/confirm`;
        let res = await this.apiPostCall(url, params)
        if (showModal) {
            BaseWebService.successSubject.next({ summary: 'Money added to wallet', detail: 'Successfully added money to wallet!' });
        }
        return res;
    }

    public async createBusinessStripeCheckoutSession(id: number,params: any, showModal: boolean = true) {
        let url = this.webServiceUri + `/business/direct_debit/${id}`;
        let res = await this.apiPostCall(url, params)
        if (showModal) {
            BaseWebService.successSubject.next({ summary: 'Stripe', detail: 'Created Stripe checkout sessions' });
        }
        return res;
    }

    public async createUserStripeCheckoutSession(params: any, showModal: boolean = true) {
        let url = this.webServiceUri + `/user/create_direct_debit`;
        let res = await this.apiPostCall(url, params)
        if (showModal) {
            BaseWebService.successSubject.next({ summary: 'Stripe', detail: 'Created Stripe checkout sessions' });
        }
        return res;
    }


}


@Injectable()
export class SubscriptionWebService extends BaseWebService {
    constructor(protected http: Http) {
        super(http);
    }

    public async createSubscription(id: number, params: any, showModal: boolean = true) {
        let url = this.webServiceUri + `/business/${id}/subscription`;
        let res = await this.apiPostCall(url, params)
        if (showModal) {
            BaseWebService.successSubject.next({ summary: 'Subscription successful', detail: 'Successfully subscribed!' });
        }
        return res;
    }

    public async createSubscriptionFromAdmin(id: number, params: any, showModal: boolean = true) {
        let url = this.webServiceUri + `/admin/business/${id}/subscription`;
        let res = await this.apiPostCall(url, params)
        if (showModal) {
            BaseWebService.successSubject.next({ summary: 'Subscription successful', detail: 'Successfully subscribed!' });
        }
        return res;
    }

    public async getSubscription(id: number) {
        let url = this.webServiceUri + `/business/${id}/subscription`;
        return await this.apiGetCall(url)
    }
    
    public async getSubscriptionPreview(company_id: number, params: any) {
        let url = this.webServiceUri + `/business/${company_id}/subscription/preview`;
        return this.apiPostCall(url, params);
    }

    public async getCurrentSubscription(company_id: number) {
        let url = this.webServiceUri + `/business/${company_id}/subscription/current`;
        return this.apiPostCall(url, {});
    }

    public async getDiscountCode(company_id: number, discount_code: string) {
        let url = this.webServiceUri + `/business/${company_id}/subscription/discount`;
        return this.apiPostCall(url, { discount_code });
    }

    public async createBusinessDirectDebitStripeCheckoutSession(id: number, params: any, showModal: boolean = true, update: boolean = false) {
        let url = this.webServiceUri + `/business/${id}/subscription_with_direct_debit`;
        let res = await this.apiPostCall(url, params)
        if (showModal && !update) {
            BaseWebService.successSubject.next({ summary: 'Stripe', detail: 'Created Stripe checkout sessions' });
        }
        if (update) {
            BaseWebService.successSubject.next({ summary: 'Stripe', detail: 'Subscription updated!' });
        }
        return res;
    }

    public async onboardingRequest($id) {
        let url = this.webServiceUri + `/user/onboarding_request/`+$id;
        return this.apiPostCall(url, {})
    }
}

@Injectable()
export class DonationWebService extends BaseWebService {
    constructor(protected http: Http) {
        super(http);
    }

    public async userDonateProject(id: number, params: any, showModal: boolean = true) {
        let url = this.webServiceUri + `/user/donate/project/${id}`;
        let res = await this.apiPostCall(url, params)
        if (showModal) {
            BaseWebService.successSubject.next({ summary: 'Donation successful', detail: 'Successfully donated!' });
        }
        return res;
    }

    public async userDonateCharity(id: number, params: any, showModal: boolean = true) {
        let url = this.webServiceUri + `/user/donate/charity/${id}`;
        let res = await this.apiPostCall(url, params)
        if (showModal) {
            BaseWebService.successSubject.next({ summary: 'Donation successful', detail: 'Successfully donated!' });
        }
        return res;
    }


    public async businessDonateProject(businessId: number, projectId: number, params: any, showModal: boolean = true) {
        let url = this.webServiceUri + `/business/${businessId}/donate/project/${projectId}`;
        let res = await this.apiPostCall(url, params)
        if (showModal) {
            BaseWebService.successSubject.next({ summary: 'Donation successful', detail: 'Successfully donated!' });
        }
        return res;
    }
    public async businessDonateCharity(businessId: number, charityId: number, params: any, showModal: boolean = true) {
        let url = this.webServiceUri + `/business/${businessId}/donate/charity/${charityId}`;
        let res = await this.apiPostCall(url, params)
        if (showModal) {
            BaseWebService.successSubject.next({ summary: 'Donation successful', detail: 'Successfully donated!' });
        }
        return res;
    }
    public async businessDirectDebitDonateCharity(businessId: number, params: any, showModal: boolean = true) {
        let url = this.webServiceUri + `/business/direct_debit/${businessId}`;
        let res = await this.apiPostCall(url, params)
        if (showModal) {
            BaseWebService.successSubject.next({ summary: 'Donation successful', detail: 'Successfully donated!' });
        }
        return res;
    }
}

@Injectable()

export class GroupWebService extends BaseWebService {
    constructor(protected http: Http) {
        super(http);
    }

    public async createGroup(params: any, showModal: boolean = true) {
        let url = this.webServiceUri + `/group`;
        let res = await this.apiPostCall(url, params);
        if (showModal) {
            BaseWebService.successSubject.next({ summary: 'Group created', detail: 'Successfully creates group'});
        }
        return res;
    }

    public async getGroup(id: number) {
        let url = this.webServiceUri + `/group/${id}`;
        return this.apiGetCall(url);
    }

    public async deleteGroup(id: number) {
        let url = this.webServiceUri + `/group/${id}`;
        return this.apiDeleteCall(url);
    }

    public async updateGroup(id: number, group: GroupModel, showModal: boolean = true) {
        let url = this.webServiceUri + `/group/${id}`;
        let res = await this.apiPatchCall(url, group)
        if (showModal) {
            BaseWebService.successSubject.next({ summary: 'Group updated', detail: 'Successfully updated group details!' });
        }
        return res;
    }

    public async getIndividualGroupList(member_id: number) {
        let url = this.webServiceUri + `/group/individual/${member_id}/list`;
        return this.apiGetCall(url);
    }

    public async getCompanyGroupList(member_id: number) {
        let url = this.webServiceUri + `/group/business/${member_id}/list`;
        return this.apiGetCall(url);
    }

    public async getGroupPendingRequests(id: number) {
        let url = this.webServiceUri + `/group/${id}/requests`;
        return this.apiGetCall(url);
    }

    public async getGroupAdmins(id: number) {
        let url = this.webServiceUri + `/group/${id}/admins`;
        return this.apiGetCall(url);
    }

    public async getGroupStatistics(id: number) {
        let url = this.webServiceUri + `/group/members_total_statistics/${id}`;
        return this.apiGetCall(url);
    }

    public async getGroupTotalStatistics(id: number) {
        let url = this.webServiceUri + `/group/total_statistics/${id}`;
        return this.apiGetCall(url);
    }

    public async generateNewPin(id: number) {
        let url = this.webServiceUri + `/group/${id}/generate_new_pin`;
        return this.apiPostCall(url);
    }

    public async joinGroup(params: any, showModal: boolean = true) {
        let url = this.webServiceUri + `/group/join`;
        let res = await this.apiPostCall(url, params)
        if (showModal) {
            BaseWebService.successSubject.next({ summary: 'Group joined', detail: 'Successfully joined group!' });
        }
        return res
    }

    public async leaveGroup(params: any, showModal: boolean = true) {
        let url = this.webServiceUri + `/group/leave`;
        let res = await this.apiPostCall(url, params)
        if (showModal) {
            BaseWebService.successSubject.next({ summary: 'Left Group', detail: 'You have successfully left the group!' });
        }
        return res
    }

    public async getIndividualGroupsListWithstats(member_id: number) {
        let url = this.webServiceUri + `/group/individual/${member_id}/list_with_total_stats`;
        return this.apiGetCall(url);
    }

    public async getBusinessGroupsListWithstats(member_id: number) {
        let url = this.webServiceUri + `/group/business/${member_id}/list_with_total_stats`;
        return this.apiGetCall(url);
    }

    public async setGroupMembership(group_id: number, params: any, showModal: boolean = true) {
        let url = this.webServiceUri + `/membership/group/${group_id}/set`;
        if (showModal) {
            BaseWebService.successSubject.next({ summary: 'Group membership changed', detail: 'Successfully changed group membership!' });
        }
        return this.apiPostCall(url, params);
    }

    //removeMemebers
    public async removeMemebers(params: any, showModal: boolean = true) {
        let url = this.webServiceUri + `/membership/group/remove`;
        if (showModal) {
            BaseWebService.successSubject.next({ summary: 'Group members removed', detail: 'Successfully removed members from group!' });
        }
        return this.apiPostCall(url, params);
    }

    public async addAdmins(params: any, showModal: boolean = true){
        let url = this.webServiceUri + `/membership/group/make_admins`;
        if (showModal) {
            BaseWebService.successSubject.next({ summary: 'Group members promoted', detail: 'Successfully send mails to invite new admins!' });
        }
        return this.apiPostCall(url, params)
    }
}

@Injectable()
export class MessageWebService extends BaseWebService {
    constructor(protected http: Http) {
        super(http);
    }

    public async getAllInboxMessages(receiver_type: number, receiver_id: number) {
        let url = this.webServiceUri + `/message/get_all_inbox/${receiver_type}/${receiver_id}`;
        return this.apiGetCall(url);
    }

    public async getAllSentMessages(sender_type: number, sender_id: number) {
        let url = this.webServiceUri + `/message/get_all_sent/${sender_type}/${sender_id}`;
        return this.apiGetCall(url);
    }

    public async getAllDraftMessages(sender_type: number, sender_id: number) {
        let url = this.webServiceUri + `/message/get_all_draft/${sender_type}/${sender_id}`;
        return this.apiGetCall(url);
    }

    //getArchivedMessages
    public async getAllArchivedMessages(sender_type: number, sender_id: number) {
        let url = this.webServiceUri + `/message/get_all_archive/${sender_type}/${sender_id}`;
        return this.apiGetCall(url);
    }

    public async getMessage(id: number, receiver_type: number, receiver_id: number) {
        let url = this.webServiceUri + `/message/get/${id}/${receiver_type}/${receiver_id}`;
        return this.apiGetCall(url);
    }

    public async getReplay(id: number, receiver_type: number, receiver_id: number) {
        let url = this.webServiceUri + `/replay/get/${id}/${receiver_type}/${receiver_id}`;
        return this.apiGetCall(url);
    }

    public async markMessageAsRead(params: any) {
        let url = this.webServiceUri + `/message/mark_read`;
        let res = await this.apiPostCall(url, params);
        return res;
    }

    public async sendReply(params, showModal: boolean = true) {
        let url = this.webServiceUri + `/message/reply`;
        let res = await this.apiPostCall(url, params);
        if (showModal) {
            BaseWebService.successSubject.next({ summary: 'Reply sent!', detail: 'Successfully sent a reply!' });
        }
        return res;
    }

    public async sendMessage(params, showModal: boolean = true) {
        let url = this.webServiceUri + `/message/create`;
        let res = await this.apiPostCall(url, params);
        if (showModal) {
            BaseWebService.successSubject.next({ summary: 'Message sent!', detail: 'Successfully sent a message!' });
        }
        return res;
    }
    
    public async saveDraftMessage(params, showModal: boolean = true) {
        let url = this.webServiceUri + `/message/create`;
        let res = await this.apiPostCall(url, params);
        if (showModal) {
            BaseWebService.successSubject.next({ summary: 'Message saved!', detail: 'Successfully saved a message as draft!' });
        }
        return res;
    }
    
    public async updateDraftMessage(id:number, userType: number, userID: number, params, showModal: boolean = true) {
        let url = this.webServiceUri + `/message/update/${id}/${userType}/${userID}`;
        let res = await this.apiPatchCall(url, params);
        if (showModal) {
            BaseWebService.successSubject.next({ summary: 'Message updated!', detail: 'Successfully updated draft message!' });
        }
        return res;
    }
    
    // /api/message/{id}
    public async archiveMessage(id: number, userType: number, userID: number, showModal: boolean = true) {
        let url = this.webServiceUri + `/message/archive/${id}/${userType}/${userID}`;
        let res = await this.apiDeleteCall(url)
        if (showModal) {
            BaseWebService.successSubject.next({ summary: 'Message archived', detail: 'Successfully archived a message!' });
        }
        return res;
    }
}

@Injectable()
export class OrganisationWebService extends BaseWebService {
    constructor(protected http: Http) {
        super(http);
    }

    public async getOrganisation(id: number) {
        let url = this.webServiceUri + `/organisation/${id}`;
        return this.apiGetCall(url);
    }

    public async createSubBusiness(params: any){
        let url = this.webServiceUri + `/organisation/sub_business`;
        let res = await this.apiPostCall(url, params);
        return res;
    }

    public async deleteSubBusiness(id: number) {
        let url = this.webServiceUri + `/organisation/sub_business/${id}`;
        return this.apiDeleteCall(url);
    }

    public async transferFunds(params: any, showModal: boolean = true){
        let url = this.webServiceUri + `/organisation/transfer_funds`;
        let res = await this.apiPostCall(url, params);
        if (showModal) {
            BaseWebService.successSubject.next({ summary: 'Transfer completed', detail: 'Successfully completed funds transfer!' });
        }
        return res;
    }

    public async transferEmployees(params: any, showModal: boolean = true){
        let url = this.webServiceUri + `/organisation/transfer_employees`;
        let res = await this.apiPostCall(url, params);
        if (showModal) {
            BaseWebService.successSubject.next({ summary: 'Transfer completed', detail: 'Successfully completed employees transfer!' });
        }
        return res;
    }
}