import { BaseModel } from './base-model';
import { CharityMember } from './charity-member';
import { GlobalConstants } from '@utils/Constants';
import { environment } from '@app/environments/environment';

export class CharityModel extends BaseModel {

    public id: number = -1;
    public users: Array<CharityMember> = [];
    public charity_name: string;
    public registration_charity_number: string;
    public charity_city: string;
    public charity_address: string;
    public charity_email: string;
    public charity_website: string;
    public charity_description: string;
    public formatted_address: string;
    public charity_phone: string;
    public charity_type: number;
    public charity_type_name: string;
    public charity_registration_type: number;
    public project_count: number = 0;
    public logo: string = null;
    public is_locked: boolean = false;
    public isApproved: boolean = false;
    public total_donation: number = 0;
    public total_donators: number = 0;
    public is_connected: boolean = false;
    public imageSmall: string = null; // 80x80
    public imageMedium: string = null; // 150x150
    public imageBig: string = null; // 320x320
    public unread_messages: number = 0;
    public total_charity_projects_funds_donators: number = 0;
    public total_charity_projects_funds_donations: number = 0;
    public charity_post_code: string;

    public fromJSON(json: any) {
        this.id = json["id"];
        this.charity_name = json["charity_name"] || this.charity_name;
        this.registration_charity_number = json["registration_charity_number"] || this.registration_charity_number;
        this.charity_city = json["charity_city"] || this.charity_city;
        this.charity_address = json["charity_address"] || this.charity_address;
        this.charity_email = json["charity_email"] || this.charity_email;
        this.charity_website = json["charity_website"] || this.charity_website;
        this.charity_description = json["charity_description"] || this.charity_description;
        this.charity_phone = json["charity_phone"] || this.charity_phone;
        this.charity_type = json["charity_type"] || this.charity_type;
        this.charity_type_name = GlobalConstants.CHARITY_TYPES_MAP[this.charity_type] || this.charity_name;
        this.charity_registration_type = json["charity_registration_type"] || this.charity_registration_type;
        this.project_count = json["project_count"] || this.project_count;
        this.logo = json["logo"] || this.logo;
        this.is_locked = json["is_locked"] || this.is_locked;
        this.isApproved = json["isApproved"] || this.isApproved;
        this.total_donation = (json["total_donation"] / 100) || this.total_donation;
        this.total_donators = json["total_donators"] || this.total_donators;
        this.is_connected = !!json["is_connected"]

        if(this.logo) {
            this.imageSmall = `url('${environment.imageUrl}${this.logo}_small')`;
            this.imageMedium = `url('${environment.imageUrl}${this.logo}_medium')`;
            this.imageBig = `url('${environment.imageUrl}${this.logo}_big')`;
        } else {
            this.imageSmall = `url(/assets/img/user-placeholder.svg)`;
            this.imageMedium = `url(/assets/img/user-placeholder.svg)`;
            this.imageBig = `url(/assets/img/user-placeholder.svg)`;
        }

        this.formatted_address = `${this.charity_address}, ${this.charity_city}`;
        this.unread_messages = json["unread_messages"] || this.unread_messages;
        this.total_charity_projects_funds_donators = json["total_charity_projects_funds_donators"]  || this.total_charity_projects_funds_donators;
        this.total_charity_projects_funds_donations = json["total_charity_projects_funds_donations"] || this.total_charity_projects_funds_donations;
        this.charity_post_code = json["charity_post_code"] || this.charity_post_code;
        return this;
    }

    public toJSON() {
        let jsonUsers = [];
        for(let user of this.users) {
            jsonUsers.push(user.toJSON());
        }
        return {
            id: this.id,
            user: jsonUsers,
            charity_name: this.charity_name,
            registration_charity_number: this.registration_charity_number,
            charity_city: this.charity_city,
            charity_address: this.charity_address,
            charity_email: this.charity_email,
            charity_description: this.charity_description,
            charity_website: this.charity_website,
            charity_phone: this.charity_phone,
            charity_type: this.charity_type,
            charity_registration_type: this.charity_registration_type,
            is_connected: this.is_connected,
            charity_post_code: this.charity_post_code
        }
    }
}