import { Injectable } from '@angular/core';
import { BaseUser } from '@models/base-user';
import { CompanySubscription } from '@models/company-subscription';
import { CompanyModel } from '@models/company-model';
import { Subject, ReplaySubject } from 'rxjs';
import { CompanyAdminPrivs } from '@utils/Enumerations';
import { AppService } from '../app/app.service';
import { MessageService } from 'primeng/api';
import { CompanyMember } from '@app/models/company-member';
import { BusinessUser } from '@app/models/business-user';

@Injectable()
export class CompanyService {

    public company: CompanyModel = new CompanyModel();
    public companySource: Subject<CompanyModel> = new Subject();
    public companyUser: BaseUser = null;
    public userPrivs: CompanyAdminPrivs = -1;
    public users: Array<BusinessUser> = [];
    public admins: Array<CompanyMember> = [];
    public inAdmin: boolean = false;
    public isSubscribing: boolean = false;

    public test: number;

    public subscriptionSource: Subject<CompanySubscription> = new Subject();

    public setCompany(val: CompanyModel, skipObs?: boolean) {
        this.company = val;
        this.companyUser = this.appService.getUser();
        let companyPrivs = this.appService.getUserBusinesses().filter((item) => {
            return item.business_id == this.company.company_id;
        });

        if (companyPrivs.length > 0) {
            this.userPrivs = companyPrivs[0].permissions;
            this.test = companyPrivs[0].permissions;
        }
        if(!skipObs) {
            this.companySource.next(this.company);
        }
    }

    constructor(private appService: AppService,
        public messageService: MessageService) { }

    public getCompany(): CompanyModel {
        return this.company;
    }

    public getUserPrivs() {
        return this.userPrivs;
    }

    public clearService() {
        this.company = new CompanyModel();
        this.companyUser = null;
        this.userPrivs = -1;
        this.admins = [];
    }

    public showSubscriptionWarning() {
        this.messageService.add({ key: "businessSubs", closable: false, sticky: true, severity: "warn", summary: "Business not subscribed!", detail: "Your business does not have an active subscription. Please subscribe!" });
      }
    
      public showLockedWarning() {
        this.messageService.add({ key: "businessSubs", closable: false, sticky: true, severity: "warn", summary: "Business locked!", detail: "Your business has been locked. Please contact IIC!" });
      }
    
      public hideSubscriptionWarning() {
        this.messageService.clear("businessSubs");
      }
}
